import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDumbbell,
  faPlus,
  faPencil,
  faTrash,
  faEye,
  faCheckCircle,
  faClock,
  faCalendarAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const AdminMemberDetailForFitnessPlans = ({ bid }) => {
  // Mock data for demonstration
  const [fitnessPlans] = useState([
    {
      id: 1,
      name: "Strength Building Program",
      description: "Progressive overload strength training program",
      status: "active",
      workoutsPerWeek: 4,
      createdAt: "2024-03-15T10:00:00Z",
      updatedAt: "2024-03-15T10:00:00Z"
    },
    {
      id: 2,
      name: "Cardio Conditioning",
      description: "High-intensity cardio workout plan",
      status: "pending",
      workoutsPerWeek: 3,
      createdAt: "2024-03-14T10:00:00Z",
      updatedAt: "2024-03-14T10:00:00Z"
    }
  ]);

  // Status badge component
  const StatusBadge = ({ status }) => {
    const getStatusConfig = (status) => {
      switch (status.toLowerCase()) {
        case 'active':
          return { color: 'bg-green-100 text-green-800', icon: faCheckCircle };
        case 'pending':
          return { color: 'bg-yellow-100 text-yellow-800', icon: faClock };
        default:
          return { color: 'bg-gray-100 text-gray-800', icon: faClock };
      }
    };

    const config = getStatusConfig(status);
    return (
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${config.color}`}>
        <FontAwesomeIcon icon={config.icon} className="mr-1" />
        {status}
      </span>
    );
  };

  // Plan card component
  const FitnessPlanCard = ({ plan }) => (
    <div className="bg-white rounded-lg border border-gray-200 hover:border-primary transition-colors duration-200">
      <div className="p-4">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-lg font-semibold text-gray-900">{plan.name}</h4>
            <p className="text-sm text-gray-600 mt-1">{plan.description}</p>
          </div>
          <StatusBadge status={plan.status} />
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex items-center text-sm text-gray-600">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-400" />
            <span>Created: {format(new Date(plan.createdAt), 'MMM dd, yyyy')}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-400" />
            <span>Updated: {format(new Date(plan.updatedAt), 'MMM dd, yyyy')}</span>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-gray-900">Workouts per week:</span>
            <span className="text-sm text-gray-600">{plan.workoutsPerWeek}</span>
          </div>
          <div className="flex items-center space-x-2">
            <button className="p-2 text-gray-600 hover:text-primary transition-colors duration-200">
              <FontAwesomeIcon icon={faEye} />
            </button>
            <button className="p-2 text-gray-600 hover:text-primary transition-colors duration-200">
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button className="p-2 text-gray-600 hover:text-red-500 transition-colors duration-200">
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
      {/* Demo Message Banner */}
      <div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded-lg">
        <div className="flex items-center text-blue-700">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          <span className="text-sm">Demo Mode: Currently displaying mock data for demonstration purposes.</span>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-bold text-textDark flex items-center">
          <FontAwesomeIcon icon={faDumbbell} className="mr-3 text-primaryColor" />
          Fitness Plans
        </h3>
        <button className="btn-primary flex items-center">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Plan
        </button>
      </div>

      {fitnessPlans.length === 0 ? (
        <div className="text-center py-12">
          <FontAwesomeIcon icon={faDumbbell} className="text-gray-400 text-4xl mb-4" />
          <h4 className="text-lg font-medium text-gray-900 mb-2">No Fitness Plans</h4>
          <p className="text-gray-500">This member doesn't have any fitness plans yet.</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {fitnessPlans.map((plan) => (
            <FitnessPlanCard key={plan.id} plan={plan} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminMemberDetailForFitnessPlans;
import { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Accordion = ({ head, content, isOpenByDefault }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="rounded-lg overflow-hidden transition-all duration-200">
    <div 
      className="relative cursor-pointer group"
      onClick={toggleAccordion}
    >
      {head}
      <div className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center text-gray-400 group-hover:text-gray-600 transition-colors">
        <FontAwesomeIcon 
          icon={isOpen ? faChevronUp : faChevronDown} 
          className="w-4 h-4"
        />
      </div>
    </div>
    <div 
      className={`transition-all duration-200 ${
        isOpen ? 'opacity-100' : 'opacity-0 hidden'
      }`}
    >
      {content}
    </div>
  </div>
  );
};

Accordion.propTypes = {
  head: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isOpenByDefault: PropTypes.bool,
};

Accordion.defaultProps = {
  isOpenByDefault: false,
};

export default Accordion;

import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faDumbbell,
	faArrowLeft,
	faGauge,
	faSave,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {
	putExerciseUpdateAPI,
	getExerciseDetailAPI,
} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_TYPE_WITH_EMPTY_OPTIONS,
	EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_TYPE_CUSTOM,
} from "../../../Constants/App";
import FormRadioField from "../../Reusable/FormRadioField";

function AdminExerciseUpdate() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [videoType, setVideoType] = useState(
		EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentFilename, setVideoAttachmentFilename] = useState("");
	const [videoAttachment, setVideoAttachment] = useState(null);
	const [type, setType] = useState(EXERCISE_TYPE_CUSTOM);
	const [thumbnailType, setThumbnailType] = useState(
		EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentFilename, setThumbnailAttachmentFilename] =
		useState("");
	const [description, setDescription] = useState("");
	const [alternateName, setAlternateName] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [movementType, setMovementType] = useState(0);
	const [category, setCategory] = useState("");
	const [hasMonetization, setHasMonetization] = useState(false);
	const [offerID, setOfferID] = useState("");
	const [isOfferOther, setIsOfferOther] = useState(false);
	const [hasTimedLock, setHasTimedLock] = useState(false);
	const [timedLock, setTimedLock] = useState("");
	const [status, setStatus] = useState(0);

	////
	//// API.
	////

	const onExerciseDetailSuccess = (response) => {
		console.log("onExerciseDetailSuccess: Starting...");
		setVideoType(response.videoType);
		setVideoURL(response.videoUrl);
		setVideoAttachmentID(response.videoAttachmentId);
		setVideoAttachmentFilename(response.videoAttachmentFilename);
		setThumbnailType(response.thumbnailType);
		setThumbnailURL(response.thumbnailUrl);
		setThumbnailAttachmentID(response.thumbnailAttachmentId);
		setThumbnailAttachmentFilename(response.thumbnailAttachmentFilename);
		setName(response.name);
		setAlternateName(response.alternateName);
		setDescription(response.description);
		setCategory(response.category);
		setGender(response.gender);
		setMovementType(response.movementType);
		setType(response.type);
		setHasMonetization(response.hasMonetization);
		if (response.hasMonetization) {
			setOfferID(response.offerId);
			setHasTimedLock(response.hasTimedLock);
			if (response.hasTimedLock) {
				setTimedLock(response.timedLock);
			}
		}
		setStatus(response.status);
	};

	const onExerciseDetailError = (apiErr) => {
		console.log("onExerciseDetailError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed getting exercise details");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onExerciseDetailError: Delayed for 2 seconds.");
			setTopAlertMessage("");
		}, 2000);

		// Scroll to the top of the page.
		window.scrollTo(0, 0);
	};

	const onExerciseDetailDone = () => {
		console.log("onExerciseDetailDone: Starting...");
		setFetching(false);
	};

	const onUpdateSuccess = (response) => {
		console.log("onUpdateSuccess: Starting...");
		console.log(response);

		Scroll.animateScroll.scrollToTop();

		// Handle both null and non-null responses
		if (response && response.message === "Update successful") {
			// Handle the case where we got a successful update with no response body
			setErrors({});
			setTimeout(() => {
				console.log("onUpdateSuccess: Delayed for 2 seconds.");
				setFetching(false);
				// Redirect the user.
				setForceURL("/admin/exercise/" + id);
			}, 2000);
			return;
		}

		// Your existing success handling code
		if (response?.id) {
			setErrors({});
			setTimeout(() => {
				console.log("onUpdateSuccess: Delayed for 2 seconds.");
				setFetching(false);
				// Redirect the user.
				setForceURL("/admin/exercise/" + id);
			}, 2000);
		}
	};

	const onUpdateError = (apiErr) => {
		console.log("onUpdateError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed updating");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onUpdateError: Delayed for 2 seconds.");
			setTopAlertMessage("");
		}, 2000);

		// Scroll to the top of the page.
		window.scrollTo(0, 0);
	};

	const onUpdateDone = () => {
		console.log("onUpdateDone: Starting...");
		setFetching(false);
	};

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});

		const payload = {
			id: id,
			type: type,
			video_type: videoType,
			video_url: videoURL,
			thumbnail_type: thumbnailType,
			thumbnail_url: thumbnailURL,
			name: name,
			alternate_name: alternateName,
			category: category,
			description: description,
			gender: gender,
			movement_type: movementType,
			has_monetization: hasMonetization,
			offer_id: offerID,
			has_timed_lock: hasTimedLock,
			timed_lock: timedLock,
			status: status,
		};

		// Only include video_upload if there's a new video attachment
		if (videoAttachmentID && videoAttachmentID !== "") {
			payload.video_upload = videoAttachmentID;
		}

		// Only include thumbnail_upload if there's a new thumbnail
		if (thumbnailAttachmentID && thumbnailAttachmentID !== "") {
			payload.thumbnail_upload = thumbnailAttachmentID;
		}

		putExerciseUpdateAPI(payload, onUpdateSuccess, onUpdateError, onUpdateDone);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setFetching(true);
			getExerciseDetailAPI(
				id,
				onExerciseDetailSuccess,
				onExerciseDetailError,
				onExerciseDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Exercises", link: "/admin/exercises", icon: faDumbbell},
		{label: "Detail", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-6 sm:px-6 md:px-8">
				{/* Main Content */}
				<div className="bg-white rounded-xl shadow-sm border border-gray-200 animate-fade-in">
					<div className="p-4 sm:p-6 border-b border-gray-200">
						<h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center">
							<FontAwesomeIcon icon={faDumbbell} className="mr-3" />
							Update Exercise
						</h1>
					</div>

					<div className="p-4 sm:p-6">
						<FormErrorBox errors={errors} />

						{isFetching ? (
							<PageLoadingContent displayMessage={"Please wait..."} />
						) : (
							<div className="space-y-8">
								{/* Monetization Section */}
								<section className="space-y-4">
									<h2 className="text-lg font-medium text-gray-900 flex items-center">
										<FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
										Monetization
									</h2>
									<div className="bg-gray-50 rounded-lg p-4 sm:p-6 space-y-4">
										<FormCheckboxField
											label="Enable Monetization"
											name="hasMonetization"
											checked={hasMonetization}
											onChange={(e) => setHasMonetization(e.target.checked)}
											errorText={errors?.hasMonetization}
										/>
										{hasMonetization && (
											<div className="pl-6 border-l-2 border-gray-200 space-y-4">
												<FormCheckboxField
													label="Enable Timed Lock"
													name="hasTimedLock"
													checked={hasTimedLock}
													onChange={(e) => setHasTimedLock(e.target.checked)}
													errorText={errors?.hasTimedLock}
												/>
												{hasTimedLock && (
													<FormSelectField
														label="Lock Duration"
														name="timedLock"
														selectedValue={timedLock}
														onChange={(e) => setTimedLock(e.target.value)}
														options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
														errorText={errors?.timedLock}
													/>
												)}
											</div>
										)}
									</div>
								</section>

								{/* Media Section */}
								<section className="space-y-4">
									<h2 className="text-lg font-medium text-gray-900 flex items-center">
										<FontAwesomeIcon icon={faVideo} className="mr-2" />
										Media
									</h2>
									<div className="bg-gray-50 rounded-lg p-4 sm:p-6 space-y-6">
										{/* Video Upload */}
										<div className="space-y-4">
											<FormRadioField
												label="Video Type"
												name="videoType"
												placeholder="Pick"
												value={videoType}
												opt1Value={EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
												opt1Label="File Upload"
												opt2Value={EXERCISE_VIDEO_TYPE_YOUTUBE}
												opt2Label="YouTube"
												opt3Value={EXERCISE_VIDEO_TYPE_VIMEO}
												opt3Label="Vimeo"
												errorText={errors && errors.videoType}
												onChange={(e) => {
													const newType = parseInt(e.target.value);
													setVideoType(newType);
													if (
														newType ===
														EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
													) {
														setVideoURL("");
													} else {
														setVideoAttachmentID("");
														setVideoAttachmentFilename("");
														setVideoAttachment(null);
													}
												}}
												maxWidth="180px"
												disabled={false}
											/>

											{(() => {
												switch (videoType) {
													case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
														return (
															<>
																<FormAttachmentField
																	label="Video Upload"
																	name="videoUpload"
																	placeholder="Upload file"
																	errorText={errors && errors.videoUpload}
																	attachmentID={videoAttachmentID}
																	setAttachmentID={setVideoAttachmentID}
																	attachmentFilename={videoAttachmentFilename}
																	setAttachmentFilename={
																		setVideoAttachmentFilename
																	}
																	helpText="Upload a video file (MP4, MOV, etc.)"
																	validationText="Maximum size: 500MB"
																	disabled={false}
																/>
															</>
														);
													case EXERCISE_VIDEO_TYPE_YOUTUBE:
														return (
															<FormInputField
																label="YouTube URL"
																name="videoExternalURL"
																placeholder="URL input"
																value={videoURL}
																errorText={errors && errors.videoUrl}
																helpText={`Enter the YouTube video URL`}
																onChange={(e) => {
																	setVideoURL(e.target.value);
																	setVideoAttachmentID("");
																	setVideoAttachmentFilename("");
																	setVideoAttachment(null);
																}}
																isRequired={true}
																maxWidth="380px"
															/>
														);
													case EXERCISE_VIDEO_TYPE_VIMEO:
														return (
															<FormInputField
																label="Vimeo URL"
																name="videoExternalURL"
																placeholder="URL input"
																value={videoURL}
																errorText={errors && errors.videoUrl}
																helpText={`Enter the Vimeo video URL`}
																onChange={(e) => {
																	setVideoURL(e.target.value);
																	setVideoAttachmentID("");
																	setVideoAttachmentFilename("");
																	setVideoAttachment(null);
																}}
																isRequired={true}
																maxWidth="380px"
															/>
														);
													default:
														return null;
												}
											})()}
										</div>

										{/* Thumbnail */}
										<div className="space-y-4">
											<h3 className="text-md font-medium text-gray-900 flex items-center">
												<FontAwesomeIcon icon={faImage} className="mr-2" />
												Thumbnail
											</h3>
											<FormRadioField
												label="Thumbnail Type"
												name="thumbnailType"
												placeholder="Pick"
												value={thumbnailType}
												opt1Value={
													EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
												}
												opt1Label="File Upload"
												opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
												opt2Label="External URL"
												errorText={errors && errors.thumbnailType}
												onChange={(e) => {
													const newType = parseInt(e.target.value);
													setThumbnailType(newType);
													// Clear relevant fields when switching types
													if (
														newType ===
														EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
													) {
														setThumbnailURL("");
													} else {
														setThumbnailAttachmentID("");
														setThumbnailAttachmentFilename("");
													}
												}}
												maxWidth="180px"
												disabled={false}
											/>

											{(() => {
												switch (thumbnailType) {
													case EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE:
														return (
															<>
																<FormAttachmentField
																	label="File Upload"
																	name="thumbnaiUpload"
																	placeholder="Upload file"
																	errorText={errors && errors.thumbnailUpload}
																	attachmentID={thumbnailAttachmentID}
																	setAttachmentID={setThumbnailAttachmentID}
																	attachmentFilename={
																		thumbnailAttachmentFilename
																	}
																	setAttachmentFilename={
																		setThumbnailAttachmentFilename
																	}
																	helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
																	validationText="Maximum size: 10MB"
																	disabled={false}
																/>
															</>
														);
													case EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL:
														return (
															<FormInputField
																label="Thumbnail External URL"
																name="thumbnailUrl"
																placeholder="URL input"
																value={thumbnailURL}
																errorText={errors && errors.thumbnailUrl}
																helpText="Enter the URL of the thumbnail image"
																onChange={(e) => {
																	setThumbnailURL(e.target.value);
																	setThumbnailAttachmentID("");
																	setThumbnailAttachmentFilename("");
																}}
																isRequired={true}
																maxWidth="380px"
															/>
														);
													default:
														return null;
												}
											})()}
										</div>
									</div>
								</section>

								{/* Details Section */}
								<section className="space-y-4">
									<h2 className="text-lg font-medium text-gray-900">
										Exercise Details
									</h2>
									<div className="space-y-4">
										<FormInputField
											label="Name"
											name="name"
											placeholder="Name"
											value={name}
											onChange={(e) => setName(e.target.value)}
											errorText={errors?.name}
										/>
										<FormInputField
											label="Alternate Name"
											name="alternateName"
											placeholder="Alternate Name"
											value={alternateName}
											onChange={(e) => setAlternateName(e.target.value)}
											errorText={errors?.alternateName}
										/>
										<FormTextareaField
											label="Description"
											name="description"
											placeholder="Description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											errorText={errors?.description}
										/>
										<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
											<FormSelectField
												label="Category"
												name="category"
												value={category}
												onChange={(e) => setCategory(parseInt(e.target.value))}
												options={EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION}
												errorText={errors?.category}
											/>
											<FormSelectField
												label="Gender"
												name="gender"
												value={gender}
												onChange={(e) => setGender(e.target.value)}
												options={EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION}
												errorText={errors?.gender}
											/>
											<FormSelectField
												label="Movement Type"
												name="movementType"
												value={movementType}
												onChange={(e) =>
													setMovementType(parseInt(e.target.value))
												}
												options={EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION}
												errorText={errors?.movementType}
											/>
											<FormSelectField
												label="Status"
												name="status"
												value={status}
												onChange={(e) => setStatus(parseInt(e.target.value))}
												options={EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION}
												errorText={errors?.status}
											/>
										</div>
									</div>
								</section>

								{/* Submit Button */}
								<div className="flex justify-end pt-6 border-t border-gray-200">
									<button
										onClick={onSubmitClick}
										disabled={isFetching}
										className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-dark 
                             text-white rounded-lg transition-colors duration-300 disabled:opacity-50
                             disabled:cursor-not-allowed shadow-sm hover:shadow-md">
										<FontAwesomeIcon icon={faSave} className="mr-2" />
										Save Changes
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminExerciseUpdate;

import {useState, useEffect, useRef, useCallback} from "react";

const useTimer = ({
	initialTime = 120, // Default: 120 seconds
	onComplete, // Callback when timer completes
	autoStart = false, // Whether to start automatically
	interval = 1000, // Update interval in milliseconds
	onTick, // Optional callback for each tick
}) => {
	const [time, setTime] = useState(initialTime);
	const [isActive, setIsActive] = useState(autoStart);
	const [isPaused, setIsPaused] = useState(false);
	const timerRef = useRef(null);

	// Helper function to clear existing timer
	const clearExistingTimer = useCallback(() => {
		if (timerRef.current) {
			clearInterval(timerRef.current);
			timerRef.current = null;
		}
	}, []);

	// Start the timer
	const start = useCallback(() => {
		console.log("Timer started with initial time:", initialTime);
		clearExistingTimer();

		setIsActive(true);
		setIsPaused(false);
		setTime(initialTime);

		timerRef.current = setInterval(() => {
			setTime((prevTime) => {
				if (prevTime <= 1) {
					clearExistingTimer();
					setIsActive(false);
					onComplete?.();
					return 0;
				}
				return prevTime - 1;
			});
		}, interval);
	}, [interval, onComplete, initialTime, clearExistingTimer]);

	// Pause the timer
	const pause = useCallback(() => {
		console.log("Attempting to pause...");
		console.log("Current states - isActive:", isActive, "isPaused:", isPaused);

		if (isActive && !isPaused) {
			console.log("Pausing timer");
			clearExistingTimer();
			setIsActive(false);
			setIsPaused(true);
		}
	}, [isActive, isPaused, clearExistingTimer]);

	// Resume the timer
	const resume = useCallback(() => {
		console.log("Timer resumed");
		if (!isPaused) return;

		setIsPaused(false);
		setIsActive(true);

		timerRef.current = setInterval(() => {
			setTime((prevTime) => {
				if (prevTime <= 1) {
					clearExistingTimer();
					setIsActive(false);
					onComplete?.();
					return 0;
				}
				return prevTime - 1;
			});
		}, interval);
	}, [interval, onComplete, clearExistingTimer, isPaused]);

	// Reset the timer
	const reset = useCallback(() => {
		console.log("Timer reset");
		clearExistingTimer();
		setTime(initialTime);
		setIsActive(false);
		setIsPaused(false);
	}, [initialTime, clearExistingTimer]);

	// Stop the timer completely
	const stop = useCallback(() => {
		console.log("Timer stopped");
		clearExistingTimer();
		setTime(initialTime);
		setIsActive(false);
		setIsPaused(false);
	}, [initialTime, clearExistingTimer]);

	// Format time to MM:SS
	const formatTime = useCallback(() => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes}:${seconds.toString().padStart(2, "0")}`;
	}, [time]);

	// Cleanup on unmount
	useEffect(() => {
		return () => clearExistingTimer();
	}, [clearExistingTimer]);

	// Auto-start if enabled
	useEffect(() => {
		if (autoStart) {
			start();
		}
		return () => clearExistingTimer();
	}, [autoStart, start, clearExistingTimer]);

	return {
		time, // Current time in seconds
		isActive, // Whether timer is running
		isPaused, // Whether timer is paused
		start, // Start the timer
		pause, // Pause the timer
		resume, // Resume from pause
		reset, // Reset to initial time
		stop, // Stop the timer completely
		formatTime, // Get formatted time string

		// Additional helper methods
		restart: useCallback(() => {
			reset();
			start();
		}, [reset, start]),

		getProgress: useCallback(() => {
			return ((initialTime - time) / initialTime) * 100;
		}, [time, initialTime]),

		getTimeLeft: useCallback(() => {
			const minutes = Math.floor(time / 60);
			const seconds = time % 60;
			return {minutes, seconds};
		}, [time]),
	};
};

export default useTimer;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAppleAlt,
  faPlus,
  faPencil,
  faTrash,
  faEye,
  faCheckCircle,
  faClock,
  faCalendarAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';

const AdminMemberDetailForNutritionPlans = ({ bid }) => {
  const [nutritionPlans, setNutritionPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Status badge component
  const StatusBadge = ({ status }) => {
    const getStatusConfig = (status) => {
      switch (status.toLowerCase()) {
        case 'active':
          return { color: 'bg-green-100 text-green-800', icon: faCheckCircle };
        case 'pending':
          return { color: 'bg-yellow-100 text-yellow-800', icon: faClock };
        default:
          return { color: 'bg-gray-100 text-gray-800', icon: faClock };
      }
    };

    const config = getStatusConfig(status);
    return (
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${config.color}`}>
        <FontAwesomeIcon icon={config.icon} className="mr-1" />
        {status}
      </span>
    );
  };

  // Plan card component
  const NutritionPlanCard = ({ plan }) => (
    <div className="bg-white rounded-lg border border-gray-200 hover:border-primary transition-colors duration-200">
      <div className="p-4">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-lg font-semibold text-gray-900">{plan.name}</h4>
            <p className="text-sm text-gray-600 mt-1">{plan.description}</p>
          </div>
          <StatusBadge status={plan.status} />
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex items-center text-sm text-gray-600">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-400" />
            <span>Created: {format(new Date(plan.createdAt), 'MMM dd, yyyy')}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-400" />
            <span>Updated: {format(new Date(plan.updatedAt), 'MMM dd, yyyy')}</span>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-gray-900">Meals per day:</span>
            <span className="text-sm text-gray-600">{plan.mealsPerDay}</span>
          </div>
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => handleViewPlan(plan)}
              className="p-2 text-gray-600 hover:text-primary transition-colors duration-200">
              <FontAwesomeIcon icon={faEye} />
            </button>
            <button 
              onClick={() => handleEditPlan(plan)}
              className="p-2 text-gray-600 hover:text-primary transition-colors duration-200">
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button 
              onClick={() => handleDeleteClick(plan)}
              className="p-2 text-gray-600 hover:text-red-500 transition-colors duration-200">
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Delete confirmation modal
  const DeleteModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Delete Nutrition Plan</h3>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete this nutrition plan? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
            Cancel
          </button>
          <button
            onClick={handleDeleteConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors">
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  // Event handlers
  const handleViewPlan = (plan) => {
    // Implement view functionality
    console.log('View plan:', plan);
  };

  const handleEditPlan = (plan) => {
    // Implement edit functionality
    console.log('Edit plan:', plan);
  };

  const handleDeleteClick = (plan) => {
    setSelectedPlan(plan);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Implement delete API call
      console.log('Deleting plan:', selectedPlan);
      setShowDeleteModal(false);
      // Refresh list after deletion
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  // Fetch nutrition plans
  useEffect(() => {
    const fetchNutritionPlans = async () => {
      try {
        // Implement API call to fetch nutrition plans
        // For now, using mock data
        const mockPlans = [
          {
            id: 1,
            name: "Weight Loss Nutrition Plan",
            description: "Customized nutrition plan for healthy weight loss",
            status: "active",
            mealsPerDay: 5,
            createdAt: "2024-03-15T10:00:00Z",
            updatedAt: "2024-03-15T10:00:00Z"
          },
          {
            id: 2,
            name: "Muscle Gain Diet",
            description: "High-protein nutrition plan for muscle development",
            status: "pending",
            mealsPerDay: 6,
            createdAt: "2024-03-14T10:00:00Z",
            updatedAt: "2024-03-14T10:00:00Z"
          }
        ];
        setNutritionPlans(mockPlans);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching nutrition plans:', error);
        setIsLoading(false);
      }
    };

    fetchNutritionPlans();
  }, [bid]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">

        {/* Demo Message Banner */}
      <div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded-lg">
        <div className="flex items-center text-blue-700">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          <span className="text-sm">Demo Mode: Currently displaying mock data for demonstration purposes.</span>
        </div>
      </div>
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-bold text-textDark flex items-center">
          <FontAwesomeIcon icon={faAppleAlt} className="mr-3 text-primaryColor" />
          Nutrition Plans
        </h3>
        <button className="btn-primary flex items-center">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Plan
        </button>
      </div>

      {nutritionPlans.length === 0 ? (
        <div className="text-center py-12">
          <FontAwesomeIcon icon={faAppleAlt} className="text-gray-400 text-4xl mb-4" />
          <h4 className="text-lg font-medium text-gray-900 mb-2">No Nutrition Plans</h4>
          <p className="text-gray-500">This member doesn't have any nutrition plans yet.</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {nutritionPlans.map((plan) => (
            <NutritionPlanCard key={plan.id} plan={plan} />
          ))}
        </div>
      )}

      {showDeleteModal && <DeleteModal />}
    </div>
  );
};

export default AdminMemberDetailForNutritionPlans;
import {useState, useEffect} from "react";
import {useParams, Link, useLocation, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faGauge,
	faEye,
	faBolt,
	faPencilRuler,
	faUsers,
	faMedal,
	faTrophy,
	faInfoCircle,
	faClipboardList,
	faClock,
	faCalendar,
	faStar,
	faLayerGroup,
	faChartLine,
	faRefresh,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	challengeDetailState,
} from "../../../AppState";
import {
	deletefitnessChallengeAPI,
	getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import Layout from "../../Menu/Layout";
import {
	CHALLENGE_CATEGORY_MAP,
	CHALLENGE_CRITERIA_MAP,
} from "../../../Constants/FieldOptions";
import QuillRenderer from "../../Reusable/QuillRenderer";

function MemberFitnessChallengeDetail() {
	// URL Parameters
	const {id} = useParams();
	const location = useLocation();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
	const [challengeDetail, setChallengeDetail] =
		useRecoilState(challengeDetailState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedChallengeForDeletion, setSelectedChallengeForDeletion] =
		useState(null);
	const [activeTab, setActiveTab] = useState("details");

	const timeRemaining = datum.endTime
		? new Date(datum.endTime) > new Date()
			? Math.ceil(
					(new Date(datum.endTime) - new Date()) / (1000 * 60 * 60 * 24)
			  )
			: -1 // Use -1 to indicate ended
		: null;

	// Event handling
	const onDeleteConfirmButtonClick = () => {
		deletefitnessChallengeAPI(id, onDeleteSuccess, onDeleteError, onDeleteDone);
		setSelectedChallengeForDeletion(null);
	};

	// API Success and Error Handlers
	const onChallengeDetailSuccess = (response) => {
		setDatum(response);
		setChallengeDetail(response);
	};

	const onChallengeDetailError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onChallengeDetailDone = () => {
		setFetching(false);
	};

	const onDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Challenge successfully removed.");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/fitness-challenge");
	};

	const onDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Unable to delete the challenge.");
		setTimeout(() => setTopAlertMessage(""), 2000);
		Scroll.animateScroll.scrollToTop();
	};

	const onDeleteDone = () => {
		setFetching(false);
	};

	// Fetch challenge details on mount
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
		setFetching(true);
		getfitnessChallengeDetailAPI(
			id,
			onChallengeDetailSuccess,
			onChallengeDetailError,
			onChallengeDetailDone
		);
	}, [id]);

	useEffect(() => {
		const path = location.pathname;
		if (path.includes("/points")) {
			setActiveTab("points");
		} else if (path.includes("/leaderboard")) {
			setActiveTab("leaderboard");
		} else {
			setActiveTab("details");
		}
	}, [location.pathname]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	// Delete confirmation modal content
	const DeleteConfirmationContent = () => (
		<div className="space-y-6">
			<p className="text-gray-600">
				Are you sure you want to remove this challenge? This action cannot be
				undone.
			</p>

			<div className="flex justify-end space-x-3">
				<button
					onClick={() => setSelectedChallengeForDeletion(null)}
					className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-250">
					Cancel
				</button>
				<button
					onClick={onDeleteConfirmButtonClick}
					className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-hover rounded-md transition-colors duration-250">
					Delete
				</button>
			</div>
		</div>
	);

	const isUserCompleted = () => {
		const currentUserInChallenge = challengeDetail?.users?.find(
			(user) => user.id === currentUser.id
		);
		return currentUserInChallenge?.completed || false;
	};

	// Tab navigation items
	const tabItems = [
		{
			id: "details",
			label: "Detail",
			icon: faEye,
			to: `/fitness-challenge/${id}`,
			show: true,
		},
		{
			id: "points",
			label: "Points",
			icon: faChartLine,
			to: `/fitness-challenge/${id}/points`,
			show:
				challengeDetail?.category === 2 &&
				(timeRemaining === null || timeRemaining >= 0) &&
				!isUserCompleted(),
		},
		{
			id: "leaderboard",
			label: "Leaderboard",
			icon: faTrophy,
			to: `/fitness-challenge/${id}/leaderboard`,
			show: true,
		},
	];

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Challenge",
			icon: faBolt,
			link: "/fitness-challenge",
		},
		{
			label: "Details",
			icon: faEye,
		},
	];
	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			{/* Delete Modal */}
			<Modal
				isOpen={selectedChallengeForDeletion !== null}
				onClose={() => setSelectedChallengeForDeletion(null)}
				header="Confirm Deletion">
				<DeleteConfirmationContent />
			</Modal>

			{/* Main Content */}
			<div className="bg-white rounded-lg shadow-md animate-fade-in">
				{/* Header Section */}
				{datum && (
					<div className="bg-gradient-to-r from-primary/95 to-primary-dark/95 rounded-xl shadow-lg px-6 py-5 mb-6">
						<div className="flex items-center justify-between">
							<div className="flex items-center space-x-3">
								<div className="p-2 bg-white/10 rounded-lg">
									<FontAwesomeIcon
										icon={faBolt}
										className="text-white h-5 w-5"
									/>
								</div>
								<h1 className="text-2xl font-bold text-white">Challenges</h1>
							</div>
							<div className="flex space-x-3">
								<button
									onClick={() => {
										setFetching(true);
										getfitnessChallengeDetailAPI(
											id,
											onChallengeDetailSuccess,
											onChallengeDetailError,
											onChallengeDetailDone
										);
									}}
									className="px-4 py-2 text-sm font-medium text-white bg-white/10 
            hover:bg-white/20 rounded-lg transition-colors duration-150 
            flex items-center space-x-2">
									<FontAwesomeIcon icon={faRefresh} className="h-4 w-4" />
									<span className="hidden sm:inline">Refresh</span>
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Enhanced Tab Navigation */}
				<div className="border-b border-divider bg-gray-50">
					<div className="px-6">
						<nav className="flex space-x-8 -mb-px" aria-label="Tabs">
							{tabItems.map(
								(tab) =>
									tab.show && (
										<Link
											key={tab.id}
											to={tab.to}
											className={`
                group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
                ${
									activeTab === tab.id
										? "border-primary text-primary"
										: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
								}
                transition-all duration-250 ease-in-out
              `}
											aria-current={activeTab === tab.id ? "page" : undefined}>
											<FontAwesomeIcon
												icon={tab.icon}
												className={`
                  mr-2 h-4 w-4
                  ${
										activeTab === tab.id
											? "text-primary"
											: "text-gray-400 group-hover:text-gray-500"
									}
                `}
											/>
											{tab.label}
										</Link>
									)
							)}
						</nav>
					</div>
				</div>

				{/* Enhanced Content Area */}
				<div className="px-6 py-6 bg-gray-50/30">
				

				<FormErrorBox errors={errors} />
					{isFetching ? (
						<PageLoadingContent
							displayMessage={"Loading challenge details..."}
						/>
					) : (
						<div className="space-y-8 animate-slide-up">
							{/* Challenge Info Cards with Enhanced Design */}
							<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
								{/* Challenge Overview Card */}
								<div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md flex flex-col h-[420px] transition-all duration-250 border border-gray-100">
									<div className="flex items-center space-x-3 mb-4 flex-shrink-0">
										<div className="p-2 bg-primary/10 rounded-lg">
											<FontAwesomeIcon
												icon={faInfoCircle}
												className="text-primary h-5 w-5"
											/>
										</div>
										<h3 className="text-lg font-semibold text-gray-900">
											Challenge Overview
										</h3>
									</div>
									<div className="flex-1 custom-scrollbar overflow-y-auto">
										<QuillRenderer
											content={datum.description}
											withBorder={false}
										/>
									</div>
								</div>

								{/* Challenge Details Card */}
								<div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md flex flex-col h-[420px] transition-all duration-250 border border-gray-100">
									<div className="flex items-center space-x-3 mb-4 flex-shrink-0">
										<div className="p-2 bg-primary/10 rounded-lg">
											<FontAwesomeIcon
												icon={faClipboardList}
												className="text-primary h-5 w-5"
											/>
										</div>
										<h3 className="text-lg font-semibold text-gray-900">
											Challenge Details
										</h3>
									</div>
									<div className="flex-1 overflow-y-auto">
										<dl className="space-y-4">
											{[
												{
													label: "Duration",
													value: `${datum.durationInWeeks} weeks`,
													icon: faClock,
												},
												{
													label: "Start Date",
													value: new Date(datum.startTime).toLocaleDateString(
														"en-US",
														{
															year: "numeric",
															month: "long",
															day: "numeric",
															hour: "numeric",
															minute: "numeric",
														}
													),
													icon: faCalendar,
												},
												{
													label: "Maximum Points",
													value: datum.maxPoints,
													icon: faStar,
												},
												{
													label: "Challenge Type",
													value: CHALLENGE_CATEGORY_MAP[datum.category],
													icon: faLayerGroup,
												},
												{
													label: "Challenge Criteria",
													value: CHALLENGE_CRITERIA_MAP[datum.criteria],
													icon: faCheck,
												},
											].map((item, index) => (
												<div
													key={index}
													className="flex items-center space-x-3">
													<FontAwesomeIcon
														icon={item.icon}
														className="text-gray-400 h-4 w-4"
													/>
													<div>
														<dt className="text-sm font-medium text-gray-500">
															{item.label}
														</dt>
														<dd className="text-sm text-gray-900">
															{item.value}
														</dd>
													</div>
												</div>
											))}
										</dl>
									</div>
								</div>
							</div>

							{/* Enhanced Badge Section */}
							{datum.earnsBadge && (
								<div className="bg-gradient-to-r from-primary to-primary-light p-[1px] rounded-xl animate-gradient-flow">
									<div className="bg-white rounded-xl p-6">
										<div className="flex flex-col items-center space-y-6">
											<div className="flex items-center space-x-3">
												<div className="p-2 bg-primary/10 rounded-lg">
													<FontAwesomeIcon
														icon={faMedal}
														className="text-primary h-6 w-6"
													/>
												</div>
												<h3 className="text-xl font-bold text-gray-900">
													Earn the {datum.badgeName} Badge!
												</h3>
											</div>

											<div className="relative">
												<div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-full blur-xl"></div>
												<img
													src={
														datum?.badgeDetails?.thumbnailObjectUrl || datum?.badgeDetails?.thumbnailUrl
													}
													alt={`${datum.badgeName} badge`}
													className="relative h-32 w-auto object-contain [image-rendering:high-quality]"
												/>
											</div>

											<p className="text-center text-gray-600 max-w-md leading-relaxed">
												{datum.criteria === 1
													? `Complete the challenge to automatically claim your ${datum.badgeName} badge!`
													: `Complete the challenge and wait for admin approval to receive your ${datum.badgeName} badge!`}
											</p>
										</div>
									</div>
								</div>
							)}

							{/* Rules Section */}
							<div className="space-y-4">
								<h3 className="text-lg font-semibold text-gray-900 flex items-center space-x-2">
									<FontAwesomeIcon
										icon={faPencilRuler}
										className="text-primary"
									/>
									<span>Challenge Rules</span>
								</h3>

								<div className="space-y-4">
									{datum?.rules?.map((rule, index) => (
										<div
											key={index}
											className="bg-gray-50 rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-250">
											<h4 className="text-md font-semibold text-gray-900 mb-2">
												Rule {index + 1}: {rule.name}
											</h4>
											<p className="text-gray-600">{rule.description}</p>
										</div>
									))}
								</div>
							</div>

							{/* Participants Section */}
							<div className="space-y-4">
								<h3 className="text-lg font-semibold text-gray-900 flex items-center space-x-2">
									<FontAwesomeIcon icon={faUsers} className="text-primary" />
									<span>Participants</span>
								</h3>

								<div className="bg-gray-50 rounded-lg p-6 hover:shadow-md transition-shadow duration-250">
									{datum?.users?.length > 0 ? (
										<ul className="divide-y divide-gray-200">
											{datum.users.map((user, index) => (
												<li
													key={index}
													className="py-4 flex items-center justify-between">
													<span className="text-gray-900 font-medium">
														{user.name}
													</span>
													<span
														className={`
                                            px-3 py-1 rounded-full text-sm font-medium
                                            ${
																							user.completed
																								? "bg-green-100 text-green-800"
																								: "bg-yellow-100 text-yellow-800"
																						}
                                        `}>
														{user.completed ? "Completed" : "In Progress"}
													</span>
												</li>
											))}
										</ul>
									) : (
										<p className="text-gray-500 text-center py-4">
											No participants have joined this challenge yet.
										</p>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
}

export default MemberFitnessChallengeDetail;

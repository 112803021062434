import getCustomAxios from "../Helpers/customAxios";
import {camelizeKeys, decamelizeKeys, decamelize} from "humps";
import {DateTime} from "luxon";

import {
	MMA_FITNESS_EXERCISES_API_ENDPOINT,
	MMA_FITNESS_EXERCISE_API_ENDPOINT,
	// MMA_FITNESS_BRANCH_EXERCISE_SELECT_OPTIONS_API_ENDPOINT
} from "../Constants/API";

export function getExerciseListAPI(
	filtersMap = new Map(),
	onSuccessCallback,
	onErrorCallback,
	onDoneCallback
) {
	const axios = getCustomAxios();

	// The following code will generate the query parameters for the url based on the map.
	let aURL = MMA_FITNESS_EXERCISES_API_ENDPOINT;
	filtersMap.forEach((value, key) => {
		let decamelizedkey = decamelize(key);
		if (aURL.indexOf("?") > -1) {
			aURL += "&" + decamelizedkey + "=" + value;
		} else {
			aURL += "?" + decamelizedkey + "=" + value;
		}
	});

	axios
		.get(aURL)
		.then((successResponse) => {
			const responseData = successResponse.data;

			// Snake-case from API to camel-case for React.
			const data = camelizeKeys(responseData);

			// Bugfixes.
			// console.log("getExerciseListAPI | pre-fix | results:", data);
			if (
				data.results !== undefined &&
				data.results !== null &&
				data.results.length > 0
			) {
				data.results.forEach((item, index) => {
					item.createdAt = DateTime.fromISO(item.createdAt).toLocaleString(
						DateTime.DATETIME_MED
					);
					// console.log(item, index);
				});
			}
			// console.log("getExerciseListAPI | post-fix | results:", data);

			// Return the callback data.
			onSuccessCallback(data);
		})
		.catch((exception) => {
			let errors = camelizeKeys(exception);
			onErrorCallback(errors);
		})
		.then(onDoneCallback);
}

export function postExerciseCreateAPI(
	decamelizedData,
	onSuccessCallback,
	onErrorCallback,
	onDoneCallback
) {
	const axios = getCustomAxios();

	axios
		.post(MMA_FITNESS_EXERCISES_API_ENDPOINT, decamelizedData)
		.then((successResponse) => {
			const responseData = successResponse.data;

			// Snake-case from API to camel-case for React.
			const data = camelizeKeys(responseData);

			// Return the callback data.
			onSuccessCallback(data);
		})
		.catch((exception) => {
			let errors = camelizeKeys(exception);
			onErrorCallback(errors);
		})
		.then(onDoneCallback);
}

export function getExerciseDetailAPI(
	exerciseID,
	onSuccessCallback,
	onErrorCallback,
	onDoneCallback
) {
	const axios = getCustomAxios();
	axios
		.get(MMA_FITNESS_EXERCISE_API_ENDPOINT.replace("{id}", exerciseID))
		.then((successResponse) => {
			const responseData = successResponse.data;

			// Snake-case from API to camel-case for React.
			const data = camelizeKeys(responseData);

			// For debugging purposeso pnly.
			// console.log(data);

			// Return the callback data.
			onSuccessCallback(data);
		})
		.catch((exception) => {
			let errors = camelizeKeys(exception);
			onErrorCallback(errors);
		})
		.then(onDoneCallback);
}

export function putExerciseUpdateAPI(
	decamelizedData,
	onSuccessCallback,
	onErrorCallback,
	onDoneCallback
) {
	const axios = getCustomAxios();
	const url = MMA_FITNESS_EXERCISE_API_ENDPOINT.replace(
		"{id}",
		decamelizedData.id
	);

	console.log("Making PUT request to:", url);
	console.log("With data:", decamelizedData);

	axios
		.put(url, decamelizedData)
		.then((successResponse) => {
			console.log("Raw API Success Response:", successResponse);
			const responseData = successResponse.data;

			// If the response is null/empty but status is 200, consider it a success
			if (responseData === null) {
				onSuccessCallback({
					id: decamelizedData.id,
					message: "Update successful",
				});
				return;
			}

			const data = camelizeKeys(responseData);
			console.log("Processed Success Data:", data);
			onSuccessCallback(data);
		})
		.catch((exception) => {
			console.log("Raw API Error:", exception);

			// More detailed error handling
			let errors;
			if (exception.response?.data) {
				errors = camelizeKeys(exception.response.data);
			} else if (exception.message) {
				errors = {message: exception.message};
			} else {
				errors = {message: "An unknown error occurred"};
			}

			console.log("Processed Error Data:", errors);
			onErrorCallback(errors);
		})
		.finally(() => {
			console.log("Request completed");
			if (onDoneCallback) onDoneCallback();
		});
}

export function deleteExerciseAPI(
	id,
	onSuccessCallback,
	onErrorCallback,
	onDoneCallback
) {
	const axios = getCustomAxios();
	axios
		.delete(MMA_FITNESS_EXERCISE_API_ENDPOINT.replace("{id}", id))
		.then((successResponse) => {
			const responseData = successResponse.data;

			// Snake-case from API to camel-case for React.
			const data = camelizeKeys(responseData);

			// Return the callback data.
			onSuccessCallback(data);
		})
		.catch((exception) => {
			let errors = camelizeKeys(exception);
			onErrorCallback(errors);
		})
		.then(onDoneCallback);
}

import React from "react";

function FormInputFieldWithButton({ 
  label, 
  name, 
  placeholder, 
  value, 
  type = "text", 
  errorText, 
  validationText, 
  helpText, 
  onChange, 
  maxWidth, 
  disabled = false, 
  onButtonClick, 
  buttonLabel 
}) {
  return (
    <div className="mb-4 animate-fade-in">
      {/* Label */}
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>

      {/* Input Group */}
      <div className="flex items-center space-x-2">
        {/* Input Container */}
        <div className="relative flex-1">
          <input
            className={`
              w-full px-4 py-2 
              rounded-lg border 
              transition-colors duration-DEFAULT
              focus:outline-none focus:ring-2 focus:ring-primary/25
              disabled:bg-gray-50 disabled:text-gray-500
              ${errorText 
                ? 'border-red-500 bg-red-50 focus:border-red-500' 
                : 'border-gray-200 focus:border-primary'
              }
            `}
            style={{ maxWidth: maxWidth }}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoComplete="off"
          />
        </div>

        {/* Button */}
        <button
          onClick={onButtonClick}
          disabled={disabled}
          className={`
            px-4 py-2 
            rounded-lg
            transition-colors duration-DEFAULT
            ${disabled 
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-primary text-white hover:bg-primary-hover active:bg-primary-dark'
            }
          `}
        >
          {buttonLabel}
        </button>
      </div>

      {/* Error Message */}
      {errorText && (
        <p className="mt-1 text-sm text-red-500 animate-slideUp">
          {errorText}
        </p>
      )}

      {/* Help Text */}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">
          {helpText}
        </p>
      )}

      {/* Validation Text */}
      {validationText && (
        <p className="mt-1 text-sm text-primary">
          {validationText}
        </p>
      )}
    </div>
  );
}

export default FormInputFieldWithButton;
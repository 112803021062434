import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrophy,
	faFire,
	faBolt,
	faStar,
	faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {PROGRESS_BAR_MOTIVATIONAL_CONTENT} from "../../Constants/FieldOptions";

const ProgressBar = ({currentLevel, currentLevelDetails}) => {
	const progress = currentLevel?.challengesCompleted || 0;
	const totalChallenges = currentLevelDetails?.numChallenges || 1;
	const progressPercentage = (progress / totalChallenges) * 100;

	const getLevelIcon = () => {
		if (progressPercentage === 100) return faTrophy;
		if (progressPercentage >= 75) return faFire;
		if (progressPercentage >= 50) return faBolt;
		if (progressPercentage >= 25) return faStar;
		return faChartLine;
	};

	// Use useMemo to maintain stable motivational content across renders
	const motivationalContent = useMemo(() => {
		const messages = PROGRESS_BAR_MOTIVATIONAL_CONTENT;
		const level =
			messages.find((m) => progressPercentage <= m.threshold) ||
			messages[messages.length - 1];
		const randomIndex = Math.floor(Math.random() * level.variations.length);
		const variation = level.variations[randomIndex];

		return {
			...variation,
			color: level.color,
		};
	}, [progressPercentage]);

	return (
		<div className="flex items-center space-x-3 h-8">
			{/* Level Icon */}
			<div className="flex items-center">
				<FontAwesomeIcon
					icon={getLevelIcon()}
					className={`${motivationalContent.color} text-lg`}
				/>
			</div>

			{/* Level Name & Progress */}
			<div className="flex flex-col justify-center">
				<div className="flex items-baseline space-x-2">
					<span className="text-sm font-medium text-white">
						{currentLevelDetails?.name || "Level"}
					</span>
					{/* <span className={`text-[10px] ${motivationalContent.color} font-medium`}>
            {motivationalContent.text}
          </span> */}
				</div>

				{/* Progress Bar and Motivational Subtext */}
				<div className="flex flex-col">
					<div className="relative w-[180px] h-1.5 bg-gray-800 rounded-full overflow-hidden">
						<div
							className="absolute top-0 left-0 h-full rounded-full transition-all duration-300"
							style={{
								width: `${progressPercentage}%`,
								background: "linear-gradient(90deg, #FF6B00, #FF8A00)",
							}}
						/>
					</div>
					<span
						className={`text-[12px] ${motivationalContent.color} italic text-gray-400 mt-0.5`}>
						{motivationalContent.subtext}
					</span>
				</div>
			</div>

			{/* Progress Counter & Percentage */}
			<div className="flex items-center space-x-2">
				<span className="text-xs text-gray-400 font-medium">
					{`${progress}/${totalChallenges}`}
				</span>
				<div className={`text-sm font-semibold ${motivationalContent.color}`}>
					{Math.round(progressPercentage)}%
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;

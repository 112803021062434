import React from 'react';

const StatusBadge = ({ 
    status, 
    icon, 
    text, 
    tooltip, 
    variant = 'warning', // or 'success', 'error', 'info'
    action = null, // Optional action button
    isLoading = false // Loading state for action
  }) => {
    const variants = {
      warning: 'bg-yellow-50 border-yellow-200 text-yellow-600',
      error: 'bg-red-50 border-red-200 text-red-600',
      success: 'bg-green-50 border-green-200 text-green-600',
      info: 'bg-blue-50 border-blue-200 text-blue-600'
    };
  
    return (
      <div className={`inline-flex items-center gap-1.5 px-2.5 py-1 
                       border rounded-md shadow-sm animate-fade-in
                       ${variants[variant]}`}>
        {icon}
        <span className="text-xs font-medium font-secondary">
          {text}
        </span>

        {action && (
          <>
            {/* Divider */}
            <div className={`h-4 w-px ${variant === 'error' ? 'bg-red-200' : 
              variant === 'warning' ? 'bg-yellow-200' : 
              variant === 'success' ? 'bg-green-200' : 'bg-blue-200'}`} 
            />

            {/* Action Button */}
            <button
              onClick={action.onClick}
              disabled={isLoading}
              className={`
                inline-flex items-center gap-1 px-2 py-1 
                text-xs font-medium font-secondary
                rounded-md transition-all duration-200
                focus:outline-none focus:ring-2
                ${variant === 'error' 
                  ? 'hover:bg-red-100 focus:ring-red-500/20' 
                  : variant === 'warning'
                  ? 'hover:bg-yellow-100 focus:ring-yellow-500/20'
                  : variant === 'success'
                  ? 'hover:bg-green-100 focus:ring-green-500/20'
                  : 'hover:bg-blue-100 focus:ring-blue-500/20'
                }
                ${isLoading ? 'opacity-50 cursor-wait' : ''}
              `}
            >
              {isLoading ? (
                <>
                  <svg 
                    className="animate-spin -ml-1 mr-2 h-3 w-3" 
                    fill="none" 
                    viewBox="0 0 24 24"
                  >
                    <circle 
                      className="opacity-25" 
                      cx="12" 
                      cy="12" 
                      r="10" 
                      stroke="currentColor" 
                      strokeWidth="4"
                    />
                    <path 
                      className="opacity-75" 
                      fill="currentColor" 
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Verifying...
                </>
              ) : (
                <>
                  {action.text}
                  {action.icon}
                </>
              )}
            </button>
          </>
        )}

        {tooltip && (
          <div className="group relative">
            <svg 
              className="w-3 h-3 cursor-help" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
              />
            </svg>
            <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-48 
                          hidden group-hover:block animate-fade-in">
              <div className="bg-gray-900 text-white text-xs rounded-lg py-1.5 px-2 
                            shadow-lg">
                {tooltip}
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default StatusBadge;
import React from "react";
import {useRecoilState} from "recoil";
import {currentUserState} from "../../../AppState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrophy, faLock} from "@fortawesome/free-solid-svg-icons";

const Badges = ({badgeDetails, badgesEarned}) => {
	const [currentUser] = useRecoilState(currentUserState);

	// Merge badge details with earned counts
	const getBadgeWithCount = (badge) => {
		const earned = badgesEarned?.find((b) => b.id === badge.id);
		return {
			...badge,
			status: earned ? "earned" : "locked",
			count: earned?.count || 0,
		};
	};

	const BadgeCard = ({badge}) => {
		const mergedBadge = getBadgeWithCount(badge);

		return (
			<div className="relative group">
				<div className="absolute top-2 right-2 z-10">
					<span
						className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
							mergedBadge.status === "earned"
								? "bg-green-100 text-green-800"
								: "bg-gray-100 text-gray-800"
						}`}>
						{mergedBadge.status === "earned"
							? `Earned ${mergedBadge.count}x`
							: "Locked"}
					</span>
				</div>

				{/* Badge Image */}
				<div className="p-4">
					<div className="relative w-full aspect-square flex items-center justify-center">
						<img
							src={mergedBadge.thumbnailUrl || mergedBadge.thumbnailObjectUrl}
							alt={mergedBadge.name}
							className={`w-20 h-20 object-contain transition-transform duration-300 
                         group-hover:scale-110 ${
														mergedBadge.status === "locked" ? "opacity-40" : ""
													}`}
						/>
						{mergedBadge.status === "locked" && (
							<FontAwesomeIcon
								icon={faLock}
								className="text-gray-400 text-xl absolute inset-0 m-auto"
							/>
						)}
					</div>
				</div>

				{/* Badge Info */}
				<div className="p-4 bg-gray-50">
					<h3 className="text-sm font-medium text-gray-900 truncate">
						{mergedBadge.name}
					</h3>
					<p className="mt-1 text-xs text-gray-500 line-clamp-2">
						{mergedBadge.description}
					</p>
				</div>

				{/* Hover Details */}
				<div className="absolute inset-0 bg-black bg-opacity-75 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-4">
					<div className="text-center text-white">
						<p className="text-sm mb-3">{mergedBadge.description}</p>
						<div className="text-xs text-white/80 mt-2">
							{mergedBadge.criteria === 2 &&
								`Completed ${mergedBadge.count} challenge(s)`}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="space-y-6">
			<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
				{badgeDetails?.map((badge) => (
					<BadgeCard key={badge.id} badge={badge} />
				))}
			</div>

			{/* Empty State */}
			{(!badgeDetails || badgeDetails.length === 0) && (
				<div className="text-center py-12">
					<div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
						<FontAwesomeIcon
							icon={faTrophy}
							className="text-gray-400 text-xl"
						/>
					</div>
					<h3 className="text-lg font-medium text-gray-900">No Badges Yet</h3>
					<p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
						Start completing challenges to earn your first badge
					</p>
				</div>
			)}
		</div>
	);
};

export default Badges;

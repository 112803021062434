import React from "react";
import DOMPurify from "dompurify";

function QuillRenderer({
	label,
	content,
	errorText,
	helpText,
	maxWidth,
	className = "",
	containerClassName = "",
	withBorder = true,
}) {
	// Sanitize the HTML content
	const sanitizedContent = DOMPurify.sanitize(content);

	// Container style for max width
	const containerStyle = maxWidth ? {maxWidth} : {};

	return (
		<div
			className={`quill-renderer-field ${containerClassName}`}
			style={containerStyle}>
			{/* Label Section */}
			{label && <label className="quill-renderer-label">{label}</label>}

			{/* Content Section */}
			<div
				className={`${withBorder ? "quill-renderer-content" : ""} 
					${className} ${
					errorText ? "has-error" : ""
				}`}>
				<div
					className="quill-rendered-html"
					dangerouslySetInnerHTML={{__html: sanitizedContent}}
				/>
			</div>

			{/* Error Message */}
			{errorText && <p className="quill-renderer-error">{errorText}</p>}

			{/* Help Text */}
			{helpText && <p className="quill-renderer-help">{helpText}</p>}
		</div>
	);
}

export default QuillRenderer;

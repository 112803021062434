import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function FormQuillField({
	label,
	name,
	placeholder,
	value,
	errorText,
	helpText,
	onChange,
	isRequired,
	maxWidth,
	disabled = false,
}) {
	// Default Quill modules configuration
	const modules = {
		toolbar: [
			["bold", "italic", "underline", "strike"],
			["blockquote", "code-block"],
			[{header: 1}, {header: 2}],
			[{list: "ordered"}, {list: "bullet"}],
			[{script: "sub"}, {script: "super"}],
			["clean"],
		],
	};

	// Handle Quill's onChange directly
	const handleChange = (content) => {
		// Create a synthetic event object to maintain compatibility with existing form handlers
		const syntheticEvent = {
			target: {
				name: name,
				value: content,
			},
		};
		onChange(syntheticEvent);
	};

	let containerStyle = {maxWidth};
	let quillClassName = "quill-editor";
	if (errorText) {
		quillClassName += " is-danger";
	}

	return (
		<div className="field pb-4" style={containerStyle}>
			<label className="label">
				{label}
				{isRequired && <span className="has-text-danger">*</span>}
			</label>
			<div className="control">
				<ReactQuill
					theme="snow"
					value={value || ""} // Ensure value is never undefined
					onChange={handleChange}
					placeholder={placeholder}
					modules={modules}
					readOnly={disabled}
					className={quillClassName}
				/>
			</div>
			{errorText && <p className="help is-danger">{errorText}</p>}
			{helpText && <p className="help">{helpText}</p>}
		</div>
	);
}

export default FormQuillField;

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { routeConfig } from './routes/routeConfig';
import { generateRoutes } from './routes/routesGenerator';
import AnonymousCurrentUserRedirector from './Components/Misc/AnonymousCurrentUserRedirector';
import TwoFactorAuthenticationRedirector from './Components/Misc/TwoFactorAuthenticationRedirector';

import TopAlertBanner from './Components/Misc/TopAlertBanner';
import GoogleFitRequiresLoginAgainTopBanner from './Components/Misc/GoogleFitRequiresLoginAgainTopBanner';
import Footer from './Components/Menu/Footer';

const queryClient = new QueryClient();

function AppRoute() {
  return (
    <div className="w-full">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AnonymousCurrentUserRedirector />
            <TwoFactorAuthenticationRedirector />
            <TopAlertBanner />
            <GoogleFitRequiresLoginAgainTopBanner />
            
            <Routes>
				{/* Admin Routes */}
				<Route path="/admin">
					{generateRoutes(routeConfig.admin, 'admin')}
				</Route>

				{/* Member Routes */}
				{generateRoutes(routeConfig.member, 'member')}

				{/* Public Routes */}
				{generateRoutes(routeConfig.public, 'public')}

				{/* Account Routes */}
				{generateRoutes(routeConfig.account, 'account')}

				{/* 404 Route */}
				{generateRoutes(routeConfig.errors, 'errors')}
            </Routes>

            <Footer />
          </Router>
        </QueryClientProvider>
      </RecoilRoot>
    </div>
  );
}

export default AppRoute;
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";

const DragSortableList = ({items, onSortChange}) => {
	const [list, setList] = useState(items);
	const [draggedItem, setDraggedItem] = useState(null);

	useEffect(() => {
		setList(items);
		return () => {};
	}, [items]);

	const handleDragEnter = (index) => {
		if (draggedItem === null) return;
		const newList = [...list];
		const draggedItemContent = newList[draggedItem];
		newList.splice(draggedItem, 1);
		newList.splice(index, 0, draggedItemContent);
		setList(newList);
		setDraggedItem(index);
		onSortChange(newList);
	};

	const handleDragEnd = () => {
		setDraggedItem(null);
	};

	const handleDragStart = (index, event) => {
		event.dataTransfer.setData("text/plain", "");
		setDraggedItem(index);
	};

	return (
		<ul className="w-full space-y-3">
			{list.map((item, index) => (
				<li
					key={index}
					draggable
					onDragStart={(event) => handleDragStart(index, event)}
					onDragEnter={() => handleDragEnter(index)}
					onDragEnd={handleDragEnd}
					className={`
            transform transition-all duration-300 ease-in-out
            ${
							draggedItem === index
								? "scale-[1.02] ring-2 ring-primary/50 shadow-lg"
								: ""
						}
          `}>
					<div
						className="
            group
            relative
            bg-white
            border border-gray-200/75
            rounded-xl
            shadow-sm
            hover:shadow-md
            hover:border-primary/20
            transition-all
            duration-300
            overflow-hidden
          ">
						{/* Gradient overlay on hover */}
						<div
							className="
              absolute inset-0
              bg-gradient-to-r from-primary/0 via-primary/[0.02] to-primary/[0.03]
              opacity-0 group-hover:opacity-100
              transition-opacity duration-300
            "
						/>

						{/* Content container */}
						<div
							className="
              relative
              p-4
              flex items-center justify-between
              gap-4
            ">
							{/* Left side content */}
							<div className="flex-grow min-w-0">
								<div className="text-gray-700 font-medium truncate">
									{item.content}
								</div>
							</div>

							{/* Drag handle */}
							<div
								className="
                  flex items-center justify-center
                  w-12 h-12
                  rounded-xl
                  cursor-move
                  text-gray-400
                  hover:text-primary
                  hover:bg-primary/5
                  active:scale-95
                  transition-all
                  duration-300
                  group-hover:scale-105
                ">
								<FontAwesomeIcon
									icon={faBars}
									className="
                      text-lg
                      transition-all duration-500
                      group-hover:rotate-180
                    "
								/>
							</div>
						</div>

						{/* Bottom border indicator */}
						<div
							className="
              h-1
              w-full
              bg-gradient-to-r from-primary/10 via-primary/20 to-primary/10
              opacity-0
              group-hover:opacity-100
              transition-opacity
              duration-300
            "
						/>
					</div>
				</li>
			))}
		</ul>
	);
};

export default DragSortableList;

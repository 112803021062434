import React, { useState, useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faKey,
  faTriangleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import { postPasswordResetAPI } from "../../API/gateway";
import { topAlertMessageState, topAlertStatusState } from "../../AppState";

function PasswordReset() {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("q");

  // Global state
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  // Component states
  const [errors, setErrors] = useState({});
  const [validation, setValidation] = useState({
    password: false,
    passwordRepeated: false,
  });
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");
  const [forceURL, setForceURL] = useState("");
  const [wasPasswordSent, setWasPasswordSent] = useState(false);

  // Event Handlers
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setValidation(prev => ({ ...prev, password: false }));
  };

  const onPasswordRepeatedChange = (e) => {
    setPasswordRepeated(e.target.value);
    setValidation(prev => ({ ...prev, passwordRepeated: false }));
  };

  const onButtonClick = () => {
    const newErrors = {};
    const newValidation = {};

    if (!password) {
      newErrors.password = "Password is required";
    } else {
      newValidation.password = true;
    }

    if (!passwordRepeated) {
      newErrors.passwordRepeated = "Password confirmation is required";
    } else if (password !== passwordRepeated) {
      newErrors.passwordRepeated = "Passwords do not match";
    } else {
      newValidation.passwordRepeated = true;
    }

    setErrors(newErrors);
    setValidation(newValidation);

    if (Object.keys(newErrors).length === 0) {
      postPasswordResetAPI(
        verificationCode,
        password,
        passwordRepeated,
        onPasswordResetSuccess,
        onPasswordResetError,
        onPasswordResetDone
      );
    } else {
      var scroll = Scroll.animateScroll;
      scroll.scrollToTop();
    }
  };

  // API Handlers
  function onPasswordResetSuccess() {
    setTopAlertMessage("Password successfully reset");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setWasPasswordSent(true);
  }

  function onPasswordResetError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onPasswordResetDone() {
    console.log("Password reset completed");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat" 
      style={{
        backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`
      }}>
      <div className="max-w-md mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="bg-darkShade/90 backdrop-blur-sm rounded-2xl shadow-2xl p-8">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <img
              src="/static/logo.png"
              alt="Logo"
              className="w-32 h-32 rounded-full border-4 border-main/20 hover:border-main/40 transition-colors duration-300"
            />
          </div>

          {!wasPasswordSent ? (
            <div className="space-y-6">
              <div className="text-center">
                <h1 className="text-2xl font-bold text-lightShade mb-2">
                  Password Reset
                </h1>
                <p className="text-lightShade/60">
                  Please enter your new password.
                </p>
              </div>

              <FormErrorBox errors={errors} />

              <FormInputField
                label="Password"
                name="password"
                type="password"
                placeholder="Enter your new password"
                value={password}
                errorText={errors.password}
                validationText={validation.password ? "Password is valid" : ""}
                onChange={onPasswordChange}
                maxWidth="100%"
              />

              <FormInputField
                label="Confirm Password"
                name="passwordRepeated"
                type="password"
                placeholder="Confirm your new password"
                value={passwordRepeated}
                errorText={errors.passwordRepeated}
                validationText={validation.passwordRepeated ? "Passwords match" : ""}
                onChange={onPasswordRepeatedChange}
                maxWidth="100%"
              />

              <button
                onClick={onButtonClick}
                className="w-full py-3 px-4 bg-main text-darkShade rounded-lg
                  hover:bg-main/90 transition-colors duration-200 flex items-center 
                  justify-center font-medium"
              >
                Reset Password
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          ) : (
            <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-6 text-center">
              <FontAwesomeIcon 
                icon={faCheckCircle} 
                className="text-green-500 text-3xl mb-4" 
              />
              <h2 className="text-xl font-semibold text-lightShade mb-2">
                Password Reset Complete
              </h2>
              <p className="text-lightShade/60">
                Your password has been successfully reset. You can now log in with your new password.
              </p>
            </div>
          )}

          <div className="mt-6 text-center">
            <Link 
              to="/login"
              className="text-main hover:text-main/80 transition-colors duration-200 
                inline-flex items-center text-sm"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back to Login
            </Link>
          </div>
        </div>

        {/* Help Section */}
        <div className="mt-8 text-center text-lightShade">
          <p className="font-medium mb-2">Need help?</p>
          <div className="space-y-1">
            <a 
              href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
              className="block text-main hover:text-main/80 transition-colors duration-200"
            >
              {process.env.REACT_APP_ORGANIZATION_EMAIL}

            </a>
            <a 
              href={`tel:${process.env.REACT_APP_ORGANIZATION_PHONE}`}


              className="block text-main hover:text-main/80 transition-colors duration-200"
            >
              {process.env.REACT_APP_ORGANIZATION_PHONE}
            </a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default PasswordReset;
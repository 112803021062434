import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faVideo,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faDumbbell,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faCogs,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {getExerciseDetailAPI, deleteExerciseAPI} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import DataDisplayRowOffer from "../../Reusable/DataDisplayRowOffer";
import DataDisplayRowCheckbox from "../../Reusable/DataDisplayRowCheckbox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_TYPE_WITH_EMPTY_OPTIONS,
	EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
	EXERCISE_TYPE_SYSTEM,
} from "../../../Constants/App";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowRadio from "../../Reusable/DataDisplayRowRadio";
import DataDisplayRowSelect from "../../Reusable/DataDisplayRowSelect";
import DataDisplayRowImage from "../../Reusable/DataDisplayRowImage";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminExerciseDetail() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [tabIndex, setTabIndex] = useState(1);
	const [selectedExerciseForDeletion, setSelectedExerciseForDeletion] =
		useState(null);

	////
	//// Event handling.
	////

	const onDeleteConfirmButtonClick = () => {
		console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

		deleteExerciseAPI(
			selectedExerciseForDeletion.id,
			onExerciseDeleteSuccess,
			onExerciseDeleteError,
			onExerciseDeleteDone
		);
		setSelectedExerciseForDeletion(null);
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onExerciseDetailSuccess(response) {
		console.log("onExerciseDetailSuccess: Starting...");
		setDatum(response);
	}

	function onExerciseDetailError(apiErr) {
		console.log("onExerciseDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onExerciseDetailDone() {
		console.log("onExerciseDetailDone: Starting...");
		setFetching(false);
		Scroll.animateScroll.scrollToTop();
	}

	// --- Delete --- //

	function onExerciseDeleteSuccess(response) {
		console.log("onExerciseDeleteSuccess: Starting..."); // For debugging purposes only.

		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Exercise deleted");
		setTimeout(() => {
			console.log(
				"onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect back to the members page.
		setForceURL("/admin/exercises");
	}

	function onExerciseDeleteError(apiErr) {
		console.log("onExerciseDeleteError: Starting..."); // For debugging purposes only.
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			console.log(
				"onExerciseDeleteError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onExerciseDeleteDone() {
		console.log("onExerciseDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getExerciseDetailAPI(
				id,
				onExerciseDetailSuccess,
				onExerciseDetailError,
				onExerciseDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, []);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Exercises", link: "/admin/exercises", icon: faDumbbell},
		{label: "Detail", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-6 sm:px-6 md:px-8">
				{/* Main Content */}
				<div className="bg-white rounded-xl shadow-sm border border-gray-200 animate-fade-in">
					{datum && (
						<>
							{/* Header Section */}
							<div className="p-4 sm:p-6 border-b border-gray-200">
								<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
									<h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center">
										<FontAwesomeIcon icon={faDumbbell} className="mr-3" />
										Exercise Details
									</h1>
									<div className="flex flex-wrap items-center gap-3">
										<Link
											to={`/admin/exercise/${id}/update`}
											className="inline-flex items-center px-4 py-2 bg-white text-primary hover:text-primary-dark rounded-lg border border-gray-200 hover:border-primary transition-all duration-300 shadow-sm">
											<FontAwesomeIcon icon={faPencil} className="mr-2" />
											<span>Edit</span>
										</Link>
										<button
											onClick={() => setSelectedExerciseForDeletion(datum)}
											className="inline-flex items-center px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-300 shadow-sm">
											<FontAwesomeIcon icon={faTrash} className="mr-2" />
											<span>Delete</span>
										</button>
									</div>
								</div>
							</div>

							{/* Content Section */}
							<div className="p-4 sm:p-6">
								<FormErrorBox errors={errors} />

								{isFetching ? (
									<PageLoadingContent displayMessage={"Please wait..."} />
								) : (
									<div className="space-y-8">
										{/* Video Section */}
										<section className="space-y-4">
											<h2 className="text-lg font-medium text-gray-900 flex items-center">
												<FontAwesomeIcon icon={faVideo} className="mr-2" />
												Video
											</h2>
											<div className="bg-gray-50 rounded-lg p-4">
												{(() => {
													switch (datum.videoType) {
														case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
															return (
																<video
																	className="w-full aspect-video rounded-lg"
																	controls>
																	<source
																		src={datum.videoObjectUrl}
																		type="video/mp4"
																	/>
																</video>
															);
														case EXERCISE_VIDEO_TYPE_YOUTUBE:
															return <div>YouTube Video</div>;
														case EXERCISE_VIDEO_TYPE_VIMEO:
															return (
																<div className="aspect-video rounded-lg overflow-hidden">
																	<Vimeo video={datum.videoUrl} responsive />
																</div>
															);
														default:
															return null;
													}
												})()}
											</div>
										</section>

										{/* Details Section */}
										<section className="space-y-6">
											<h2 className="text-lg font-medium text-gray-900">
												Exercise Information
											</h2>
											<div className="bg-gray-50 rounded-lg p-4 sm:p-6 space-y-6">
												<DataDisplayRowImage
													label="Thumbnail"
													src={datum.thumbnailObjectUrl}
													alt="Thumbnail"
													className="rounded-lg"
												/>

												<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
													<DataDisplayRowText label="Name" value={datum.name} />
													<DataDisplayRowText
														label="Alternate Name"
														value={datum.alternateName}
													/>
												</div>

												<DataDisplayRowText
													label="Description"
													value={datum.description}
												/>

												<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
													<DataDisplayRowRadio
														label="Gender"
														value={datum.gender}
														opt1Value="Male"
														opt1Label="Male"
														opt2Value="Female"
														opt2Label="Female"
														opt3Value="Other"
														opt3Label="Other"
													/>
													<DataDisplayRowSelect
														label="Movement Type"
														selectedValue={datum.movementType}
														options={
															EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION
														}
													/>
												</div>

												<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
													<DataDisplayRowSelect
														label="Category"
														selectedValue={datum.category}
														options={
															EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION
														}
													/>
													<DataDisplayRowSelect
														label="Type"
														selectedValue={datum.type}
														options={EXERCISE_TYPE_WITH_EMPTY_OPTIONS}
													/>
												</div>

												<DataDisplayRowSelect
													label="Status"
													selectedValue={datum.status}
													options={EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION}
												/>

												{/* Monetization Section */}
												{datum.hasMonetization && (
													<div className="space-y-6 pt-4 border-t border-gray-200">
														<h3 className="text-md font-medium text-gray-900">
															Monetization Details
														</h3>
														<DataDisplayRowOffer
															label="Offer"
															offerID={datum.offerId}
															helpText=""
														/>

														{datum.hasTimedLock && (
															<DataDisplayRowSelect
																label="Timed Lock Duration"
																selectedValue={datum.timedLock}
																options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
															/>
														)}
													</div>
												)}
											</div>
										</section>

										{/* Action Buttons */}
										<div className="flex flex-col sm:flex-row justify-between gap-4 pt-6 border-t border-gray-200">
											<Link
												to="/admin/exercises"
												className="inline-flex items-center justify-center px-4 py-2 bg-gray-100 
                             hover:bg-gray-200 text-gray-700 rounded-lg transition-colors duration-300">
												<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
												Back to Exercises
											</Link>
											<Link
												to={`/admin/exercise/${id}/update`}
												className={`inline-flex items-center justify-center px-4 py-2 rounded-lg 
                             transition-colors duration-300 ${
																datum.type === EXERCISE_TYPE_SYSTEM
																	? "bg-gray-300 cursor-not-allowed"
																	: "bg-yellow-500 hover:bg-yellow-600 text-white"
															}`}
												disabled={datum.type === EXERCISE_TYPE_SYSTEM}>
												<FontAwesomeIcon icon={faPencil} className="mr-2" />
												Edit Exercise
											</Link>
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={selectedExerciseForDeletion !== null}
				onClose={() => setSelectedExerciseForDeletion(null)}
				header="Confirm Deletion">
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to delete this exercise? This action cannot be
						undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setSelectedExerciseForDeletion(null)}
							className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-lg transition-colors">
							Delete
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default AdminExerciseDetail;

import {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
	faCalendarPlus,
	faEye,
	faPencilAlt,
	faTrashAlt,
	faUsers,
	faBook,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {deleteVideoCollectionAPI} from "../../../API/VideoCollection";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	trainingProgramFilterShowState,
	trainingProgramFilterTemporarySearchTextState,
	trainingProgramFilterActualSearchTextState,
	trainingProgramFilterStatusState,
	trainingProgramFilterVisibilityState,
	trainingProgramFilterSortState,
	trainingProgramVisibilityForMember,
	trainingProgramVisibilityForAdmin,
	trainingProgramFilterVideoTypeState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
	TRAINING_PROGRAM_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	TRAINING_PROGRAM_WITH_EMPTY_VISBILITY_OPTIONS,
	VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import {
	deleteTrainingProgAPI,
	getTrainingProgListApi,
} from "../../../API/trainingProgram";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
	TableCell,
} from "../../Reusable/AdminList";
import Layout from "../../Menu/Layout";
import React from "react";

function AdminTrainingProgramList() {
	////
	//// Global state.
	////

	const {uid} = useParams();

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		trainingProgramFilterShowState
	); // Filtering + Searching
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		trainingProgramFilterTemporarySearchTextState
	); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useState(""); // Searching - The actual search query value to submit to the API.
	const [status, setStatus] = useRecoilState(trainingProgramFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		trainingProgramFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(trainingProgramFilterSortState);
	const [visibility, setVisibility] = useRecoilState(
		uid ? trainingProgramVisibilityForMember : trainingProgramVisibilityForAdmin
	);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [
		selectedTrainingProgramForDeletion,
		setSelectedTrainingProgramForDeletion,
	] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination

	////
	//// API.
	////

	function onTrainingProgramListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	const handleSearch = (e) => {
		e.preventDefault();
		setActualSearchText(temporarySearchText);
		fetchList(currentCursor, pageSize, temporarySearchText, status, visibility);
	};

	const handleClearFilters = () => {
		// Reset all filter states to their defaults
		setShowFilter(false);
		setTemporarySearchText("");
		setActualSearchText("");
		setStatus("");
		setVisibility("");

		// Fetch list with cleared filters
		fetchList(
			currentCursor,
			pageSize,
			"", // cleared search
			"", // cleared status
			"" // cleared visibility
		);
	};

	function onTrainingProgramListError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onTrainingProgramListDone() {
		setFetching(false);
	}

	function onTrainingProgramDeleteSuccess(response) {
		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Training program deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Fetch again an updated list.
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			status,
			videoType,
			sort
		);
	}

	function onTrainingProgramDeleteError(apiErr) {
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onTrainingProgramDeleteDone() {
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, st, vt, sbv, visibility) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", "_id"); // Sort (1 of 2)
			params.set("sort_order", -1); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		params.set("type", vt);

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status_list", st === 0 ? 1 : st);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}

		if (visibility !== undefined && visibility !== null && visibility !== 0) {
			params.set("visibility", visibility);
		}

		if (visibility !== 1) {
			if (uid !== undefined && uid !== null) {
				params.set("user_id", uid);
			}
		}

		getTrainingProgListApi(
			params,
			onTrainingProgramListSuccess,
			onTrainingProgramListError,
			onTrainingProgramListDone
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		setActualSearchText(temporarySearchText);
	};

	const onSelectTrainingProgramForDeletion = (e, datum) => {
		setSelectedTrainingProgramForDeletion(datum);
	};

	const onDeselectTrainingProgramForDeletion = (e) => {
		setSelectedTrainingProgramForDeletion("");
	};

	const onDeleteConfirmButtonClick = (e) => {
		deleteTrainingProgAPI(
			selectedTrainingProgramForDeletion.id,
			onTrainingProgramDeleteSuccess,
			onTrainingProgramDeleteError,
			onTrainingProgramDeleteDone
		);
		setSelectedTrainingProgramForDeletion("");
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				videoType,
				sort,
				visibility
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentCursor,
		pageSize,
		actualSearchText,
		status,
		videoType,
		sort,
		visibility,
	]);

	////
	//// Component rendering.
	////

	// Render functions
	const renderDesktopItem = (item, index) => (
		<tr key={item.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
			<TableCell>
				<div className="flex flex-col">
					<span className="font-medium text-gray-900">{item.name}</span>
					<span className="text-gray-500 text-sm">{item.description}</span>
				</div>
			</TableCell>
			<TableCell>
				<div className="flex flex-col">
					<span className="font-medium">{item.phases} Phases</span>
					<span className="text-gray-500">{item.durationInWeeks} Weeks</span>
				</div>
			</TableCell>
			<TableCell>
				<div className="flex items-center space-x-2">
					<span
						className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
          ${
						item.status === 1
							? "bg-green-100 text-green-800"
							: "bg-red-100 text-red-800"
					}`}>
						{item.status === 1 ? "Active" : "Inactive"}
					</span>
					<span
						className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
          ${
						item.visibility === 2
							? "bg-blue-100 text-blue-800"
							: "bg-gray-100 text-gray-800"
					}`}>
						{item.visibility === 2 ? "Public" : "Private"}
					</span>
				</div>
			</TableCell>
			<TableCell>
				<div className="text-sm text-gray-500">
					{new Date(item.createdAt).toLocaleDateString()}
				</div>
			</TableCell>
			<TableCell>
				<div className="flex items-center space-x-3">
					<Link
						to={`/admin/training-program/${item.id}`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
						View
					</Link>
					<Link
						to={`/admin/training-program/${item.id}/edit`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faPencilAlt} className="mr-2 h-4 w-4" />
						Edit
					</Link>
					<button
						onClick={() => setSelectedTrainingProgramForDeletion(item)}
						className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
						<FontAwesomeIcon icon={faTrashAlt} className="mr-2 h-4 w-4" />
						Delete
					</button>
				</div>
			</TableCell>
		</tr>
	);

	const renderMobileItem = (item) => (
		<div
			key={item.id}
			className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
			<div className="px-4 py-5 sm:px-6">
				<div className="flex flex-col gap-3">
					<div className="flex items-start justify-between">
						<div className="flex flex-col">
							<h3 className="text-lg font-medium text-gray-900">{item.name}</h3>
							<p className="mt-1 text-sm text-gray-500">{item.description}</p>
						</div>
						<div className="flex flex-col items-end gap-2">
							<span
								className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
              ${
								item.status === 1
									? "bg-green-100 text-green-800"
									: "bg-red-100 text-red-800"
							}`}>
								{item.status === 1 ? "Active" : "Inactive"}
							</span>
							<span
								className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
              ${
								item.visibility === 2
									? "bg-blue-100 text-blue-800"
									: "bg-gray-100 text-gray-800"
							}`}>
								{item.visibility === 2 ? "Public" : "Private"}
							</span>
						</div>
					</div>

					<div className="flex justify-between items-center text-sm">
						<div className="flex gap-4">
							<span className="text-gray-500">{item.phases} Phases</span>
							<span className="text-gray-500">
								{item.durationInWeeks} Weeks
							</span>
						</div>
						<span className="text-gray-500">
							{new Date(item.createdAt).toLocaleDateString()}
						</span>
					</div>

					<div className="mt-4 flex justify-end">
						<Link
							to={`/admin/training-program/${item.id}`}
							className="inline-flex items-center px-3 py-2 border border-transparent text-sm 
                     leading-4 font-medium rounded-md text-white bg-primary 
                     hover:bg-primary-dark transition-colors duration-300">
							View Details
							<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Training Programs", icon: faBook},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="px-4 sm:px-6 lg:px-8 py-6 max-w-container mx-auto">
				<ListHeader
					title="Training Programs"
					icon={faBook}
					onRefresh={() =>
						fetchList(
							currentCursor,
							pageSize,
							actualSearchText,
							status,
							visibility
						)
					}
					onToggleFilter={() => setShowFilter(!showFilter)}
					addLink="/admin/training-program/add"
					addLabel="New"
				/>

				{showFilter && (
					<ListFilter onClear={onClearFilterClick}>
						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
							<FormInputFieldWithButton
								label="Search"
								name="search"
								type="text"
								value={temporarySearchText}
								placeholder="Search by name or description"
								onChange={(e) => setTemporarySearchText(e.target.value)}
								onButtonClick={handleSearch}
								buttonIcon={faSearch}
								buttonLabel="Search"
							/>
							<FormSelectField
								label="Status"
								name="status"
								selectedValue={status}
								options={TRAINING_PROGRAM_STATUS_OPTIONS_WITH_EMPTY_OPTION}
								onChange={(e) => setStatus(parseInt(e.target.value))}
							/>
							<FormSelectField
								label="Visibility"
								name="visibility"
								selectedValue={visibility}
								options={TRAINING_PROGRAM_WITH_EMPTY_VISBILITY_OPTIONS}
								onChange={(e) => setVisibility(parseInt(e.target.value))}
							/>
						</div>
					</ListFilter>
				)}

				{isFetching ? (
					<PageLoadingContent />
				) : listData?.results?.length > 0 || previousCursors.length > 0 ? (
					<>
						{errors && <FormErrorBox errors={errors} />}

						{/* Desktop view */}
						<div className="hidden md:block">
							<DataListDesktop
								headers={[
									{label: "Title"},
									{label: "Description"},
									{label: "Status"},
									{label: "Created"},
									{label: "Actions", className: "text-right"},
								]}
								data={listData?.results || []}
								renderRow={renderDesktopItem}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData?.hasNextPage}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>

						{/* Mobile view */}
						<div className="md:hidden">
							<DataListMobile
								data={listData?.results || []}
								renderItem={renderMobileItem}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData?.hasNextPage}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>
					</>
				)
				: (
					<div className="text-center py-12 bg-gray-50 rounded-lg">
						<FontAwesomeIcon
							icon={faBook}
							className="text-4xl text-gray-400 mb-4"
						/>
						<h3 className="text-lg font-medium text-gray-900 mb-2">

							No Training Programs Available
						</h3>
						<p className="text-gray-600">
							Get started by{" "}

							<Link
								to="/admin/training-program/add"
								className="text-primary hover:text-primary-dark font-medium">
								creating your first training program
							</Link>

						</p>
					</div>
				)}
			</div>
		</Layout>
	);
}

export default AdminTrainingProgramList;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Scroll from "react-scroll";
import { postEmailVerificationAPI } from "../../API/gateway";
import FormErrorBox from "../Reusable/FormErrorBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faArrowRight, 
  faEnvelope, 
  faCheckCircle 
} from "@fortawesome/free-solid-svg-icons";

function EmailVerification() {
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const [version, setEmailVerification] = useState("");

  function onEmailVerificationSuccess(response) {
    console.log("onEmailVerificationSuccess: Starting...");
    setEmailVerification(response);
  }

  function onEmailVerificationError(apiErr) {
    console.log("onEmailVerificationError: Starting...");
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onEmailVerificationDone() {
    console.log("onEmailVerificationDone: Starting...");
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      postEmailVerificationAPI(
        searchParams.get("q"),
        onEmailVerificationSuccess,
        onEmailVerificationError,
        onEmailVerificationDone
      );
    }

    return () => (mounted = false);
  }, []);

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat" 
      style={{
        backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`
      }}>
      <div className="max-w-2xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="bg-darkShade/90 backdrop-blur-sm rounded-2xl shadow-2xl p-8">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <img
              src="/static/logo.png"
              alt="Logo"
              className="w-32 h-32 rounded-full border-4 border-main/20 hover:border-main/40 transition-colors duration-300"
            />
          </div>

          <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-6 text-center">
            <FontAwesomeIcon 
              icon={faCheckCircle} 
              className="text-green-500 text-3xl mb-4" 
            />
            <h1 className="text-2xl font-bold text-lightShade mb-4">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              Email Verified
            </h1>
            
            <FormErrorBox errors={errors} />
            
            <p className="text-lightShade/80 mb-6">
              Thank you for verifying. You may now log into your account.
            </p>

            <Link 
              to="/login"
              className="inline-flex items-center text-main hover:text-main/80 
                transition-colors duration-200 font-medium"
            >
              Go to login
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
          </div>
        </div>

        {/* Help Section */}
        <div className="mt-8 text-center text-lightShade">
          <p className="font-medium mb-2">Need help?</p>
          <div className="space-y-1">
            <a 
              href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
              className="block text-main hover:text-main/80 transition-colors duration-200"
            >
              {process.env.REACT_APP_ORGANIZATION_EMAIL}


            </a>
            <a 
              href={`tel:${process.env.REACT_APP_ORGANIZATION_PHONE}`}
              className="block text-main hover:text-main/80 transition-colors duration-200"
            >
              {process.env.REACT_APP_ORGANIZATION_PHONE}


            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
import React, {useState, useRef, useEffect, useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlay,
	faInfo,
	faBookmark,
	faShare,
	faThumbsUp,
	faClock,
	faEye,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
import Vimeo from "@u-wave/react-vimeo";
import {EXERCISE_VIDEO_FILE_TYPE_OPTIONS} from "../../Constants/FieldOptions";
import QuillRenderer from "./QuillRenderer";

export const VIDEO_LAYOUTS = {
	MINIMAL: "minimal",
	COMPACT: "compact",
	FULL: "full",
};

// Helper function to extract YouTube video ID
const getYouTubeVideoId = (url) => {
	if (!url) return null;
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);
	return match && match[2].length === 11 ? match[2] : null;
};

// Video Content Component
const VideoContent = ({
	showVideo,
	setShowVideo,
	videoType,
	videoObjectUrl,
	videoUrl,
	thumbnailUrl,
	thumbnailObjectUrl,
	name,
	onPlay,
	onEnded,
	autoplay = false,
}) => {
	const videoWrapperRef = React.useRef(null);
	const [aspectRatio] = useState("16/9");
	const [isLoading, setIsLoading] = useState(false);
	const playerRef = useRef(null);
	const youtubePlayerRef = useRef(null);
	const [vimeoPlayer, setVimeoPlayer] = useState(null);
	const [error, setError] = useState(null);

	// Function to handle YouTube API loading
	useEffect(() => {
		// Load YouTube API if not already loaded
		if (!window.YT) {
			const tag = document.createElement("script");
			tag.src = "https://www.youtube.com/iframe_api";
			const firstScriptTag = document.getElementsByTagName("script")[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
	}, []);

	const handlePlayClick = React.useCallback(() => {
		setShowVideo(true);
		if (onPlay) onPlay();
	}, [setShowVideo, onPlay]);

	const renderVideo = React.useCallback(() => {
		switch (parseInt(videoType)) {
			case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[0].value: // S3
				if (!videoObjectUrl && !videoUrl) return null;

				return (
					<video
						ref={playerRef}
						className="absolute inset-0 w-full h-full object-cover"
						controls
						playsInline
						autoPlay={autoplay}
						muted={!showVideo}
						controlsList="nodownload"
						onLoadedData={() => setIsLoading(false)}
						onError={(e) => {
							console.error("S3 video error:", e);
							setError("Failed to load video");
							setIsLoading(false);
						}}
						onEnded={() => {
							console.log("S3 video ended");
							if (onEnded) onEnded();
						}}>
						<source src={videoObjectUrl || videoUrl} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				);

			case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[1].value: // YouTube
				if (!videoUrl) return null;

				const youtubeId = getYouTubeVideoId(videoUrl);
				if (!youtubeId) return null;

				return (
					<iframe
						ref={playerRef}
						id={`youtube-${youtubeId}`}
						className="absolute inset-0 w-full h-full"
						src={`https://www.youtube.com/embed/${youtubeId}?autoplay=${
							autoplay ? 1 : 0
						}&rel=0&modestbranding=1&enablejsapi=1`}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{border: "none"}}
						onLoad={() => {
							setIsLoading(false);
							// Add YouTube API event listener
							if (window.YT) {
								new window.YT.Player(`youtube-${youtubeId}`, {
									events: {
										onStateChange: (event) => {
											// Video ended (state = 0)
											if (event.data === 0) {
												console.log("YouTube video ended");
												if (onEnded) onEnded();
											}
										},
									},
								});
							}
						}}
						onError={(e) => {
							console.error("YouTube video error:", e);
							setError("Failed to load video");
							setIsLoading(false);
						}}
					/>
				);

			case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[2].value: // Vimeo
				if (!videoUrl) return null;

				const vimeoId = videoUrl.split("/").pop();
				return (
					<div className="relative w-full h-full">
						<Vimeo
							video={vimeoId}
							responsive={true}
							autopause={false}
							autoplay={autoplay}
							controls={true}
							playsInline={true}
							muted={false}
							background={false}
							dnt={true}
							pip={false}
							onError={(e) => {
								console.error("S3 video error:", e);
								setError("Failed to load video");
								setIsLoading(false);
							}}
							onReady={(player) => {
								setVimeoPlayer(player);
								player.on("ended", () => {
									console.log("Vimeo ended via player API");
									if (onEnded) onEnded();
								});
							}}
							onLoaded={(event) => {
								console.log("Vimeo video loaded", event);
								setIsLoading(false);
							}}
							onPlay={({duration, seconds, percent}) => {
								console.log("Play initiated");
								if (onPlay) onPlay();
							}}
							onPlaying={({duration, seconds, percent}) => {
								console.log("Playback started");
							}}
							onPause={({duration, seconds, percent}) => {
								console.log("Playback paused");
							}}
							onEnded={({duration, seconds, percent}) => {
								console.log("Playback ended");
								if (onEnded) onEnded();
							}}
							onTimeUpdate={({duration, seconds, percent}) => {
								console.log("Time updated", seconds);
							}}
							onProgress={({duration, seconds, percent}) => {
								console.log("Buffered", percent);
							}}
							style={{
								width: "100%",
								height: "100%",
							}}
						/>
					</div>
				);

			default:
				return null;
		}
	}, [videoType, videoUrl, videoObjectUrl, showVideo, autoplay, onEnded]);

	// Add this useEffect to handle Vimeo player initialization and cleanup
	useEffect(() => {
		if (
			parseInt(videoType) === EXERCISE_VIDEO_FILE_TYPE_OPTIONS[2].value &&
			playerRef.current
		) {
			const player = playerRef.current;

			// Store the player instance
			playerRef.current = player;

			// Add additional event listeners if needed
			player?.on("ended", () => {
				console.log("Vimeo video ended via player event");
				if (onEnded) onEnded();
			});

			// Cleanup
			return () => {
				if (playerRef.current) {
					playerRef.current = null;
				}
			};
		}
	}, [videoType, onEnded]);

	// Add this function to manually check video progress
	const checkVimeoProgress = useCallback(() => {
		if (playerRef.current) {
			playerRef.current.getCurrentTime().then((currentTime) => {
				playerRef.current.getDuration().then((duration) => {
					if (currentTime >= duration - 0.5) {
						console.log("Vimeo video completed via progress check");
						if (onEnded) onEnded();
					}
				});
			});
		}
	}, [onEnded]);

	// Add this effect to periodically check video progress
	useEffect(() => {
		let progressInterval;

		if (
			parseInt(videoType) === EXERCISE_VIDEO_FILE_TYPE_OPTIONS[2].value &&
			showVideo
		) {
			progressInterval = setInterval(checkVimeoProgress, 1000);
		}

		return () => {
			if (progressInterval) {
				clearInterval(progressInterval);
			}
		};
	}, [videoType, showVideo, checkVimeoProgress]);

	// Auto-show video when autoplay is true
	React.useEffect(() => {
		if (autoplay) {
			setShowVideo(true);
		}
	}, [autoplay]);

	// Initialize YouTube player when component mounts or video changes
	useEffect(() => {
		if (
			parseInt(videoType) === EXERCISE_VIDEO_FILE_TYPE_OPTIONS[1].value &&
			videoUrl
		) {
			const youtubeId = getYouTubeVideoId(videoUrl);

			if (youtubeId && window.YT && window.YT.Player) {
				if (youtubePlayerRef.current) {
					youtubePlayerRef.current.destroy();
				}

				youtubePlayerRef.current = new window.YT.Player(
					`youtube-${youtubeId}`,
					{
						videoId: youtubeId,
						playerVars: {
							autoplay: autoplay ? 1 : 0,
							controls: 1,
							rel: 0,
							modestbranding: 1,
							playsinline: 1,
						},
						events: {
							onReady: (event) => {
								console.log("YouTube player ready");
								setIsLoading(false);
								if (autoplay) {
									event.target.playVideo();
								}
							},
							onStateChange: (event) => {
								// YT.PlayerState.ENDED = 0
								if (event.data === 0) {
									console.log("YouTube video ended");
									if (onEnded) onEnded();
								}
							},
							onError: (error) => {
								console.error("YouTube player error:", error);
							},
						},
					}
				);
			}
		}

		// Cleanup
		return () => {
			if (youtubePlayerRef.current) {
				youtubePlayerRef.current.destroy();
			}
		};
	}, [videoType, videoUrl, autoplay, onEnded]);

	return (
		<div
			ref={videoWrapperRef}
			className="relative w-full group bg-black overflow-hidden rounded-3xl transition-all duration-500 hover:shadow-primary/20"
			style={{aspectRatio}}>
			{error && (
				<div className="absolute inset-0 flex items-center justify-center bg-red-50 z-20">
					<div className="text-center p-6">
						<div className="w-12 h-12 bg-red-100 rounded-full mx-auto flex items-center justify-center mb-4">
							<FontAwesomeIcon
								icon={faWarning}
								className="text-red-500 text-xl"
							/>
						</div>
						<p className="text-red-600 font-medium">{error}</p>
						<button
							onClick={() => {
								setError(null);
								setIsLoading(true);
								renderVideo();
							}}
							className="mt-4 px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors">
							Retry
						</button>
					</div>
				</div>
			)}

			{showVideo ? (
				<div className="absolute inset-0 w-full h-full">
					<div className="absolute inset-0 bg-gradient-to-r from-primary/10 via-transparent to-primary/5" />
					{renderVideo()}
					{isLoading && (
						<div className="absolute inset-0 flex items-center justify-center backdrop-blur-sm bg-black/30">
							<div className="relative">
								<div className="animate-spin rounded-full h-16 w-16 border-4 border-primary border-t-transparent" />
								<div className="absolute inset-0 flex items-center justify-center">
									<div className="h-4 w-4 bg-primary rounded-full" />
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				<div
					className="absolute inset-0 cursor-pointer group overflow-hidden"
					onClick={handlePlayClick}>
					<img
						className="w-full h-full object-cover transition-all duration-700 group-hover:scale-105"
						src={thumbnailUrl || thumbnailObjectUrl}
						alt={name}
						loading="lazy"
					/>
					<div
						className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30 to-transparent 
                      flex items-center justify-center transition-all duration-500">
						<div className="relative">
							<div className="absolute inset-0 animate-ping bg-white/30 rounded-full" />
							<div
								className="w-20 h-20 sm:w-24 sm:h-24 bg-white/90 backdrop-blur-xl rounded-full 
                         flex items-center justify-center transform transition-all duration-500 
                         group-hover:scale-110 shadow-2xl">
								<FontAwesomeIcon
									icon={faPlay}
									className="text-3xl sm:text-4xl text-primary ml-2 sm:ml-3 
                         transition-transform duration-500 group-hover:scale-110"
								/>
							</div>
						</div>
					</div>
					<div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black via-black/70 to-transparent">
						<h2 className="text-white text-xl font-bold truncate">{name}</h2>
						<div className="flex items-center gap-4 mt-2 text-white/70">
							<span className="flex items-center gap-2">
								<FontAwesomeIcon icon={faEye} className="text-sm" />
								<span className="text-sm">Preview</span>
							</span>
							<span className="flex items-center gap-2">
								<FontAwesomeIcon icon={faClock} className="text-sm" />
								<span className="text-sm">Click to play</span>
							</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

// Enhanced ActionButtons Component
const ActionButtons = ({
	isLiked,
	setIsLiked,
	isSaved,
	setIsSaved,
	onLike,
	onSave,
	onShare,
	stats,
}) => (
	<div className="flex items-center gap-6 mt-6 text-sm">
		<button
			onClick={() => {
				setIsLiked(!isLiked);
				if (onLike) onLike();
			}}
			className={`group flex items-center gap-3 px-4 py-2 rounded-xl transition-all duration-300
                  ${
										isLiked
											? "bg-primary/10 text-primary"
											: "text-gray-600 hover:bg-gray-100"
									}`}>
			<FontAwesomeIcon
				icon={faThumbsUp}
				className={`text-lg transform transition-transform duration-300 
                      ${isLiked ? "scale-110" : "group-hover:scale-110"}`}
			/>
			<span className="font-medium">{stats?.likes || 0}</span>
		</button>

		<button
			onClick={() => {
				setIsSaved(!isSaved);
				if (onSave) onSave();
			}}
			className={`group flex items-center gap-3 px-4 py-2 rounded-xl transition-all duration-300
                  ${
										isSaved
											? "bg-primary/10 text-primary"
											: "text-gray-600 hover:bg-gray-100"
									}`}>
			<FontAwesomeIcon
				icon={faBookmark}
				className={`text-lg transform transition-transform duration-300 
                      ${isSaved ? "scale-110" : "group-hover:scale-110"}`}
			/>
			<span className="font-medium">{stats?.saves || 0}</span>
		</button>

		<button
			onClick={onShare}
			className="group flex items-center gap-3 px-4 py-2 rounded-xl text-gray-600 
                  hover:bg-gray-100 transition-all duration-300">
			<FontAwesomeIcon
				icon={faShare}
				className="text-lg transform transition-transform duration-300 
                      group-hover:scale-110"
			/>
			<span className="font-medium">{stats?.shares || 0}</span>
		</button>
	</div>
);

// Description Component
const Description = ({description, className}) => {
	if (!description) return null;

	if (typeof description === "string") {
		return (
			<QuillRenderer
				content={description}
				withBorder={false}
				className={className}
			/>
		);
	}
	return <div className={className}>{description}</div>;
};

// Enhanced MetadataSidebar Component
const MetadataSidebar = ({metadata}) => (
	<div className="bg-gray-50 rounded-2xl p-6 border border-gray-100 shadow-sm">
		<h3 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
			<div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center mr-3">
				<FontAwesomeIcon icon={faInfo} className="text-primary" />
			</div>
			Video Details
		</h3>
		<div className="space-y-5">
			{metadata?.map((item, index) => (
				<div
					key={index}
					className="flex items-start gap-4 p-4 rounded-xl bg-white border border-gray-100 
                          transition-all duration-300 hover:shadow-md">
					<div className="w-10 h-10 rounded-xl bg-gray-50 flex items-center justify-center">
						<FontAwesomeIcon icon={item.icon} className="text-gray-400" />
					</div>
					<div>
						<h4 className="text-sm font-medium text-gray-900">{item.label}</h4>
						<p className="text-sm text-gray-600 mt-1">{item.value}</p>
					</div>
				</div>
			))}
		</div>
	</div>
);

// Main VideoPlayer Component
const VideoPlayer = ({
	videoType = EXERCISE_VIDEO_FILE_TYPE_OPTIONS[0].value,
	videoObjectUrl,
	videoUrl,
	thumbnailUrl,
	thumbnailObjectUrl,
	name,
	description,
	metadata,
	layout = VIDEO_LAYOUTS.FULL,
	showActions = true,
	showDescription = true,
	onPlay,
	onEnded,
	autoplay = false,
	onLike,
	onSave,
	onShare,
	stats,
}) => {
	const [showVideo, setShowVideo] = useState(false);
	const [isLiked, setIsLiked] = useState(false);
	const [isSaved, setIsSaved] = useState(false);

	const layouts = {
		[VIDEO_LAYOUTS.MINIMAL]: (
			<div className="max-w-4xl mx-auto">
				<VideoContent
					showVideo={showVideo}
					setShowVideo={setShowVideo}
					videoType={videoType}
					videoObjectUrl={videoObjectUrl}
					videoUrl={videoUrl}
					thumbnailUrl={thumbnailUrl}
					thumbnailObjectUrl={thumbnailObjectUrl}
					name={name}
					onPlay={onPlay}
					onEnded={onEnded}
					autoplay={autoplay}
				/>
				{showDescription && description && (
					<div className="mt-6">
						<Description description={description} />
					</div>
				)}
			</div>
		),

		[VIDEO_LAYOUTS.COMPACT]: (
			<div className="max-w-4xl mx-auto">
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
					<div className="lg:col-span-2">
						<VideoContent
							showVideo={showVideo}
							setShowVideo={setShowVideo}
							videoType={videoType}
							videoObjectUrl={videoObjectUrl}
							videoUrl={videoUrl}
							thumbnailUrl={thumbnailUrl}
							thumbnailObjectUrl={thumbnailObjectUrl}
							name={name}
							onPlay={onPlay}
							onEnded={onEnded}
							autoplay={autoplay}
						/>
						<div className="px-4 sm:px-0">
							<h1 className="mt-6 text-2xl font-bold text-gray-900">{name}</h1>
							{showActions && (
								<ActionButtons
									isLiked={isLiked}
									setIsLiked={setIsLiked}
									isSaved={isSaved}
									setIsSaved={setIsSaved}
									onLike={onLike}
									onSave={onSave}
									onShare={onShare}
									stats={stats}
								/>
							)}
							{showDescription && <Description description={description} />}
						</div>
					</div>
					{metadata && (
						<div className="lg:col-span-1">
							<MetadataSidebar metadata={metadata} />
						</div>
					)}
				</div>
			</div>
		),

		[VIDEO_LAYOUTS.FULL]: (
			<div className="max-w-7xl mx-auto">
				<VideoContent
					showVideo={showVideo}
					setShowVideo={setShowVideo}
					videoType={videoType}
					videoObjectUrl={videoObjectUrl}
					videoUrl={videoUrl}
					thumbnailUrl={thumbnailUrl}
					thumbnailObjectUrl={thumbnailObjectUrl}
					name={name}
					onPlay={onPlay}
					onEnded={onEnded}
					autoplay={autoplay}
				/>
				{showActions && (
					<ActionButtons
						isLiked={isLiked}
						setIsLiked={setIsLiked}
						isSaved={isSaved}
						setIsSaved={setIsSaved}
						onLike={onLike}
						onSave={onSave}
						onShare={onShare}
						stats={stats}
					/>
				)}
				{showDescription && <Description description={description} />}
			</div>
		),
	};

	return layouts[layout] || layouts[VIDEO_LAYOUTS.FULL];
};

export {VideoPlayer};
export default VideoPlayer;

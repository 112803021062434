import {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faUser,
	faGauge,
	faBolt,
	faMedal,
	faSearch,
	faCheck,
	faTimes,
	faSpinner,
	faEye,
	faInfoCircle,
	faClock,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	fitnessChallengeDatumState,
	currentUserState,
} from "../../../AppState";
import {
	deletefitnessChallengeAPI,
	getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
	BADGE_CRITERIA_OPTIONS,
	BADGE_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import {putfitnessChallengeAssignBadgeAPI} from "../../../API/AssignBadges";
import ToggleSwitch from "../../Reusable/ToggleSwitch";
import Layout from "../../Menu/Layout";
import {icon} from "@fortawesome/fontawesome-svg-core";
import QuillRenderer from "../../Reusable/QuillRenderer";

function AdminFitnessChallengeAssignBadges() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users
	const [isToggled, setIsToggled] = useState(false);

	const queryClient = useQueryClient(); // Access the query client

	// Breadcrumb items
	const breadcrumbItems = [
		{
			label: "Dashboard",
			link: "/admin/dashboard",
			icon: faGauge,
		},
		{
			label: "Fitness Challenge",
			link: `/admin/fitness-challenge/${id}`,
			icon: faBolt,
		},
		{
			label: "Detail",
			icon: faEye,
		},
	];

	////
	//// API.
	////

	// --- Detail --- //

	function onFitnessChallengeDetailSuccess(response) {
		setDatum(response);
		if (response.id) {
			// Filters users with hasEarnedBadge true
			const selectedUserIds =
				response?.users
					?.filter((user) => user.hasEarnedBadge)
					.map((user) => user.id) || [];
			setSelectedUsers(selectedUserIds);
		}
	}

	function onFitnessChallengeDetailError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessChallengeDetailDone() {
		setFetching(false);
	}

	// --- Badge Assignment --- //

	const onBadgeAssignmentSuccess = (response, userId, isAssigned) => {
		Scroll.animateScroll.scrollToTop();
		if (response.success) {
			setTopAlertMessage(
				`Badge has been ${isAssigned ? "assigned" : "unassigned"} successfully.`
			);
			setTopAlertStatus("success");

			setIsToggled(!isToggled);

			queryClient.invalidateQueries(["accountDetail"]);

			// Update the local state to reflect the new badge status
			setDatum((prevDatum) => ({
				...prevDatum,
				users: prevDatum.users.map((user) =>
					user.id === userId ? {...user, completed: isAssigned} : user
				),
			}));
		}
	};

	const onBadgeAssignmentError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
		setTopAlertMessage("Error updating badge status.");
		setTopAlertStatus("danger");
	};

	const onBadgeAssignmentDone = () => {
		// Stop loading indicators, clean up state
		setFetching(false);
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	};

	////
	//// Event handling.
	////
	const handleToggleBadge = (userId, isAssigned) => {
		setFetching(true); // Start loading indicator

		// Update the selectedUsers array based on the toggle
		let updatedSelectedUsers = [];
		if (isAssigned) {
			// Add user to selected users if assigned
			updatedSelectedUsers = [...selectedUsers, userId];
		} else {
			// Remove user from selected users if unassigned
			updatedSelectedUsers = selectedUsers.filter((id) => id !== userId);
		}

		// Set the new selected users list in the state
		setSelectedUsers(updatedSelectedUsers);

		if (datum) {
			const requestData = {
				id: id,
				badge_id: datum.badgeId, // Use the current badge ID
				users: updatedSelectedUsers, // Send the updated list of selected users
			};

			putfitnessChallengeAssignBadgeAPI(
				requestData,
				onBadgeAssignmentSuccess,
				onBadgeAssignmentError,
				onBadgeAssignmentDone
			);
		}
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getfitnessChallengeDetailAPI(
				id,
				onFitnessChallengeDetailSuccess,
				onFitnessChallengeDetailError,
				onFitnessChallengeDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	// Trophy Toggle Component
	const TrophyToggle = ({isEnabled, onToggle, disabled}) => (
		<button
			onClick={onToggle}
			disabled={disabled}
			className={`
        relative 
        inline-flex 
        items-center 
        w-10 xs:w-11 sm:w-12 md:w-14 lg:w-12
        h-5 xs:h-5 sm:h-6 md:h-7 lg:h-6
        rounded-full 
        transition-colors duration-300
        ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
        ${isEnabled ? "bg-primary/20" : "bg-gray-200"}
    `}>
			{/* Sliding Trophy Icon */}
			<div
				className={`
            absolute
            w-4 xs:w-4 sm:w-5 md:w-6 lg:w-5
            h-4 xs:h-4 sm:h-5 md:h-6 lg:h-5
            flex items-center justify-center 
            rounded-full 
            shadow-sm
            transition-all duration-300 ease-in-out
            transform
            ${
							isEnabled
								? "translate-x-6 xs:translate-x-7 sm:translate-x-7 md:translate-x-8 lg:translate-x-7 bg-primary text-white"
								: "translate-x-0 bg-gray-400 text-gray-100"
						}
            ${disabled ? "opacity-50 cursor-not-allowed" : "hover:shadow-md"}
        `}>
				<FontAwesomeIcon icon={faMedal} className="text-xs" />
			</div>
		</button>
	);

	const DetailHeader = () => (
		<div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
			<h1 className="text-2xl sm:text-3xl font-bold text-textDark flex items-center">
				<FontAwesomeIcon className="mr-2" icon={faMedal} />
				Assign Badges
			</h1>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-8">
				<div className="mx-auto px-4 sm:px-6 lg:px-8">
					<DetailHeader />
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex space-x-8">
							<Link
								to={`/admin/fitness-challenge/${id}`}
								className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium">
								Detail
							</Link>
							<Link
								to={`/admin/fitness-challenge/${id}/leaderboard`}
								className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium">
								Leaderboard
							</Link>
							{datum.criteria === 2 && (
								<Link className="border-primary text-primary border-b-2 py-4 px-1 text-md font-medium">
									Assign Badges
								</Link>
							)}
						</nav>
					</div>
					{/* Challenge Overview Card */}
					<div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100 mb-6">
						<div className="bg-gradient-x from-primary/5 to-primary/10 p-6">
							<div className="grid lg:grid-cols-2 gap-6">
								{/* Challenge Info */}
								<div className="flex flex-col h-[420px]">
									<div className="flex items-center space-x-2">
										<span className="px-3 py-1 rounded-full text-xs font-medium bg-primary/20 text-primary">
											{datum.durationInWeeks} Week Challenge
										</span>
										<span className="px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
											Active
										</span>
									</div>
									<div className="flex-1 custom-scrollbar overflow-y-auto">
										<QuillRenderer content={datum.description} withBorder={false} />
									</div>
								</div>

								{/* Badge Preview */}
								{datum.badgeDetails && (
									<div className="bg-white rounded-xl p-4 shadow-sm border border-gray-200 flex flex-col h-[420px]">
										<div className="flex items-center space-x-4">
											<div className="relative group">
												<div className="w-20 h-20 rounded-lg overflow-hidden">
													<img
														src={
															datum.badgeDetails.thumbnailUrl ||
															datum.badgeDetails.thumbnailObjectUrl
														}
														alt={datum.badgeDetails.name}
														className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
													/>
												</div>
											</div>
											<div className="flex-1">
												<h3 className="text-lg font-semibold text-gray-900 mb-1">
													{datum.badgeDetails.name} Badge
												</h3>
												<p className="text-sm text-gray-500 mb-2">
													{datum.badgeDetails.description}
												</p>
												<div className="flex items-center text-xs text-gray-400">
													<FontAwesomeIcon icon={faUser} className="mr-1" />
													Created by {datum.badgeDetails.createdByUserName}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Participants Section */}
					<div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100">
						<div className="p-4">
							{/* Info Banner */}
							<div className="mb-6 bg-blue-50 rounded-xl p-4 border border-blue-100">
								<div className="flex items-start space-x-3">
									<div className="p-2 bg-blue-100 rounded-lg shrink-0">
										<FontAwesomeIcon
											icon={faInfoCircle}
											className="text-blue-600"
										/>
									</div>
									<div>
										<h4 className="text-blue-900 font-medium mb-1">
											Badge Assignment Rules
										</h4>
										<p className="text-blue-700 text-sm">
											Participants must complete the challenge before they can
											be awarded the badge. Once awarded, the badge will appear
											in their profile.
										</p>
									</div>
								</div>
							</div>

							{/* Stats Cards */}
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
								<div className="bg-green-50 rounded-xl p-4">
									<div className="flex items-center space-x-3">
										<div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center">
											<FontAwesomeIcon
												icon={faCheck}
												className="text-green-600"
											/>
										</div>
										<div>
											<p className="text-2xl font-bold text-green-700">
												{datum.users?.filter((u) => u.completed).length || 0}
											</p>
											<p className="text-sm text-green-600">Completed</p>
										</div>
									</div>
								</div>

								<div className="bg-yellow-50 rounded-xl p-4">
									<div className="flex items-center space-x-3">
										<div className="w-10 h-10 rounded-full bg-yellow-100 flex items-center justify-center">
											<FontAwesomeIcon
												icon={faClock}
												className="text-yellow-600"
											/>
										</div>
										<div>
											<p className="text-2xl font-bold text-yellow-700">
												{datum.users?.filter((u) => !u.completed).length || 0}
											</p>
											<p className="text-sm text-yellow-600">In Progress</p>
										</div>
									</div>
								</div>

								<div className="bg-primary/5 rounded-xl p-4">
									<div className="flex items-center space-x-3">
										<div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
											<FontAwesomeIcon
												icon={faMedal}
												className="text-primary"
											/>
										</div>
										<div>
											<p className="text-2xl font-bold text-primary">
												{selectedUsers.length}
											</p>
											<p className="text-sm text-primary/80">Badges Awarded</p>
										</div>
									</div>
								</div>

								{/* <div className="relative w-full">
        <input
          type="text"
          placeholder="Search participants..."
          className="w-full h-full pl-12 pr-4 py-4 border border-gray-200 rounded-xl 
                   focus:ring-2 focus:ring-primary/20 focus:border-primary 
                   transition-all duration-300 bg-gray-50/50"
        />
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
        />
      </div> */}
							</div>

							{/* Participants Grid */}
							<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
								{datum?.users?.length > 0 ? (
									datum.users.map((user) => (
										<div
											key={user.id}
											className={`relative bg-white rounded-xl border group
            ${
							selectedUsers.includes(user.id)
								? "border-primary shadow-md ring-1 ring-primary/20"
								: "border-gray-200"
						} 
            hover:shadow-lg hover:border-primary/30 
            transition-all duration-300`}>
											<div className="p-5">
												{/* User Info */}
												<div className="flex items-center justify-between mb-4">
													<div className="flex items-center space-x-4">
														<div className="relative">
															<div
																className="w-14 h-14 rounded-xl bg-gradient-to-br from-primary/20 to-primary/5 
                               flex items-center justify-center transform transition-transform duration-300 
                               group-hover:scale-105">
																<span className="text-xl font-semibold text-primary">
																	{user.name.charAt(0)}
																</span>
															</div>
															{user.completed && (
																<div
																	className="absolute -bottom-1 -right-1 w-6 h-6 bg-green-500 
                                  rounded-full border-2 border-white shadow-sm 
                                  flex items-center justify-center">
																	<FontAwesomeIcon
																		icon={faCheck}
																		className="text-xs text-white"
																	/>
																</div>
															)}
														</div>
														<div>
															<h3 className="font-semibold text-gray-900 mb-1">
																{user.name}
															</h3>
															<div className="flex items-center space-x-2">
																<span
																	className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${
												user.completed
													? "bg-green-100 text-green-800"
													: "bg-yellow-100 text-yellow-800"
											}`}>
																	<span
																		className={`w-1.5 h-1.5 rounded-full mr-1.5
                        ${user.completed ? "bg-green-600" : "bg-yellow-600"}`}
																	/>
																	{user.completed ? "Completed" : "In Progress"}
																</span>
															</div>
														</div>
													</div>

													{/* Trophy Toggle with Tooltip */}
													<div className="relative group">
														<TrophyToggle
															isEnabled={selectedUsers.includes(user.id)}
															onToggle={() =>
																handleToggleBadge(
																	user.id,
																	!selectedUsers.includes(user.id)
																)
															}
															disabled={!user.completed}
														/>
														{!user.completed && (
															<div
																className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 
                                opacity-0 group-hover:opacity-100 transition-opacity duration-200">
																<div className="bg-gray-900 text-white text-xs rounded-lg py-2 px-3">
																	Complete challenge to enable badge assignment
																	<div
																		className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1 
                                  border-4 border-transparent border-t-gray-900"></div>
																</div>
															</div>
														)}
													</div>
												</div>

												{/* Badge Status with Progress */}
												<div className="mt-4 pt-4 border-t border-gray-100">
													<div className="flex items-center justify-between mb-2">
														<div className="flex items-center space-x-3">
															<div className="relative w-8 h-8 rounded-lg overflow-hidden shadow-sm">
																<img
																	src={
																		datum.badgeDetails?.thumbnailUrl ||
																		datum.badgeDetails?.thumbnailObjectUrl
																	}
																	alt={datum.badgeDetails?.name}
																	className="w-full h-full object-cover"
																/>
															</div>
															<span className="text-sm font-medium text-gray-600">
																{datum.badgeDetails?.name}
															</span>
														</div>
														<span
															className={`text-xs font-medium px-2 py-1 rounded-md
                  ${
										selectedUsers.includes(user.id)
											? "bg-primary/10 text-primary"
											: "bg-gray-100 text-gray-600"
									}`}>
															{selectedUsers.includes(user.id)
																? "Badge Awarded"
																: "Not Awarded"}
														</span>
													</div>

													{/* {!user.completed && (
                <div className="mt-2">
                  <div className="flex items-center justify-between text-xs text-gray-500 mb-1">
                    <span>Challenge Progress</span>
                    <span>{user.progress || 0}%</span>
                  </div>
                  <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-primary/30 rounded-full transition-all duration-500"
                      style={{ width: `${user.progress || 0}%` }}
                    />
                  </div>
                </div>
              )} */}
												</div>
											</div>

											{/* Loading Overlay */}
											{isFetching && selectedUsers.includes(user.id) && (
												<div
													className="absolute inset-0 bg-white/60 backdrop-blur-sm rounded-xl 
                          flex items-center justify-center">
													<div
														className="flex items-center space-x-3 px-4 py-2 bg-white/90 
                            rounded-lg shadow-sm ring-1 ring-gray-100">
														<FontAwesomeIcon
															icon={faSpinner}
															className="text-primary animate-spin"
														/>
														<span className="text-sm font-medium text-gray-700">
															Updating badge status...
														</span>
													</div>
												</div>
											)}
										</div>
									))
								) : (
									<div className="col-span-full">
										<div className="bg-white rounded-xl border border-gray-200 p-8 text-center">
											<div className="mx-auto">
												<div className="w-16 h-16 bg-primary/5 rounded-xl flex items-center justify-center mx-auto mb-4">
													<FontAwesomeIcon
														icon={faUserPlus}
														className="text-primary text-2xl"
													/>
												</div>
												<h3 className="text-lg font-semibold text-gray-900 mb-2">
													No Participants Yet
												</h3>
												<p className="text-gray-600 mb-6">
													This challenge doesn't have any participants yet.
													Participants need to join the challenge before badges
													can be assigned.
												</p>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminFitnessChallengeAssignBadges;

import React from "react";
import ExerciseDisplay from "./ExerciseDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCalendarWeek, 
  faDumbbell, 
  faCircleInfo 
} from "@fortawesome/free-solid-svg-icons";

function FitnessPlanDisplay({ weeklyFitnessPlans }) {
  return (
    <div className="space-y-4">

      {/* Weekly Plans */}
      <div className="space-y-6">
        {weeklyFitnessPlans.map((weeklyPlan, index) => (
          <>

            {/* Daily Plans */}
            <div className="divide-y divide-gray-100">
              {weeklyPlan.dailyPlans?.map((dailyPlan, dailyIndex) => (
                <div 
                  key={dailyIndex} 
                  className="transition-colors duration-300 
                           hover:bg-gray-50"
                >

                  {/* Exercise Details */}
                  {dailyPlan.planDetails && dailyPlan.planDetails.length > 0 && (
                    <div className="animate-fadeIn">
                      <ExerciseDisplay exercises={dailyPlan.planDetails} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default FitnessPlanDisplay;
import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faArrowLeft,
    faGauge,
    faTrophy,
    faEye,
    faIdCard,
	faPencil,
	faTrash,
	faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {
    getFitnessPlanDetailAPI,
    deleteFitnessPlanAPI,
} from "../../../API/FitnessPlan";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {currentUserState, topAlertMessageState, topAlertStatusState} from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowRadio from "../../Reusable/DataDisplayRowRadio";
import DataDisplayRowMultiSelectStatic from "../../Reusable/DataDisplayRowMultiSelectStatic";
import DataDisplayRowSelectStatic from "../../Reusable/DataDisplayRowSelectStatic";
import {
    HOME_GYM_EQUIPMENT_MAP,
    PHYSICAL_ACTIVITY_MAP,
    DAYS_PER_WEEK_MAP,
    TIME_PER_DAY_MAP,
    MAX_WEEK_MAP,
    FITNESS_GOAL_MAP,
    WORKOUT_PREFERENCE_MAP,
} from "../../../Constants/FieldOptions";
import {
    FITNESS_GOAL_STATUS_QUEUED,
    FITNESS_GOAL_STATUS_ACTIVE,
    GENDER_OTHER,
    GENDER_MALE,
    GENDER_FEMALE,
} from "../../../Constants/App";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function MemberFitnessPlanSubmissionForm() {
    const {id} = useParams();
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [datum, setDatum] = useState({});
    const [selectedFitnessPlanForDeletion, setSelectedFitnessPlanForDeletion] = useState(null);

    const onDeleteConfirmButtonClick = () => {
        deleteFitnessPlanAPI(
            selectedFitnessPlanForDeletion.id,
            onFitnessPlanDeleteSuccess,
            onFitnessPlanDeleteError,
            onFitnessPlanDeleteDone
        );
        setSelectedFitnessPlanForDeletion(null);
    };

    function onFitnessPlanDetailSuccess(response) {
        setDatum(response);

        console.log(response);
    }

    function onFitnessPlanDetailError(apiErr) {
        setErrors(apiErr);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onFitnessPlanDetailDone() {
        setFetching(false);
    }

	function onFitnessPlanDeleteSuccess(response) {
        setTopAlertStatus("success");
        setTopAlertMessage("Fitness plan deleted");
        setTimeout(() => {
            setTopAlertMessage("");
        }, 2000);
        setForceURL("/fitness-plans");
    }

    function onFitnessPlanDeleteError(apiErr) {
        setErrors(apiErr);
        setTopAlertStatus("danger");
        setTopAlertMessage("Failed deleting");
        setTimeout(() => {
            setTopAlertMessage("");
        }, 2000);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onFitnessPlanDeleteDone() {
        setFetching(false);
    }

    const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Plans",
			icon: faLeaf,
			link: "/fitness-plans",
		},
		{
			label: "Detail",
			icon: faEye,
		},
	]

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            Scroll.animateScroll.scrollToTop();
            setFetching(true);
            getFitnessPlanDetailAPI(
                id,
                onFitnessPlanDetailSuccess,
                onFitnessPlanDetailError,
                onFitnessPlanDetailDone
            );
            console.log("fetching");
        }
        return () => {
            mounted = false;
        };
    }, []);

	const FitnessPlanHeader = ({ datum, onDeleteClick }) => (
		<div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
		  <h1 className="text-2xl sm:text-3xl font-bold text-primary flex items-center">
			<FontAwesomeIcon className="mr-2" icon={faTrophy} />
			Fitness Plan
		  </h1>
		  {datum?.status === FITNESS_GOAL_STATUS_ACTIVE && (
			<div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
			  <Link
				to={`/fitness-plan/${datum.id}/update`}
				className="w-full sm:w-auto inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200"
			  >
				<FontAwesomeIcon className="mr-2" icon={faPencil} />
				Edit & Re-request
			  </Link>
			  <button
				onClick={onDeleteClick}
				className="w-full sm:w-auto inline-flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200"
			  >
				<FontAwesomeIcon className="mr-2" icon={faTrash} />
				Delete
			  </button>
			</div>
		  )}
		</div>
	  );

    if (forceURL !== "") {
        return <Navigate to={forceURL} />;
    }

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <Modal
                isOpen={selectedFitnessPlanForDeletion !== null}
                onClose={() => setSelectedFitnessPlanForDeletion(null)}
                header="Are you sure?"
            >
                <div className="space-y-4">
                    <p className="text-gray-700">
                        You are about to delete this fitness plan and all the data
                        associated with it. This action cannot be undone. Are you sure
                        you would like to continue?
                    </p>
                    
                    <div className="flex justify-end space-x-3">
                        <button
                            onClick={() => setSelectedFitnessPlanForDeletion(null)}
                            className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onDeleteConfirmButtonClick}
                            className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>

			<div className="bg-white rounded-lg shadow-sm p-4">
                {datum && (
						<FitnessPlanHeader 
							datum={datum} 
							onDeleteClick={() => setSelectedFitnessPlanForDeletion(datum)} 
						/>
                )}

                <FormErrorBox errors={errors} />

                {isFetching ? (
                    <PageLoadingContent displayMessage="Loading your fitness plan..." />
                ) : (
                    datum && (
                        <div key={datum.id}>
                            {datum.status === FITNESS_GOAL_STATUS_QUEUED && (
                                <div className="bg-gray-50 p-8 rounded-lg mb-6">
                                    <h2 className="text-xl font-semibold mb-2 flex items-center">
                                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                                        Fitness Plan Submitted
                                    </h2>
                                    <p className="text-gray-600">
                                        You have successfully submitted this fitness plan to our team. 
                                        The estimated time until our team completes your fitness plan 
                                        will take about <b>1 or 2 days</b>. Please check back later.
                                    </p>
                                </div>
                            )}

                            {datum.status === FITNESS_GOAL_STATUS_ACTIVE && (
                                <>
                                    <div className="border-b border-gray-200 mb-6">
                                        <nav className="-mb-px flex space-x-8">
                                            <Link
                                                to={`/fitness-plan/${datum.id}`}
                                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium"
                                            >
                                                Detail
                                            </Link>
                                            <Link
                                                to="#"
                                                className="border-primary text-primary border-b-2 whitespace-nowrap py-4 px-1 font-medium"
                                                aria-current="page"
                                            >
                                                Submission Form
                                            </Link>
                                        </nav>
                                    </div>

                                    {/* Content sections */}
                                    <div className="space-y-8">
                                        {/* Meta Section */}
                                        <section>
                                            <h3 className="text-lg font-medium mb-4">Meta</h3>
                                            <div className="border-t border-gray-200 pt-4">
                                                <DataDisplayRowText label="Name" value={datum.name} />
                                            </div>
                                        </section>
										                                        {/* Equipment Access Section */}
																				<section>
                                            <h3 className="text-lg font-medium mb-4 flex items-center">
                                                <FontAwesomeIcon icon={faIdCard} className="mr-2" />
                                                Equipment Access
                                            </h3>
                                            <div className="border-t border-gray-200 pt-4 space-y-6">
                                                <DataDisplayRowRadio
                                                    label="What equipment do you have access to"
                                                    value={datum.equipmentAccess}
                                                    opt1Value={1}
                                                    opt1Label="No Equipment (calistanic/outdoor options)"
                                                    opt2Value={2}
                                                    opt2Label="Full Gym Access"
                                                    opt3Value={3}
                                                    opt3Label="Home Gym"
                                                />

                                                <DataDisplayRowMultiSelectStatic
                                                    label="Please select all the home gym equipment that you have (Optional)"
                                                    selectedValues={datum.homeGymEquipment}
                                                    map={HOME_GYM_EQUIPMENT_MAP}
                                                />

                                                <DataDisplayRowRadio
                                                    label="Do you workout at home?"
                                                    value={datum.hasWorkoutsAtHome}
                                                    opt1Value={1}
                                                    opt1Label="Yes"
                                                    opt2Value={2}
                                                    opt2Label="No"
                                                />
                                            </div>
                                        </section>

                                        {/* Personal Details Section */}
                                        <section>
                                            <h3 className="text-lg font-medium mb-4 flex items-center">
                                                <FontAwesomeIcon icon={faIdCard} className="mr-2" />
                                                Personal Details
                                            </h3>
                                            <div className="border-t border-gray-200 pt-4 space-y-6">
                                                <DataDisplayRowText
                                                    label="Birthday"
                                                    value={datum.birthday}
                                                    type="date"
                                                />
                                                <DataDisplayRowText
                                                    label="Height"
                                                    value={`${datum.heightFeet}' ${datum.heightInches}"`}
                                                />
                                                <DataDisplayRowText
                                                    label="Weight"
                                                    value={`${datum.weight} lbs`}
                                                />
                                                <DataDisplayRowRadio
                                                    label="Gender"
                                                    value={datum.gender}
                                                    opt1Value={GENDER_MALE}
                                                    opt1Label="Male"
                                                    opt2Value={GENDER_FEMALE}
                                                    opt2Label="Female"
                                                    opt3Value={GENDER_OTHER}
                                                    opt3Label="Other"
                                                />
                                                {datum.gender === GENDER_OTHER && (
                                                    <DataDisplayRowText
                                                        label="Gender (Other)"
                                                        value={datum.genderOther}
                                                    />
                                                )}
                                            </div>
                                        </section>

                                        {/* Goals Section */}
                                        <section>
                                            <h3 className="text-lg font-medium mb-4 flex items-center">
                                                <FontAwesomeIcon icon={faTrophy} className="mr-2" />
                                                Goals
                                            </h3>
                                            <div className="border-t border-gray-200 pt-4 space-y-6">
                                                <DataDisplayRowText
                                                    label="What is your ideal weight for your fitness goal?"
                                                    value={`${datum.idealWeight} lbs`}
                                                />
                                                <DataDisplayRowSelectStatic
                                                    label="My current level of physical activity is"
                                                    selectedValue={datum.physicalActivity}
                                                    map={PHYSICAL_ACTIVITY_MAP}
                                                />
                                                <DataDisplayRowSelectStatic
                                                    label="Enter the number of days per week that you can train"
                                                    selectedValue={datum.daysPerWeek}
                                                    map={DAYS_PER_WEEK_MAP}
                                                />
                                                <DataDisplayRowSelectStatic
                                                    label="Enter the length of time per day that you can train"
                                                    selectedValue={datum.timePerDay}
                                                    map={TIME_PER_DAY_MAP}
                                                />
                                                <DataDisplayRowSelectStatic
                                                    label="Enter the number of weeks that you would like your training plan to last"
                                                    selectedValue={datum.maxWeeks}
                                                    map={MAX_WEEK_MAP}
                                                />
                                                <DataDisplayRowMultiSelectStatic
                                                    label="Enter your fitness goals"
                                                    selectedValues={datum.goals}
                                                    map={FITNESS_GOAL_MAP}
                                                />
                                                <DataDisplayRowMultiSelectStatic
                                                    label="Enter your workout preferences"
                                                    selectedValues={datum.workoutPreferences}
                                                    map={WORKOUT_PREFERENCE_MAP}
                                                />
                                            </div>
                                        </section>

                                        {/* Back button */}
                                        <div className="mt-8">
                                            <Link
                                                to="/fitness-plans"
                                                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                                Back to fitness plans
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                )}
            </div>
        </Layout>
    );
}

export default MemberFitnessPlanSubmissionForm;
import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faEye,
	faBuilding,
	faEnvelope,
	faSquarePhone,
	faTable,
	faHome,
	faLock,
	faTimesCircle,
	faArrowRight,
	faImage,
	faEllipsis,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faKey,
	faUnlock,
    faWarning,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {getAccountDetailAPI} from "../../../API/Account";
import {postDisableOTP} from "../../../API/gateway";
import DateTimeTextFormatter from "../../Reusable/EveryPage/DateTimeTextFormatter";
import CheckboxTextFormatter from "../../Reusable/EveryPage/CheckboxTextFormatter";
import SelectTextFormatter from "../../Reusable/EveryPage/SelectTextFormatter";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputField from "../../Reusable/FormInputField";
import DataDisplayRowImage from "../../Reusable/DataDisplayRowImage";
import TagsTextFormatter from "../../Reusable/EveryPage/TagsTextFormatter";
import URLTextFormatter from "../../Reusable/EveryPage/URLTextFormatter";
import EmailTextFormatter from "../../Reusable/EveryPage/EmailTextFormatter";
import PhoneTextFormatter from "../../Reusable/EveryPage/PhoneTextFormatter";
import DateTextFormatter from "../../Reusable/EveryPage/DateTextFormatter";
import {COMMERCIAL_CUSTOMER_TYPE_OF_ID} from "../../../Constants/App";
import {
	ROOT_ROLE_ID,
	ADMIN_ROLE_ID,
	TRAINER_ROLE_ID,
	MEMBER_ROLE_ID,
} from "../../../Constants/App";
import {
	USER_PHONE_TYPE_OF_OPTIONS_WITH_EMPTY_OPTIONS,
	USER_TYPE_OF_FILTER_OPTIONS,
	USER_ORGANIZATION_TYPE_OPTIONS,
	GENDER_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AccountTwoFactorAuthenticationDetail({ modalStates, updateModalState }) {

	////
	//// Global state.
	////

// Global state
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    // Local state
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");

	// Modal related states.
	const [showDisableOTPWarning, setShowDisableOTPWarning] = useState(false);

	////
	//// Event handling.
	////

	const onDisableTwoFactorAuthenticationButtonClick = () => {
		console.log("onDisableTwoFactorAuthenticationButtonClick: Starting...");
		console.log("otpEnabled:", currentUser.otpEnabled);
		postDisableOTP(onDisableOTPSuccess, onDisableOTPError, onDisableOTPDone);
	};

	const onCloseDisableTwoFactorAuthenticationModal = () => {
		setErrors({});
		setShowDisableOTPWarning(false);
	};

	////
	//// API.
	////

	// --- Account Detail --- //

	const onAccountDetailSuccess = (response) => {
		console.log("onAccountDetailSuccess: Starting...");
		setCurrentUser(response);
	};

	const onAccountDetailError = (apiErr) => {
		console.log("onAccountDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onAccountDetailDone = () => {
		console.log("onAccountDetailDone: Starting...");
		setFetching(false);
	};

	// --- 2FA Disable --- //

	const onDisableOTPSuccess = (response) => {
		console.log("onDisableOTPSuccess: Starting...");

		// Update the current logged in user account.
		setCurrentUser(response);

		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("2FA Disabled")

		// Change the page state to accomodate success.
		setErrors({});
		setShowDisableOTPWarning(false);;
		
	};

	const onDisableOTPError = (apiErr) => {
		console.log("onDisableOTPError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onDisableOTPDone = () => {
		console.log("onDisableOTPDone: Starting...");
		setFetching(false);
		setShowDisableOTPWarning(false);
	};

	////
	//// BREADCRUMB
	////

	const generateBreadcrumbItemLink = (currentUser) => {
		let dashboardLink;
		switch (currentUser.role) {
			case ROOT_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case ADMIN_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case TRAINER_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case MEMBER_ROLE_ID:
				dashboardLink = "/dashboard";
				break;
			default:
				dashboardLink = "/501"; // Default or error handling
				break;
		}
		return dashboardLink;
	};

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: generateBreadcrumbItemLink(currentUser),
		},
		{label: "Detail (2FA)", icon: faEye},
	];

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			setErrors({});
			getAccountDetailAPI(
				onAccountDetailSuccess,
				onAccountDetailError,
				onAccountDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, []);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

    const resetModals = () => {
		updateModalState("twoFactor", "showDisableOTPWarning", false);
	};

	// showDisableOTPWarning, setShowDisableOTPWarning

	return (
        <>
        {!currentUser.otpEnabled ? (
            <div>
                <div>

                    {/* Status Indicator */}
                    <div className="flex items-center space-x-3 mb-6">
                        <div className="flex-shrink-0">
                            <div className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-red-100">
                                <FontAwesomeIcon 
                                    icon={faTimesCircle} 
                                    className="h-5 w-5 text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">
                                Two-Factor Authentication is not enabled
                            </h3>
                            <p className="text-sm text-gray-500">
                                Your account is currently not protected by 2FA
                            </p>
                        </div>
                    </div>

                    {/* Quick Setup Card */}
                    <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 mb-6">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm font-medium text-gray-900">
                                    Ready to secure your account?
                                </p>
                                <p className="text-sm text-gray-500">
                                    Setup takes less than 2 minutes
                                </p>
                            </div>
                            <Link
                                to="/account/2fa/enable"
                                className="mt-3 sm:mt-0 inline-flex items-center px-4 py-2 bg-primary 
                                         hover:bg-primary-hover text-white rounded-lg transition-colors 
                                         duration-200 text-sm font-medium"
                            >
                                Get started
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />
                            </Link>
                        </div>
                    </div>

                    {/* Benefits List */}
                    <div>
                        <h4 className="text-sm font-medium text-gray-900 mb-4">
                            Benefits of Two-Factor Authentication
                        </h4>
                        <ul className="space-y-3">
                            {[
                                'Protect against unauthorized access',
                                'Secure your personal information',
                                'Get alerts about login attempts'
                            ].map((benefit, index) => (
                                <li key={index} className="flex items-start">
                                    <FontAwesomeIcon 
                                        icon={faCheckCircle} 
                                        className="h-4 w-4 text-green-500 mt-0.5 mr-3 flex-shrink-0" 
                                    />
                                    <span className="text-sm text-gray-600">{benefit}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        ) : (
            <div className="border-t-4 border-green-500">
                <div className="p-6">
                    {/* Status Indicator */}
                    <div className="flex items-center space-x-3 mb-6">
                        <div className="flex-shrink-0">
                            <div className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-green-100">
                                <FontAwesomeIcon 
                                    icon={faCheckCircle} 
                                    className="h-5 w-5 text-green-600"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">
                                Two-Factor Authentication is enabled
                            </h3>
                            <p className="text-sm text-gray-500">
                                Your account is protected with an additional layer of security
                            </p>
                        </div>
                    </div>

                    {/* Security Tips */}
                    <div className="bg-green-50 rounded-lg p-4 border border-green-100">
                        <h4 className="text-sm font-medium text-green-800 mb-3">
                            Security Reminders
                        </h4>
                        <ul className="space-y-2">
                            <li className="flex items-start text-sm text-green-700">
                                <FontAwesomeIcon icon={faCheckCircle} className="h-4 w-4 mt-0.5 mr-2 flex-shrink-0" />
                                Keep your backup codes in a safe place
                            </li>
                            <li className="flex items-start text-sm text-green-700">
                                <FontAwesomeIcon icon={faCheckCircle} className="h-4 w-4 mt-0.5 mr-2 flex-shrink-0" />
                                Never share your 2FA codes with anyone
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )}
        <Modal
    isOpen={modalStates?.showDisableOTPWarning}
    onClose={() => updateModalState("twoFactor", "showDisableOTPWarning", false)}
    header="Disable Two-Factor Authentication"
>
    <div className="p-6">
        {/* Warning Icon and Message */}
        <div className="mb-6">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                <FontAwesomeIcon 
                    icon={faWarning} 
                    className="w-6 h-6 text-red-600" 
                />
            </div>
            <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Disable Two-Factor Authentication?
                </h3>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        You're about to disable two-factor authentication. This will make your account less secure.
                    </p>
                </div>
            </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between space-x-3">
            <button
                type="button"
                onClick={() => updateModalState("twoFactor", "showDisableOTPWarning", false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 
                         bg-white border border-gray-300 rounded-md hover:bg-gray-50 
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
                         transition-colors duration-200"
            >
                Cancel
            </button>
            <button
                type="button"
                onClick={onDisableTwoFactorAuthenticationButtonClick}
                disabled={isFetching}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white 
                         bg-red-600 border border-transparent rounded-md hover:bg-red-700 
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                         disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
            >
                {isFetching ? (
                    <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                        Disabling...
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faUnlock} className="mr-2 h-4 w-4" />
                        Disable 2FA
                    </>
                )}
            </button>
        </div>
    </div>
</Modal>
        </>



	);
}


export default AccountTwoFactorAuthenticationDetail;

// Components/Account/User/UserProfile.jsx
import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faUserCircle,
	faTags,
	faUsers,
	faChartLine,
	faClipboardQuestion,
	faCreditCard,
	faMedal,
	faCrown,
	faEllipsisH,
	faEdit,
	faKey,
	faPlus,
	faGauge,
	faCalendarAlt,
	faEnvelope,
	faPhone,
	faLocationDot,
	faPlugCircleCheck,
	faBuildingUser,
	faUserTag,
	faUser,
	faWarning,
	faBolt,
	faArrowRight,
	faCheckCircle,
	faLock,
	faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

// Import API and State
import {getAccountDetailAPI} from "../../../API/Account";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";

// Import Components
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import ContentHeader from "../../Reusable/Shared/ContentHeaderForProfile";
import Modal from "../../Reusable/Modal";

// Import Tab Components
import UserInfo from "./UserInfo";
import ActivityStream from "./ActivityStream";
import AccountTagList from "../DetailForTags";
import AccountFriendList from "../Friend/Friend";
import AccountWearableTechLaunchpad from "../WearableTech/Launchpad";
import Badges from "./Badges";
import AccountLevelInfo from "../Level/Level";
import AccountSubscriptionDetailAndCancel from "../Subscription/Subscription";
import FitnessProfile from "../FitnessProfile";
import AccountMoreLaunchpad from "../More/Launchpad";
import StatusBadge from "../../Reusable/StatusBadge";
import AccountTwoFactorAuthenticationDetail from "../2FA/View";
import { ADMIN_ROLE_ID } from "../../../Constants/App";

function UserProfile() {
	// Global State
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	// Local State
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [activeTab, setActiveTab] = useState("detail");
	const [modalStates, setModalStates] = useState({
		tags: {
			showAdd: false,
			showEdit: false,
			showDelete: false,
		},
		friends: {
			showAdd: false,
			showEdit: false,
			showDelete: false,
		},
		twoFactor: {
			showDisableOTPWarning: false,
			showEnableOTPConfirm: false,
		},
		// Add other modal states as needed
	});
	const [isVerifying, setIsVerifying] = useState(false);
	const location = useLocation();

	const defaultAvatarUrl = "static/default_user.jpg";

	// Navigation Configuration
	const navigationConfig = {
		detail: {
			id: "detail",
			label: "User Details",
			icon: faUserCircle,
			subtitle: "Manage your user details settings",
			headerActions: ({onEdit}) => (
				<Link
					to="/account/update"
					className="inline-flex items-center px-4 py-2 border border-gray-300
		            rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white
		            hover:bg-gray-50 w-full sm:w-auto justify-center">
					<FontAwesomeIcon icon={faEdit} className="mr-2" />
					Edit Profile
				</Link>
			),
		},
		tags: {
			id: "tags",
			label: "Tags",
			icon: faTags,
			subtitle: "Manage your tags settings",
			headerActions: ({onOpenModal}) => (
				<button
					onClick={() => onOpenModal("tags", "showAdd", true)}
					className="inline-flex items-center px-4 py-2 border border-gray-300 
                    rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white 
                    hover:bg-gray-50 w-full sm:w-auto justify-center">
					<FontAwesomeIcon icon={faPlus} className="mr-2" />
					New Tag
				</button>
			),
		},
		friends: {
			id: "friends",
			label: "Friends",
			icon: faUsers,
			subtitle: "Manage your friends",
			headerActions: ({onOpenModal}) => (
				<button
					onClick={() => onOpenModal("friends", "showAdd", true)}
					className="inline-flex items-center px-4 py-2 border border-gray-300 
                    rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white 
                    hover:bg-gray-50 w-full sm:w-auto justify-center">
					<FontAwesomeIcon icon={faPlus} className="mr-2" />
					Add Friend
				</button>
			),
		},
		// activity: {
		//     id: "activity",
		//     label: "Activity",
		//     icon: faChartLine,
		//     subtitle: "View your activity history"
		// },
		badges: {
			id: "badges",
			label: "Badges",
			icon: faMedal,
			subtitle: "View your earned badges",
			headerActions: ({onOpenModal}) => (
				<Link
					to="/fitness-challenge"
					className="inline-flex items-center px-4 py-2 border border-gray-300 
                    rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white 
                    hover:bg-gray-50 w-full sm:w-auto justify-center">
					<FontAwesomeIcon icon={faBolt} className="mr-2" />
					Find Challenges
				</Link>
			),
		},
		subscription: {
			id: "subscription",
			label: "Subscription",
			icon: faCreditCard,
			subtitle: "Manage your subscription",
		},
		...(currentUser?.role !== ADMIN_ROLE_ID && {
            fitness: {
                id: "fitness",
                label: "Fitness Profile",
                icon: faClipboardQuestion,
                subtitle: "Complete your fitness profile",
            }
        }),
		// twoFactor: {
		// 	id: "twoFactor",
		// 	label: "2FA Security",
		// 	icon: faLock,
		// 	subtitle: "Manage two-factor authentication",
		// 	headerActions: ({onOpenModal}) =>
		// 		currentUser?.otpEnabled ? (
		// 			<button
		// 				onClick={() =>
		// 					onOpenModal("twoFactor", "showDisableOTPWarning", true)
		// 				}
		// 				className="inline-flex items-center px-4 py-2 border border-red-300 
        //                          rounded-md shadow-sm text-sm font-medium text-red-700 bg-white 
        //                          hover:bg-red-50 w-full sm:w-auto justify-center"
		// 				disabled={currentUser?.status === 2}>
		// 				<FontAwesomeIcon icon={faUnlock} className="mr-2" />
		// 				Disable 2FA
		// 			</button>
		// 		) : (
		// 			<Link
		// 				to="/account/2fa/enable"
		// 				className="inline-flex items-center px-4 py-2 border border-green-300 
        //                          rounded-md shadow-sm text-sm font-medium text-green-700 bg-white 
        //                          hover:bg-green-50 w-full sm:w-auto justify-center"
		// 				disabled={currentUser?.status === 2}>
		// 				<FontAwesomeIcon icon={faLock} className="mr-2" />
		// 				Enable 2FA
		// 			</Link>
		// 		),
		// },
		// 2FA has issues with submitting the refresh token
		more: {
			id: "more",
			label: "More",
			icon: faEllipsisH,
			subtitle: "Additional settings",
		},
	};

	useEffect(() => {
		// Get tab from URL query parameter
		const params = new URLSearchParams(location.search);
		const tabParam = params.get("tab");

		// If a valid tab is specified in URL, set it as active
		if (tabParam && navigationConfig[tabParam]) {
			setActiveTab(tabParam);
		}
	}, [location]);

	// Modal State Management
	const updateModalState = (tabId, modalType, value) => {
		setModalStates((prev) => ({
			...prev,
			[tabId]: {
				...prev[tabId],
				[modalType]: value,
			},
		}));
	};

	// Effects
	useEffect(() => {
		fetchData();
	}, []);

	// API Handlers
	const fetchData = () => {
		setFetching(true);
		getAccountDetailAPI(
			onAccountDetailSuccess,
			onAccountDetailError,
			onAccountDetailDone
		);
	};

	const onAccountDetailSuccess = (response) => {
		setCurrentUser(response);
	};

	const onAccountDetailError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to load user profile");
		setTopAlertStatus("error");
	};

	const onAccountDetailDone = () => {
		setFetching(false);
	};

	// Render Methods
	const renderContent = () => {
		const currentNav = navigationConfig[activeTab];
		const HeaderActions = currentNav?.headerActions;

		return (
			<div className="bg-white rounded-lg shadow-sm border border-gray-200">
				<ContentHeader
					title={currentNav?.label}
					subtitle={currentNav?.subtitle}
					actions={
						HeaderActions &&
						HeaderActions({
							onOpenModal: updateModalState,
						})
					}
				/>
				<div className="p-4 sm:p-6">{renderTabContent()}</div>
			</div>
		);
	};

	const renderTabContent = () => {
		const props = {
			modalStates: modalStates[activeTab],
			updateModalState: updateModalState,
		};

		switch (activeTab) {
			case "detail":
				return <UserInfo {...currentUser} />;
			case "activity":
				return <ActivityStream {...currentUser} />;
			case "tags":
				return <AccountTagList {...props} />;
			case "friends":
				return <AccountFriendList {...props} />;
			case "badges":
				return <Badges {...currentUser} />;
			case "subscription":
				return <AccountSubscriptionDetailAndCancel {...currentUser} />;
			case "fitness":
				return <FitnessProfile {...currentUser} />;
			case "twoFactor":
				return <AccountTwoFactorAuthenticationDetail {...props} />;
			case "more":
				return <AccountMoreLaunchpad {...currentUser} />;

			default:
				return <UserInfo {...currentUser} />;
		}
	};

	// Generate breadcrumb link based on user role
	const generateBreadcrumbItemLink = (currentUser) => {
		switch (currentUser?.role) {
			case 1:
				return "/root/dashboard";
			case 2:
				return "/admin/dashboard";
			case 3:
				return "/trainer/dashboard";
			case 4:
				return "/dashboard";
			default:
				return "/";
		}
	};

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: generateBreadcrumbItemLink(currentUser),
		},
		{
			label: "Account",
			icon: faUser,
		},
	];

	const handleVerifyEmail = async () => {
		try {
			setIsVerifying(true);

			// Store the return URL in localStorage
			localStorage.setItem("returnUrl", "/account");

			// Redirect to verification page
			window.location.href = `/verify?q=${currentUser?.emailVerificationCode}`;
		} catch (error) {
			console.error("Email verification failed:", error);
			setTopAlertMessage(error.message || "Failed to process verification");
			setTopAlertStatus("danger");
			setIsVerifying(false);
		}
	};

	const renderEmailVerificationStatus = () => {
		if (currentUser?.wasEmailVerified === true) {
			return (
				<StatusBadge
					variant="success"
					text="Email Verified"
					icon={<FontAwesomeIcon icon={faCheckCircle} className="w-3 h-auto" />}
					tooltip="Your email has been verified"
				/>
			);
		} else {
			{
				return (
					<StatusBadge
						variant="error"
						text="Email Not Verified"
						icon={<FontAwesomeIcon icon={faWarning} className="w-3 h-auto" />}
						tooltip="Please verify your email address to access all features"
						action={{
							text: "Verify Now",
							onClick: handleVerifyEmail,
							icon: (
								<FontAwesomeIcon icon={faArrowRight} className="w-3 h-auto" />
							),
						}}
						isLoading={isVerifying}
					/>
				);
			}
		}
		return null;
	};

	if (isFetching) {
		return <PageLoadingContent />;
	}

	if (Object.keys(errors).length > 0) {
		return <FormErrorBox errors={errors} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-8 overflow-x-hidden">
				{/* Profile Header */}
				<div className="relative min-h-[400px] lg:h-[400px] -mt-6 -mx-6 mb-6">
					{/* Background */}
					<div className="absolute inset-0">
						<div className="absolute inset-0 bg-gradient-to-br from-darkShade to-main opacity-95" />
						<div
							className="absolute inset-0 opacity-[0.02]"
							style={{
								backgroundImage: `linear-gradient(45deg, #000 25%, transparent 25%),
                    linear-gradient(-45deg, #000 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #000 75%),
                    linear-gradient(-45deg, transparent 75%, #000 75%)`,
								backgroundSize: "20px 20px",
								backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
							}}
						/>
					</div>

					{/* Content Container */}
					<div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 lg:py-0">
						<div className="h-full flex items-center">
							<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-6 lg:gap-8">
								{/* Left Section - Profile Image & Basic Info */}
								<div className="lg:col-span-3">
									<div className="space-y-4">
										<div className="relative max-w-[200px] mx-auto lg:max-w-none">
											<img
												src={currentUser?.avatarObjectUrl || defaultAvatarUrl}
												alt={currentUser?.name || "Profile"}
												className="w-full aspect-square object-cover rounded-sm border-2 border-lightShade/10
                                    shadow-lg"
											/>
											<div className="absolute bottom-3 right-3">
												<div className="w-3 h-3 bg-activeBg rounded-full border border-lightShade/30">
													<div className="absolute inset-0 bg-activeBg rounded-full animate-ping opacity-50"></div>
												</div>
											</div>
										</div>

										{currentUser?.createdAt && (
											<div className="bg-lightShade/5 border border-lightShade/10 rounded-sm p-3">
												<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary mb-1">
													<FontAwesomeIcon
														icon={faCalendarAlt}
														className="text-textLight/40"
													/>
													Member Since
												</div>
												<div className="text-sm text-lightShade font-secondary">
													{new Date(currentUser.createdAt).toLocaleDateString(
														"en-US",
														{
															month: "long",
															year: "numeric",
														}
													)}
												</div>
											</div>
										)}
									</div>
								</div>

								{/* Middle Section - Main Information */}
								<div className="lg:col-span-6 space-y-6">
									<div>
										<div className="flex flex-col sm:flex-row sm:items-start gap-4 mb-2">
											<div className="text-center sm:text-left">
												{currentUser?.name && (
													<h1 className="text-2xl sm:text-3xl font-primary font-semibold text-lightShade mb-1">
														{currentUser.name}
													</h1>
												)}
												<div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-3">
													{currentUser?.organizationName && (
														<span className="flex items-center gap-2 text-sm text-textLight/80 font-secondary">
															<FontAwesomeIcon
																icon={faBuildingUser}
																className="text-textLight/40"
															/>
															{currentUser.organizationName}
														</span>
													)}
													{renderEmailVerificationStatus()}
												</div>
											</div>
										</div>
									</div>

									{/* Contact Information */}
									<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
										<div className="space-y-4">
											{currentUser?.email && (
												<div className="space-y-1">
													<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary">
														<FontAwesomeIcon
															icon={faEnvelope}
															className="text-textLight/40"
														/>
														Email
													</div>
													<div className="text-sm text-lightShade font-secondary break-all">
														{currentUser.email}
													</div>
												</div>
											)}
											{currentUser?.phone && (
												<div className="space-y-1">
													<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary">
														<FontAwesomeIcon
															icon={faPhone}
															className="text-textLight/40"
														/>
														Phone
													</div>
													<div className="text-sm text-lightShade font-secondary">
														{currentUser.phone}
													</div>
												</div>
											)}
										</div>
										{(currentUser?.addressLine1 ||
											currentUser?.city ||
											currentUser?.region) && (
											<div className="space-y-1">
												<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary">
													<FontAwesomeIcon
														icon={faLocationDot}
														className="text-textLight/40"
													/>
													Location
												</div>
												<div className="text-sm text-lightShade font-secondary">
													{currentUser.addressLine1 && (
														<div>{currentUser.addressLine1}</div>
													)}
													{currentUser.city && currentUser.region && (
														<div>{`${currentUser.city}, ${currentUser.region}`}</div>
													)}
													{currentUser.postalCode && currentUser.country && (
														<div>{`${currentUser.postalCode}, ${currentUser.country}`}</div>
													)}
												</div>
											</div>
										)}
									</div>

									{/* Stats */}
									<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
										{[
											{
												label: "Current Level",
												icon: faChartLine,
												value: currentUser?.currentLevelDetails?.name,
												subtext: `${
													currentUser?.currentLevel?.challengesCompleted || 0
												}/${
													currentUser?.currentLevelDetails?.numChallenges || 0
												} Challenges`,
											},
											{
												label: "Active Tags",
												icon: faTags,
												value: currentUser?.tags?.length || "0",
												subtext: "Total Tags",
											},
											{
												label: "Connected Apps",
												icon: faPlugCircleCheck,
												value:
													currentUser?.healthAppConnections?.reduce(
														(count, connection) =>
															connection.connected === "CONNECTED"
																? count + 1
																: count,
														0
													) || "0",
												subtext: `of ${
													currentUser?.healthAppConnections?.length || 0
												} Available`,
											},
										].map((stat, index) => (
											<div
												key={index}
												className="bg-lightShade/5 border border-lightShade/10 rounded-sm p-3 
                                    hover:bg-lightShade/10 transition-all duration-300">
												<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary mb-1">
													<FontAwesomeIcon
														icon={stat.icon}
														className="text-textLight/40"
													/>
													{stat.label}
												</div>
												<div className="text-xl font-primary font-semibold text-lightShade mb-0.5">
													{stat.value}
												</div>
												<div className="text-xs text-textLight/70">
													{stat.subtext}
												</div>
											</div>
										))}
									</div>
								</div>

								{/* Right Section - Actions & Quick Stats */}
								<div className="lg:col-span-3 space-y-4">
									<div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-1 gap-4">
										<Link
											to="/account/update"
											className="block w-full px-4 py-2.5 bg-lightShade text-main rounded-sm 
                                hover:bg-lightGrayShade transition-all duration-300 font-secondary text-sm">
											<span className="flex items-center justify-center gap-2">
												<FontAwesomeIcon icon={faEdit} className="text-xs" />
												Edit Profile
											</span>
										</Link>
										<Link
											to="/account/change-password"
											className="block w-full px-4 py-2.5 bg-lightShade/5 text-lightShade rounded-sm
                                border border-lightShade/10 hover:bg-lightShade/10 transition-all duration-300 
                                font-secondary text-sm">
											<span className="flex items-center justify-center gap-2">
												<FontAwesomeIcon icon={faKey} className="text-xs" />
												Change Password
											</span>
										</Link>
									</div>

									<div className="bg-lightShade/5 border border-lightShade/10 rounded-sm p-3 mt-6">
										<div className="flex items-center gap-2 text-xs text-textLight/60 font-secondary mb-3">
											<FontAwesomeIcon
												icon={faUserTag}
												className="text-textLight/40"
											/>
											Account Status
										</div>
										<div className="space-y-2">
											<div className="flex justify-between items-center">
												<span className="flex items-center gap-2 text-xs text-textLight/70">
													<FontAwesomeIcon
														icon={faCrown}
														className="text-textLight/40"
													/>
													Subscription
												</span>
												<span className="text-xs font-medium text-lightShade">
													{currentUser?.isSubscriber ? "Premium" : "Trial"}
												</span>
											</div>
											<div className="flex justify-between items-center">
												<span className="flex items-center gap-2 text-xs text-textLight/70">
													<FontAwesomeIcon
														icon={faUserCircle}
														className="text-textLight/40"
													/>
													Role
												</span>
												<span className="text-xs font-medium text-lightShade">
													{currentUser?.role === 4 ? "Member" : "Staff"}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Main Layout */}
				<div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
					{/* Navigation */}
					<div className="w-full lg:w-64 lg:flex-shrink-0 bg-white rounded-lg shadow-sm border border-gray-200 p-4 lg:p-0">
						<nav className="flex flex-col gap-2">
							{Object.values(navigationConfig).map((item) => (
								<button
									key={item.id}
									onClick={() => setActiveTab(item.id)}
									className={`
                                        flex items-center px-4 py-3 text-sm font-medium rounded-lg
                                        transition-all duration-200
                                        ${
																					activeTab === item.id
																						? "bg-blue-50 text-blue-600"
																						: "text-gray-600 hover:bg-gray-50"
																				}
                                    `}>
									<FontAwesomeIcon
										icon={item.icon}
										className={`mr-3 ${
											activeTab === item.id ? "text-blue-600" : "text-gray-400"
										}`}
									/>
									{item.label}
								</button>
							))}
						</nav>
					</div>

					{/* Main Content */}
					<div className="flex-1">{renderContent()}</div>
				</div>
			</div>
		</Layout>
	);
}

export default UserProfile;

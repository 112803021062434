import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faPlus,
	faArrowLeft,
	faGauge,
	faEye,
	faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../../../Menu/Layout";
import {ListHeader} from "../../../Reusable/AdminList";
import FormErrorBox from "../../../Reusable/FormErrorBox";
import FormAttachmentField from "../../../Reusable/FormAttachmentField";
import FormInputField from "../../../Reusable/FormInputField";
import FormTextareaField from "../../../Reusable/FormTextareaField";
import FormRadioField from "../../../Reusable/FormRadioField";
import FormSelectField from "../../../Reusable/FormSelectField";
import FormCheckboxField from "../../../Reusable/FormCheckboxField";
import FormSelectFieldForVideoCategory from "../../../Reusable/FormSelectFieldForVideoCategory";
import FormSelectFieldForOffer from "../../../Reusable/FormSelectFieldForOffer";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";
import FormQuillEditor from "../../../Reusable/FormQuillField";

import {postVideoContentCreateAPI} from "../../../../API/VideoContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../../AppState";
import {
	VIDEO_CONTENT_TYPE_WITH_EMPTY_OPTIONS,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../../Constants/FieldOptions";
import {
	VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE,
	VIDEO_CONTENT_VIDEO_TYPE_VIMEO,
	VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL,
} from "../../../../Constants/App";
import VideoPlayer, {VIDEO_LAYOUTS} from "../../../Reusable/VideoPlayer";

function AdminVideoContentAdd() {
	const {vcid} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");

	// Form states
	const [thumbnailType, setThumbnailType] = useState(
		VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");

	const [videoType, setVideoType] = useState(
		VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentName, setVideoAttachmentName] = useState("");
	const [videoAttachment, setVideoAttachment] = useState(null);
	const [type, setType] = useState(2); // 1=System, 2=Custom
	const [name, setName] = useState("");
	const [no, setNo] = useState("");
	const [description, setDescription] = useState("");
	const [authorName, setAuthorName] = useState("");
	const [authorURL, setAuthorURL] = useState("");
	const [duration, setDuration] = useState("");
	const [videoCategoryID, setVideoCategoryID] = useState("");
	const [isVideoCategoryOther, setIsVideoCategoryOther] = useState(false);
	const [hasMonetization, setHasMonetization] = useState(false);
	const [offerID, setOfferID] = useState("");
	const [isOfferOther, setIsOfferOther] = useState(false);
	const [hasTimedLock, setHasTimedLock] = useState(false);
	const [timedLock, setTimedLock] = useState("");

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/admin/dashboard"},
		{
			label: "Video Collections",
			icon: faVideo,
			link: "/admin/video-collections",
		},
		{
			label: "Detail(Overview)",
			icon: faEye,
			link: `/admin/video-collection/${vcid}`,
		},
		{label: "Add New", icon: faPlus},
	];

	// Event handlers
	const onSubmitClick = (e) => {
		e.preventDefault();
		setFetching(true);
		setErrors({});

		const payload = {
			video_type: parseInt(videoType),
			video_upload: videoAttachmentID,
			video_url: videoURL,
			thumbnail_type: parseInt(thumbnailType),
			thumbnail_upload: thumbnailAttachmentID,
			thumbnail_url: thumbnailURL,
			name: name,
			no: parseInt(no),
			description: description,
			author_name: authorName,
			author_url: authorURL,
			duration: duration,
			type: type,
			category_id: videoCategoryID,
			collection_id: vcid,
			has_monetization: hasMonetization,
			offer_id: offerID,
			has_timed_lock: hasTimedLock,
			timed_lock: timedLock,
		};

		postVideoContentCreateAPI(
			payload,
			onSubmitSuccess,
			onSubmitError,
			onSubmitDone
		);
	};

	const onSubmitSuccess = (response) => {
		setTopAlertMessage("Video content created successfully");
		setTopAlertStatus("success");
		setForceURL(`/admin/video-collection/${vcid}/video-content/${response.id}`);
	};

	const onSubmitError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to create video content");
		setTopAlertStatus("error");
	};

	const onSubmitDone = () => {
		setFetching(false);
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="p-6">
				<ListHeader title="Add Video Content" icon={faPlus} />

				<FormErrorBox errors={errors} />

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<form onSubmit={onSubmitClick} className="space-y-6">
						{/* Thumbnail Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faImage} className="mr-2" />
								Thumbnail
							</h2>
							<div className="space-y-4">
								<FormRadioField
									label="Thumbnail Type"
									name="thumbnailType"
									value={thumbnailType}
									opt1Value={
										VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
									}
									opt1Label="File Upload"
									opt2Value={VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL}
									opt2Label="External URL"
									onChange={(e) => {
										const newType = parseInt(e.target.value);
										setThumbnailType(newType);
										// Clear relevant fields when switching types
										if (
											newType ===
											VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
										) {
											setThumbnailURL("");
										} else {
											setThumbnailAttachmentID("");
											setThumbnailAttachmentName("");
										}
									}}
									errorText={errors?.thumbnailType}
								/>

								{thumbnailType ===
								VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<FormAttachmentField
										label="Thumbnail Image"
										name="thumbnail"
										placeholder="Choose an image"
										errorText={errors && errors.thumbnail}
										attachmentID={thumbnailAttachmentID}
										setAttachmentID={setThumbnailAttachmentID}
										attachmentFilename={thumbnailAttachmentName}
										setAttachmentFilename={setThumbnailAttachmentName}
										helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
										validationText="Maximum size: 10MB"
										disabled={false}
										acceptedFileTypes="image/*"
									/>
								) : (
									<FormInputField
										label="Thumbnail URL"
										name="thumbnailURL"
										type="url"
										placeholder="Enter thumbnail URL"
										value={thumbnailURL}
										errorText={errors?.thumbnailUrl}
										onChange={(e) => {
											setThumbnailURL(e.target.value);
											setThumbnailAttachmentID("");
											setThumbnailAttachmentName("");
										}}
										helpText="Enter the URL of the thumbnail image"
									/>
								)}
							</div>
						</div>

						{/* Video Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faVideo} className="mr-2" />
								Video
							</h2>
							<div className="space-y-4">
								<FormRadioField
									label="Video Type"
									name="videoType"
									value={videoType}
									opt1Value={VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
									opt1Label="File Upload"
									opt2Value={VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE}
									opt2Label="YouTube URL"
									opt3Value={VIDEO_CONTENT_VIDEO_TYPE_VIMEO}
									opt3Label="Vimeo URL"
									onChange={(e) => {
										const newType = parseInt(e.target.value);
										setVideoType(newType);
										if (
											newType ===
											VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
										) {
											setVideoURL("");
										} else {
											setVideoAttachmentID("");
											setVideoAttachmentName("");
											setVideoAttachment(null);
										}
									}}
									errorText={errors?.videoType}
								/>

								{videoType ===
								VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<FormAttachmentField
										label="Video File"
										name="video"
										placeholder="Choose a video file"
										errorText={errors && errors.video}
										attachmentID={videoAttachmentID}
										setAttachmentID={setVideoAttachmentID}
										attachmentFilename={videoAttachmentName}
										setAttachmentFilename={setVideoAttachmentName}
										helpText="Upload a video file (MP4, MOV, etc.)"
										validationText="Maximum size: 500MB"
										disabled={false}
										acceptedFileTypes="video/*"
									/>
								) : (
									<FormInputField
										label="Video URL"
										name="videoURL"
										type="url"
										placeholder={`Enter ${
											videoType === VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE
												? "YouTube"
												: "Vimeo"
										} URL`}
										value={videoURL}
										errorText={errors?.videoUrl}
										onChange={(e) => {
											setVideoURL(e.target.value);
											setVideoAttachmentID("");
											setVideoAttachmentName("");
											setVideoAttachment(null);
										}}
										helpText={`Enter the ${
											videoType === VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE
												? "YouTube"
												: "Vimeo"
										} video URL`}
									/>
								)}
							</div>
						</div>

						{/* Details Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faVideoCamera} className="mr-2" />
								Video Details
							</h2>
							<div className="space-y-4">
								<FormInputField
									label="Name"
									name="name"
									type="text"
									placeholder="Enter video name"
									value={name}
									errorText={errors?.name}
									onChange={(e) => setName(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Number"
									name="no"
									type="text"
									placeholder="Enter video number"
									value={no}
									errorText={errors?.no}
									onChange={(e) => setNo(e.target.value)}
									isRequired={true}
									helpText="Used for ordering videos in the collection"
								/>

								<FormQuillEditor
									label="Description"
									name="description"
									placeholder="Enter video description"
									value={description}
									errorText={errors?.description}
									onChange={(e) => setDescription(e.target.value)}
									// isRequired={true}
								/>

								<FormInputField
									label="Author Name"
									name="authorName"
									type="text"
									placeholder="Enter author name"
									value={authorName}
									errorText={errors?.authorName}
									onChange={(e) => setAuthorName(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Author URL"
									name="authorURL"
									type="url"
									placeholder="Enter author URL"
									value={authorURL}
									errorText={errors?.authorURL}
									onChange={(e) => setAuthorURL(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Duration"
									name="duration"
									type="text"
									placeholder="Ex: 10:30"
									value={duration}
									errorText={errors?.duration}
									onChange={(e) => setDuration(e.target.value)}
									isRequired={true}
									helpText="Format: MM:SS"
								/>

								<FormSelectFieldForVideoCategory
									label="Category"
									videoCategoryID={videoCategoryID}
									setVideoCategoryID={setVideoCategoryID}
									isVideoCategoryOther={isVideoCategoryOther}
									setIsVideoCategoryOther={setIsVideoCategoryOther}
									errorText={errors?.categoryId}
									isRequired={true}
								/>
							</div>
						</div>

						{/* Monetization Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
								Monetization
							</h2>
							<div className="space-y-4">
								<FormCheckboxField
									label="Enable Monetization"
									name="hasMonetization"
									checked={hasMonetization}
									errorText={errors?.hasMonetization}
									onChange={() => setHasMonetization(!hasMonetization)}
									helpText="Enable Monetization to restrict access to this video based on user purchases."
								/>
								{hasMonetization && (
									<>
										<FormSelectFieldForOffer
											label="Offer"
											offerID={offerID}
											setOfferID={setOfferID}
											isOfferOther={isOfferOther}
											setIsOfferOther={setIsOfferOther}
											errorText={errors?.offerId}
											isRequired={true}
											helpText="Select the offer that will grant access to this video"
										/>

										<FormCheckboxField
											label="Enable Timed Lock"
											name="hasTimedLock"
											checked={hasTimedLock}
											errorText={errors?.hasTimedLock}
											onChange={() => setHasTimedLock(!hasTimedLock)}
											helpText="Enable artificial time lock on this video for the user."
										/>

										{hasTimedLock && (
											<FormSelectField
												label="Timed Lock Duration"
												name="timedLock"
												selectedValue={timedLock}
												options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
												onChange={(e) => setTimedLock(e.target.value)}
												errorText={errors?.timedLock}
												helpText="The duration will lock this video for the user until the duration has elapsed"
												isRequired={true}
											/>
										)}
									</>
								)}
							</div>
						</div>

						{/* Action Buttons */}
						<div className="flex flex-col sm:flex-row justify-between gap-4">
							<Link
								to={`/admin/video-collection/${vcid}`}
								className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to Detail(Overview)
							</Link>
							<button
								type="submit"
								className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
								disabled={isFetching}>
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								{isFetching ? "Creating..." : "Create Video Content"}
							</button>
						</div>
					</form>
				)}
			</div>
		</Layout>
	);
}

export default AdminVideoContentAdd;

import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faClock } from "@fortawesome/free-solid-svg-icons";
import Accordion from "../../Reusable/accordion";

function PhasePanel({ phases, onAddWorkout, setSelectedPhase }) {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize activeIndex with 0

  useEffect(() => {
    if (phases?.length > 0 && setSelectedPhase) {
      setSelectedPhase(phases[0]);
    }
  }, [phases, setSelectedPhase]);

  const handlePhaseSelect = (index) => {
    setActiveIndex(index);
    if (setSelectedPhase) {
      setSelectedPhase(phases[index]);
    }
  };

  return (
    <nav className="bg-white rounded-xl shadow-md p-5 space-y-4">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Phases</h2>
        <div className="text-sm text-gray-500">
          {phases?.length || 0} total
        </div>
      </div>

      <div className="space-y-3">
        {phases?.map((phase, index) => (
          <Accordion
            key={phase.id || index}
            isOpenByDefault={index === activeIndex}
            head={
              <div 
                className={`w-full p-4 rounded-lg border transition-all duration-400 ease-out-expo
                  ${index === activeIndex 
                    ? 'border-primary bg-primary/5 shadow-sm' 
                    : 'border-gray-200 hover:border-primary/50 hover:bg-gray-50'
                  }`}
                onClick={() => handlePhaseSelect(index)}
              >
                <div className="flex items-center justify-between pr-8">
                  <div className="flex items-center gap-3">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full 
                      ${index === activeIndex 
                        ? 'bg-primary/10 text-primary' 
                        : 'bg-gray-100 text-gray-500'
                      }`}
                    >
                      <span className="text-sm font-medium">{index + 1}</span>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">
                        {phase.name}
                      </h3>
                      {phase.description && (
                        <p className="text-xs text-gray-500 mt-0.5">
                          {phase.description}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-4">
                    {phase.workouts && (
                      <div className="flex items-center gap-1.5">
                        <FontAwesomeIcon 
                          icon={faLayerGroup} 
                          className="w-3.5 h-3.5 text-gray-400" 
                        />
                        <span className="text-xs text-gray-500">
                          {phase.workouts.length} workouts
                        </span>
                      </div>
                    )}
                    {phase.totalDuration && (
                      <div className="flex items-center gap-1.5">
                        <FontAwesomeIcon 
                          icon={faClock} 
                          className="w-3.5 h-3.5 text-gray-400" 
                        />
                        <span className="text-xs text-gray-500">
                          {phase.totalDuration}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            content={
              <div className="p-4 bg-gray-50 rounded-b-lg border-x border-b border-gray-200 animate-fade-in">
                <button
                  onClick={() => onAddWorkout(phase)}
                  className="w-full px-4 py-2 text-sm font-medium text-primary bg-white 
                    border border-primary/20 rounded-lg hover:bg-primary/5 
                    transition-colors duration-200 flex items-center justify-center gap-2"
                >
                  <FontAwesomeIcon icon={faLayerGroup} className="w-4 h-4" />
                  Manage Workouts
                </button>
                {/* Additional phase details can be added here */}
              </div>
            }
          />
        ))}
      </div>
    </nav>
  );
}

export default PhasePanel;

import React, {useState, memo} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faBars,
	faChevronLeft,
	faChevronDown,
	faChevronRight,
	faSignOut,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {getNavigationItems} from "../../Constants/NavigationItems";
import {useAccountQuery} from "../../Hooks/useAccountQuery";

const LogoutModal = ({isOpen, onClose, onConfirm}) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 z-[60] overflow-y-auto">
			<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
				{/* Background overlay */}
				<div
					className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
					onClick={onClose}
				/>

				{/* Modal panel */}
				<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-lg sm:w-full">
					{/* Header */}
					<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<div className="sm:flex sm:items-start">
							<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
								<div className="flex justify-between items-center">
									<h3 className="text-lg font-semibold text-gray-900 font-primary">
										Are you sure?
									</h3>
									<button
										onClick={onClose}
										className="text-gray-400 hover:text-gray-500">
										<FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
									</button>
								</div>
								<div className="mt-4">
									<p className="text-sm text-gray-500 font-secondary">
										You are about to log out of the system and you'll need to
										log in again next time. Are you sure you want to continue?
									</p>
								</div>
							</div>
						</div>
					</div>

					{/* Footer */}
					<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
						{/* Logout Button */}
						<Link
							to="/logout"
							className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-darkRedLink text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm font-secondary"
							onClick={onConfirm}>
							Yes, Logout
						</Link>

						{/* Cancel Button */}
						<button
							type="button"
							className="mt-3 sm:mt-0 sm:mr-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor sm:w-auto sm:text-sm font-secondary"
							onClick={onClose}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Sidebar = ({
	isCollapsed,
	setIsCollapsed,
	sidebarOpen,
	setSidebarOpen,
	currentUser,
	onLogout,
}) => {
	const [expandedItems, setExpandedItems] = useState({});
	const location = useLocation();
	const [showLogoutWarning, setShowLogoutWarning] = useState(false);

	const navigationItems = getNavigationItems(currentUser?.role);

	const toggleSubItems = (itemName) => {
		setExpandedItems((prev) => ({
			...prev,
			[itemName]: !prev[itemName],
		}));
	};

	const isLinkActive = (link) => {
		return location.pathname === link;
	};

	const ProfileSection = memo(({currentUser, isCollapsed}) => {
		// Use the account query hook
		const {data: accountData} = useAccountQuery();

		// If no account data yet, use currentUser as fallback
		const userData = accountData || currentUser;

		return (
			<div
				className={`p-4 ${!isCollapsed ? "border-b" : ""} border-gray-700/50`}>
				<div className="flex items-center space-x-3">
					<Link
						to={userData?.role <= 3 ? "/admin/dashboard" : "/dashboard"}
						className="relative">
						<div className="h-auto w-10 rounded-lg flex items-center justify-center shadow-lg">
							<img
								src={
									userData?.avatarObjectUrl ||
									userData?.avatarUrl ||
									"/logo.svg"
								}
								alt="Logo"
								className="h-10 w-10 object-cover"
							/>
						</div>
						{/* Online Status Indicator */}
						<span className="absolute -bottom-0.5 -right-0.5 h-3 w-3 rounded-full border-2 border-black bg-green-500"></span>
					</Link>

					{!isCollapsed && (
						<div className="flex-1 min-w-0">
							<h2 className="text-white font-medium truncate text-sm">
								{userData?.name || "User"}
							</h2>
							<p className="text-xs text-gray-400 truncate">
								{userData?.email || "user@example.com"}
							</p>
						</div>
					)}
				</div>
			</div>
		);
	});

	const renderNavigationItem = (item, isSection = false) => {
		const hasSubItems = item.subItems && item.subItems.length > 0;
		const isExpanded = expandedItems[item.name];
		const isActive = isLinkActive(item.link);

		const WrapperComponent = item.link ? Link : "div";

		return (
			<div
				key={item.name}
				className={`w-full ${!isCollapsed && isSection ? "mb-6" : ""}`}>
				{!isCollapsed && isSection && (
					<p className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider mb-2">
						{item.name}
					</p>
				)}
				{item.items ? (
					<div className={`${isCollapsed ? "" : "space-y-1"}`}>
						{item.items.map((subItem) => renderNavigationItem(subItem))}
					</div>
				) : (
					<>
						<WrapperComponent
							to={item.link}
							className={`w-full flex items-center ${
								isCollapsed ? "justify-center px-2" : "justify-between px-4"
							} py-3 rounded-lg transition-colors duration-200 cursor-pointer
				  ${item.color ? item.color : ""}
				  ${
						isActive
							? "bg-main text-white"
							: "text-gray-400 hover:bg-main hover:text-white"
					}`}
							onClick={(e) => {
								if (item.external) {
									e.preventDefault();
									window.open(item.link, "_blank", "noopener,noreferrer");
								} else if (item.onClick) {
									e.preventDefault();
									setShowLogoutWarning(true);
								}
							}}>
							<div className="flex items-center justify-center">
								<FontAwesomeIcon
									icon={item.icon}
									className={`h-5 w-5 ${!isCollapsed && "mr-3"}`}
								/>
								{!isCollapsed && (
									<span className="flex items-center">
										{item.name}
										{item.badge && (
											<span className="ml-2 px-2 py-0.5 text-xs font-medium bg-green-100 text-green-800 rounded">
												{item.badge}
											</span>
										)}
									</span>
								)}
							</div>
							{!isCollapsed && hasSubItems && (
								<button
									onClick={(e) => {
										e.preventDefault(); // Prevent link navigation when clicking icon
										toggleSubItems(item.name);
									}}
									className="ml-auto rounded-md transition-colors duration-200">
									<FontAwesomeIcon
										icon={isExpanded ? faChevronDown : faChevronRight}
										className="h-3.5 w-3.5 text-lightShade group-hover:text-white"
									/>
								</button>
							)}
						</WrapperComponent>

						{/* Render SubItems */}
						{hasSubItems && isExpanded && !isCollapsed && (
							<div className="ml-4 mt-2 space-y-1">
								{item.subItems.map((subItem) => (
									<Link
										key={subItem.name}
										to={subItem.link}
										className={`flex items-center px-4 py-2 rounded-lg transition-colors duration-200 ${
											isLinkActive(subItem.link)
												? "bg-main text-white"
												: "text-gray-400 hover:bg-gray-800/50 hover:text-white"
										}`}>
										<FontAwesomeIcon
											icon={subItem.icon}
											className="h-4 w-4 mr-3"
										/>
										<span className="text-sm">{subItem.name}</span>
									</Link>
								))}
							</div>
						)}
					</>
				)}
			</div>
		);
	};

	// Update the Sidebar component's return statement
	return (
		<>
			<div>
				<div
					className={`
		  fixed inset-y-0 left-0 z-50 
		  bg-gradient-to-b from-gray-900 to-black
		  border-r border-gray-800/50
		  transition-all duration-300 ease-in-out
		  ${isCollapsed ? "w-0 md:w-16" : "w-64"} 
		  ${sidebarOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"}
		`}>
					<div className="flex flex-col h-full">
						{/* Profile Section */}
						<ProfileSection
							currentUser={currentUser}
							isCollapsed={isCollapsed}
						/>

						{/* Collapse Button */}
						<button
							onClick={() => setIsCollapsed(!isCollapsed)}
							className={`
								absolute ${!isCollapsed ? "left-64" : "left-16"}
								animate-pulse top-5 p-1.5 rounded-lg
								text-gray-400 hover:text-white bg-gray-800
								transition-colors duration-700 md:block hidden
							  `}>
							<FontAwesomeIcon
								icon={isCollapsed ? faChevronRight : faChevronLeft}
								className="h-4 w-4"
							/>
						</button>

						{/* Navigation */}
						<nav
							className={`flex-1 px-2 py-4 ${
								isCollapsed ? "space-y-1" : "space-y-4"
							} overflow-y-auto scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-transparent`}>
							{navigationItems?.map((item) =>
								renderNavigationItem(item, item.type === "section")
							)}
						</nav>

						<div className="p-2 border-t border-gray-700/50">
							<button
								onClick={() => setShowLogoutWarning(true)}
								className={`w-full flex items-center ${
									isCollapsed ? "justify-center" : "justify-between"
								} px-4 py-3 rounded-lg transition-colors duration-200
        text-red-400 hover:bg-red-500/10 hover:text-red-300`}>
								<div className="flex items-center">
									<FontAwesomeIcon
										icon={faSignOut}
										className={`h-5 w-5 ${!isCollapsed && "mr-3"}`}
									/>
									{!isCollapsed && <span>Logout</span>}
								</div>
							</button>
						</div>
					</div>
				</div>

				{/* Mobile Close Button */}
				{sidebarOpen && (
					<button
						onClick={() => setSidebarOpen(!sidebarOpen)}
						className="
			  fixed top-5 left-56 z-50 p-1.5 rounded-lg
			  text-gray-400 hover:text-white hover:bg-gray-800/50
			  transition-colors duration-200 lg:hidden block
			">
						<FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
					</button>
				)}
			</div>

			<LogoutModal
				isOpen={showLogoutWarning}
				onClose={() => setShowLogoutWarning(false)}
				onConfirm={() => {
					setShowLogoutWarning(false);
					onLogout();
				}}
			/>
		</>
	);
};

export default Sidebar;

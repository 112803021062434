import React, { useEffect } from "react";
import DragSortableList from "./dragsortableList";
import FormSelectField from "./FormSelectField";
import FormInputField from "./FormInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply, faXmark } from "@fortawesome/free-solid-svg-icons";
import { RESTPERIOD_OPTIONS, TARGET_TYPE_MAP, TARGET_TYPE_OPTIONS } from "../../Constants/FieldOptions";

const DragSortListForSelectedWorkouts = ({
  selectedWorkouts,
  onRemove,
  onSortChange,
  handleInputChange,
}) => {
  useEffect(() => {
    return () => {};
  }, [selectedWorkouts]);

  const renderWorkoutContent = (exercise) => {
    return (
      <>
        <div className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200">
          <div className="flex-1 space-y-4">
            {/* Exercise Header Section */}
            <div className="flex items-center space-x-4">
              {exercise.thumbnailUrl ||
              (exercise.excercise && exercise.excercise?.thumbnailUrl) ? (
                <div className="flex items-center">
                  <div className="relative">
                    <img
                      src={
                        exercise.thumbnailUrl ||
                        (exercise.excercise && exercise.excercise?.thumbnailUrl)
                      }
                      alt="Exercise Thumbnail"
                      className="w-16 h-16 rounded-lg object-cover shadow-sm hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-lg"></div>
                  </div>
                  <span className="ml-4 font-semibold text-lg text-gray-800">{exercise.name}</span>
                </div>
              ) : exercise.isRest ? (
                <div className="flex items-center space-x-2">
                  <div className="bg-primary-light/10 p-3 rounded-lg">
                    <span className="text-lg font-bold text-primary">REST</span>
                  </div>
                </div>
              ) : (
                <span className="text-lg font-semibold text-gray-800">{exercise.name}</span>
              )}
            </div>

            {/* Exercise Details Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {!exercise.isRest && (
                <div className="bg-white p-3 rounded-lg shadow-sm hover:shadow transition-shadow duration-250">
                  <FormInputField
                    label={"Reps"}
                    type="number"
                    value={exercise.reps}
                    onChange={(e) => handleInputChange(e, exercise.id, "reps")}
                    placeholder="Reps"
                  />
                </div>
              )}
              
              <div className="bg-white p-3 rounded-lg shadow-sm hover:shadow transition-shadow duration-250">
                <FormSelectField
                  label={"Rest Period"}
                  options={RESTPERIOD_OPTIONS}
                  value={exercise.restPeriod}
                  onChange={(e) => handleInputChange(e, exercise.id, "restPeriod")}
                  placeholder="Rest"
                />
              </div>

              {!exercise.isRest && (
                <>
                  <div className="bg-white p-3 rounded-lg shadow-sm hover:shadow transition-shadow duration-250">
                    <FormSelectField
                      label={"Target Type"}
                      placeholder={"Target type"}
                      options={TARGET_TYPE_OPTIONS}
                      value={exercise.targetType}
                      onChange={(e) => handleInputChange(e, exercise.id, "targetType")}
                    />
                  </div>

                  {TARGET_TYPE_MAP[exercise.targetType] === "Time" && (
                    <div className="bg-white p-3 rounded-lg shadow-sm hover:shadow transition-shadow duration-250">
                      <div className="flex items-center space-x-2">
                        <FormInputField
                          label={"Target Time"}
                          type="number"
                          value={exercise.targetTime}
                          onChange={(e) => handleInputChange(e, exercise.id, "targetTime")}
                          placeholder="Time"
                        />
                        <span className="text-sm text-gray-500 self-end mb-2">Sec</span>
                      </div>
                    </div>
                  ) }

                  {TARGET_TYPE_MAP[exercise.targetType] === "Text" && (
                    <div className="bg-white p-3 rounded-lg shadow-sm hover:shadow transition-shadow duration-250">
                      <FormInputField
                      label={"Target"}
                      maxWidth={"150px"}
                      type="text"
                      value={exercise.targetText}
                      onChange={(e) => handleInputChange(e, exercise.id, "targetText")}
                      placeholder="Target Text"
                    />
                  </div>
              )}
              </>
              )}
            </div>
          </div>

          {/* Remove Button */}
          <button 
            className="ml-4 p-2 rounded-full hover:bg-red-50 group transition-all duration-300"
            onClick={() => onRemove(exercise)}
          >
            <FontAwesomeIcon 
              className="text-red-500 group-hover:text-red-600 transform group-hover:scale-110 transition-all duration-300" 
              icon={faXmark} 
              size="lg"
            />
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="space-y-4 max-h-[410px] overflow-y-auto">
      <DragSortableList
        items={selectedWorkouts.map((exercise) => ({
          ...exercise,
          content: renderWorkoutContent(exercise),
        }))}
        onSortChange={onSortChange}
      />
    </div>
  );
};

export default DragSortListForSelectedWorkouts;

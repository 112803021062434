import {useState, useEffect, useMemo, useCallback} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faGauge,
	faPencil,
	faVideo,
	faEye,
	faDumbbell,
	faClock,
	faUser,
	faHourglass,
	faPlay,
	faForward,
	faList,
	faRotateLeft,
	faCheckCircle,
	faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	currentUserState,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import {deleteWorkoutAPI, getWorkoutDetailAPI} from "../../../API/workout";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";
import VideoPlayer, {VIDEO_LAYOUTS} from "../../Reusable/VideoPlayer";
import Timer from "../../Reusable/Timer";
function MemberWorkoutDetail() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedWorkoutForDeletion, setSelectedWorkoutForDeletion] =
		useState(null);
	const [isResting, setIsResting] = useState(false);
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
	const [restDuration, setRestDuration] = useState(0);
	const [isWorkoutComplete, setIsWorkoutComplete] = useState(false);
	const [completedVideos, setCompletedVideos] = useState(new Set());

	// Transform API data into video sequence
	const videoSequence = useMemo(() => {
		if (!datum?.workoutExercises) return [];
		return datum.workoutExercises.map((workoutExercise) => ({
			id: workoutExercise.id,
			name: workoutExercise?.exerciseName,
			videoType: workoutExercise?.isRest
				? null
				: workoutExercise?.excercise?.videoType,
			videoUrl: workoutExercise?.isRest
				? null
				: workoutExercise?.excercise?.videoUrl,
			thumbnailUrl: workoutExercise?.isRest
				? null
				: workoutExercise?.excercise?.thumbnailObjectUrl,
			isRest: workoutExercise?.isRest || false,
			restPeriodInSecs: workoutExercise.restPeriodInSecs || 60,
			description: workoutExercise?.isRest
				? null
				: workoutExercise?.excercise?.description,
			sets: workoutExercise?.sets || 1,
			orderNumber: workoutExercise?.orderNumber || 1,
		}));
	}, [datum]);

	//Check if the current user is the owner of the workout
	const isOwner = currentUser.id === datum.userId;

	// Fixed handleVideoComplete with proper state batching
	const handleVideoComplete = useCallback(() => {
		const currentVideo = videoSequence[currentVideoIndex];

		// Mark current video as completed
		setCompletedVideos((prev) => new Set([...prev, currentVideo.id]));

		// Check if next exercise is a rest period
		const nextVideo = videoSequence[currentVideoIndex + 1];

		if (nextVideo?.isRest) {
			setIsResting(true);
			setRestDuration(nextVideo.restPeriodInSecs);
			setCurrentVideoIndex((prev) => prev + 1);
		} else {
			setCurrentVideoIndex((prev) => {
				const nextIndex = prev + 1;
				if (nextIndex < videoSequence.length) {
					return nextIndex;
				}
				setIsWorkoutComplete(true);
				return prev;
			});
		}
	}, [currentVideoIndex, videoSequence]);

	// Enhanced rest completion handler
	const handleRestComplete = useCallback(() => {
		setIsResting(false);

		// Move to next exercise if available
		const nextIndex = currentVideoIndex + 1;
		if (nextIndex < videoSequence.length) {
			setCurrentVideoIndex(nextIndex);
		}
	}, [currentVideoIndex, videoSequence.length]);

	const getProgressPercentage = () => {
		if (isWorkoutComplete) return 100;
		if (videoSequence.length === 0) return 0;
		return Math.round((completedVideos.size / videoSequence.length) * 100);
	};

	const renderCurrentContent = () => {
		const currentVideo = videoSequence[currentVideoIndex];

		if (!currentVideo) return null;

		if (currentVideo.isRest) {
			return (
				<div className="p-8 text-center relative overflow-hidden">
					<div className="absolute inset-0 opacity-5">
						{/* Pattern background remains the same */}
					</div>
					<h2 className="text-2xl font-bold text-gray-900 mb-4">Rest Period</h2>
					<Timer
						key={`rest-${currentVideoIndex}`}
						onComplete={handleRestComplete}
						initialTime={currentVideo.restPeriodInSecs}
						autoStart={true}
						progressBar={true}
						className="max-w-md mx-auto"
						showControls={true}
					/>

					<button
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setIsResting(false);

							// Check if this is the last rest period
							const nextIndex = currentVideoIndex + 1;
							if (nextIndex >= videoSequence.length) {
								// This was the last rest period, complete the workout
								setIsWorkoutComplete(true);
							} else {
								// Move to next exercise
								setCurrentVideoIndex(nextIndex);
							}
						}}
						className="mt-8 inline-flex items-center px-6 py-3 bg-gray-100 rounded-xl text-gray-700 font-medium hover:bg-gray-200 transition-all duration-300"
						style={{
							position: "relative",
							zIndex: 10,
							pointerEvents: "auto",
						}}>
						<FontAwesomeIcon icon={faForward} className="mr-2" />
						Skip Rest Period
					</button>
				</div>
			);
		}

		return (
			<div className="p-4">
				<h1 className="text-2xl mx-auto text-center font-bold text-gray-900 mb-4">
					{currentVideo?.name}
					{currentVideo?.sets > 1 && (
						<span className="ml-2 text-sm text-gray-500">
							({currentVideo?.sets} sets)
						</span>
					)}
				</h1>
				<VideoPlayer
					key={`video-${currentVideoIndex}`}
					videoType={currentVideo?.videoType}
					videoUrl={currentVideo?.videoUrl}
					thumbnailUrl={currentVideo?.thumbnailUrl}
					layout={VIDEO_LAYOUTS.FULL}
					onEnded={handleVideoComplete}
					autoplay={true}
					showActions={false}
					showDescription={false}
					showRelated={false}
				/>
			</div>
		);
	};

	const onDeleteConfirmButtonClick = () => {
		deleteWorkoutAPI(id, onDeleteSuccess, onDeleteError, onDeleteDone);
		setSelectedWorkoutForDeletion(null);
	};

	// API Success/Error handlers
	function onVideoCollectionDetailSuccess(response) {
		setDatum(response);
	}

	function onVideoCollectionDetailError(apiErr) {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		setFetching(false);
	}

	function onDeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("Workout deleted successfully");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/workouts");
	}

	function onDeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed to delete workout");
		setTimeout(() => setTopAlertMessage(""), 2000);
		Scroll.animateScroll.scrollToTop();
	}

	function onDeleteDone() {
		setFetching(false);
	}

	// Breadcrumb configuration
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Workouts",
			icon: faDumbbell,
			link: "/workouts",
		},
		{
			label: "Detail",
			icon: faEye,
		},
	];

	// Effects
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			setFetching(true);
			getWorkoutDetailAPI(
				id,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={selectedWorkoutForDeletion !== null}
				onClose={() => setSelectedWorkoutForDeletion(null)}
				header="Confirm Deletion">
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to delete this workout? This action cannot be
						undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setSelectedWorkoutForDeletion(null)}
							className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                       transition-colors duration-300">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 
                       transition-colors duration-300">
							Delete
						</button>
					</div>
				</div>
			</Modal>

			<div className="mx-auto">
				{isFetching ? (
					<PageLoadingContent displayMessage="Loading workout details..." />
				) : (
					<>
						{/* Enhanced Hero Section */}
						<div className="bg-gradient-to-r from-primary via-primary/90 to-primary/80 rounded-3xl p-4 mb-8 shadow-xl">
							<div className="flex flex-col sm:flex-row items-center justify-between gap-6">
								<div className="flex items-center space-x-6">
									<div className="w-16 h-16 bg-white/95 backdrop-blur-sm shadow-lg rounded-2xl flex items-center justify-center transform rotate-3 hover:rotate-6 transition-all duration-300">
										<FontAwesomeIcon
											icon={faDumbbell}
											className="text-primary text-2xl"
										/>
									</div>
									<div>
										<h1 className="text-3xl font-bold text-white">
											{datum?.name}
										</h1>
										<div className="flex items-center mt-2 text-white/80">
											<FontAwesomeIcon icon={faUser} className="mr-2" />
											<span>{datum.createdByUserName}</span>
											<span className="mx-2">•</span>
											<FontAwesomeIcon icon={faVideo} className="mr-2" />
											<span>{videoSequence.length} exercises</span>
										</div>
									</div>
								</div>

								<div className="flex gap-4">
									{/* Restart Button */}
									<button
										onClick={() => {
											setCurrentVideoIndex(0);
											setIsResting(false);
										}}
										className="inline-flex items-center px-4 py-2 bg-white/90 text-primary rounded-xl font-medium shadow-lg hover:bg-white transition-all duration-300">
										<FontAwesomeIcon icon={faRotateLeft} className="mr-2" />
										Restart
									</button>

									{isOwner && (
										<>
											<Link
												to={`/workouts/${id}/update`}
												className="inline-flex items-center px-4 py-2 bg-white/90 text-primary rounded-xl font-medium shadow-lg hover:bg-white transition-all duration-300">
												<FontAwesomeIcon icon={faPencil} className="mr-2" />
												Edit
											</Link>
											<button
												onClick={() => setSelectedWorkoutForDeletion(datum)}
												className="inline-flex items-center px-4 py-2 bg-red-500 text-white rounded-xl font-medium shadow-lg hover:bg-red-600 transition-all duration-300">
												<FontAwesomeIcon icon={faTrash} className="mr-2" />
												Delete
											</button>
										</>
									)}
								</div>
							</div>
						</div>

						{/* Progress Stats Bar */}
						<div className="grid grid-cols-2 sm:grid-cols-2 gap-4 mb-8">
							<div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
								<div className="flex items-center space-x-3">
									<div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center">
										<FontAwesomeIcon icon={faPlay} className="text-blue-500" />
									</div>
									<div>
										<p className="text-sm text-gray-500">Current Exercise</p>
										<h3 className="text-xl font-bold text-gray-900">
											{currentVideoIndex + 1} of {videoSequence.length}
										</h3>
									</div>
								</div>
							</div>

							<div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
								<div className="flex items-center space-x-3">
									<div className="w-12 h-12 bg-green-50 rounded-xl flex items-center justify-center">
										<FontAwesomeIcon
											icon={faCheckCircle}
											className="text-green-500"
										/>
									</div>
									<div>
										<p className="text-sm text-gray-500">Progress</p>
										<h3 className="text-xl font-bold text-gray-900">
											{getProgressPercentage()}% Complete
										</h3>
									</div>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
							{/* Main Content */}
							<div className="lg:col-span-2 space-y-6">
								{/* Video/Timer Container */}
								<div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100">
									{renderCurrentContent()}
								</div>

								{/* Progress Bar */}
								<div className="bg-white rounded-2xl shadow-sm p-6 border border-gray-100">
									<div className="flex items-center justify-between mb-4">
										<div className="flex items-center space-x-2">
											{videoSequence.map((_, idx) => (
												<div
													key={idx}
													className={`w-3 h-3 rounded-full transition-all duration-300 ${
														idx === currentVideoIndex
															? "bg-primary scale-125"
															: idx < currentVideoIndex
															? "bg-primary/40"
															: "bg-gray-200"
													}`}
												/>
											))}
										</div>
										{!isResting && (
											<span className="text-sm font-medium text-gray-500">
												{videoSequence[currentVideoIndex]?.name}
											</span>
										)}
									</div>
									<div className="relative">
										<div className="w-full bg-gray-100 rounded-full h-3">
											<div
												className="bg-primary h-3 rounded-full transition-all duration-500 ease-out"
												style={{
													width: `${
														((currentVideoIndex + 1) / videoSequence.length) *
														100
													}%`,
													boxShadow: "0 0 10px rgba(var(--primary-rgb), 0.3)",
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							{/* Enhanced Sidebar */}
							<div className="lg:col-span-1">
								<div className="bg-white rounded-2xl shadow-lg p-6 border border-gray-100 sticky top-6">
									<h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center">
										<div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center mr-3">
											<FontAwesomeIcon icon={faList} className="text-primary" />
										</div>
										Workout Sequence
									</h3>
									<div className="space-y-3">
										{videoSequence.map((video, index) => (
											<div
												key={video.id}
												className={`group p-4 rounded-xl flex items-center space-x-4 transition-all duration-300 ${
													index === currentVideoIndex
														? "bg-primary/10 border-l-4 border-primary"
														: "bg-gray-50 hover:bg-gray-100 border-l-4 border-transparent hover:border-primary/50"
												}`}>
												<div
													className={`w-10 h-10 rounded-xl flex items-center justify-center transition-all duration-300 ${
														index === currentVideoIndex
															? "bg-primary text-white"
															: "bg-white text-gray-600 group-hover:bg-primary/10"
													}`}>
													{index + 1}
												</div>
												<div className="flex-1 min-w-0">
													{video?.isRest ? (
														<>
															<div className="flex items-center gap-2">
																<h4 className="font-medium text-primary truncate flex items-center">
																	<FontAwesomeIcon
																		icon={faClock}
																		className="mr-2 text-primary/70"
																	/>
																	Rest Period
																</h4>
																<span className="px-2 py-0.5 bg-primary/10 rounded-full text-xs font-medium text-primary">
																	{video.restPeriodInSecs}s
																</span>
															</div>
															<div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
																<div className="flex items-center gap-1">
																	<FontAwesomeIcon
																		icon={faHourglass}
																		className="text-xs"
																	/>
																	<span>Recovery</span>
																</div>
																<span className="w-1 h-1 rounded-full bg-gray-300" />
																{/* <div className="flex items-center gap-1">
																	<FontAwesomeIcon
																		icon={faRotateLeft}
																		className="text-xs"
																	/>
																	{video.orderNumber ===
																	videoSequence.length ? (
																		<span className="text-primary-600 font-medium">
																			Workout Complete
																		</span>
																	) : (
																		<span>
																			Next Exercise:{" "}
																			{videoSequence[video.orderNumber]?.name}
																		</span>
																	)}
																</div> */}
															</div>
														</>
													) : (
														<>
															<h4 className="font-medium text-gray-900 truncate">
																{video?.name}
															</h4>
															{video?.sets > 1 && (
																<p className="text-sm text-gray-500 flex items-center mt-1">
																	<FontAwesomeIcon
																		icon={faRepeat}
																		className="mr-1"
																	/>
																	{video.sets} sets
																</p>
															)}
														</>
													)}
												</div>
												{index === currentVideoIndex && (
													<span className="flex-shrink-0 flex items-center text-primary text-sm font-medium bg-primary/10 px-3 py-1 rounded-full">
														<FontAwesomeIcon icon={faPlay} className="mr-1" />
														Current
													</span>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</Layout>
	);
}

export default MemberWorkoutDetail;

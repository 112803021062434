import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FormDateTimeField({
  label,
  name,
  placeholder,
  value,
  type = "text",
  errorText,
  validationText,
  helpText,
  onChange,
  maxWidth,
  disabled = false,
  withPortal = true,
  filterDate = null,
}) {
  let dt = null;
  if (value !== undefined && value !== null && value !== "") {
    const valueMilliseconds = Date.parse(value);
    dt = new Date(valueMilliseconds);
  }

  const baseInputClasses = "w-full rounded-lg border px-4 py-2 text-gray-700 focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary/25 transition-colors duration-DEFAULT";
  const inputClasses = errorText
    ? `${baseInputClasses} border-red-500 bg-red-50`
    : `${baseInputClasses} border-gray-200 bg-white`;

  return (
    <div className="mb-4 animate-fade-in">
      <label className="mb-2 block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative" style={{ maxWidth: maxWidth || "100%" }}>
        <DatePicker
          className={inputClasses}
          selected={dt}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          withPortal={withPortal}
          portalId={name}
          filterDate={filterDate}
          autoComplete="off"
          onChange={(date) => onChange(date)}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          timeFormat="h:mm aa"
          timeIntervals={15}
          timeCaption="Time"
          wrapperClassName="w-full"
          popperClassName="shadow-lg rounded-lg border border-gray-200"
          customInput={
            <input
              className={inputClasses}
              disabled={disabled}
            />
          }
        >
          {errorText && (
            <div className="mt-1 text-sm text-red-500">{errorText}</div>
          )}
        </DatePicker>
      </div>
      {errorText && (
        <p className="mt-1 text-sm text-red-500 animate-slideUp">
          {errorText}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">
          {helpText}
        </p>
      )}
    </div>
  );
}

export default FormDateTimeField;

import React, {useState} from "react";
import VideoPlayer, {VIDEO_LAYOUTS} from "./VideoPlayer";
import {
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_VIMEO,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
} from "../../Constants/App";
import DraggableItem from "./dragable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faClock} from "@fortawesome/free-solid-svg-icons";

const exerciseItemJSX = (exercise, index, showDescription, showIndex) => {
	// Split description into instructions and metadata sections
	const sections = exercise.description?.split("\n").filter(Boolean) || [];
	const metadataStartIndex = sections.findIndex((line) => line === "");
	const instructions =
		metadataStartIndex !== -1
			? sections.slice(0, metadataStartIndex)
			: sections;
	const metadata =
		metadataStartIndex !== -1 ? sections.slice(metadataStartIndex + 1) : [];

	return (
		<div
			key={index}
			className="mb-6 rounded-xl bg-white p-6 shadow-md border border-gray-200/80 transition-all duration-300 hover:shadow-lg">
			{/* Exercise Title */}
			<h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
				{showIndex && (
					<span className="flex items-center justify-center w-6 h-6 rounded-full bg-primary/10 text-primary text-sm">
						{index + 1}
					</span>
				)}
				<span className="flex-1">{exercise.name}</span>
			</h2>

			{/* Metadata Section */}
			{metadata.length > 0 && (
				<div className="mb-4 flex flex-wrap gap-2">
					{metadata.map((item, idx) => (
						<span
							key={idx}
							className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-700">
							{item}
						</span>
					))}
				</div>
			)}

			{/* Instructions Section */}
			{instructions.length > 0 && showDescription && (
				<div className="space-y-4 animate-fadeIn">
					<h4 className="font-medium text-gray-900">Instructions:</h4>
					<ol className="space-y-3">
						{instructions.map((text, idx) => (
							<li
								key={idx}
								className="flex gap-3 text-gray-600 hover:text-gray-900 transition-colors duration-200">
								<span className="flex-none flex items-center justify-center w-6 h-6 rounded-full bg-primary/5 text-primary text-sm">
									{idx + 1}
								</span>
								<span className="flex-1">{text}</span>
							</li>
						))}
					</ol>
				</div>
			)}

			{/* Video Section */}
			{(exercise.videoUrl || exercise.videoObjectUrl) && (
				<div className="mt-6 overflow-hidden">
					<VideoPlayer
						videoType={exercise.videoType}
						videoObjectUrl={exercise.videoObjectUrl}
						videoUrl={exercise.videoUrl}
						thumbnailUrl={exercise.thumbnailObjectUrl || exercise.thumbnailUrl}
						name={exercise.name}
						description={instructions.join("\n")}
						layout={VIDEO_LAYOUTS.MINIMAL}
						showActions={false}
						showDescription={false}
						metadata={[
							{
								icon: faPlay,
								label: "Exercise Type",
								value: exercise.movementType
									? `Type ${exercise.movementType}`
									: "General",
							},
							...(exercise.category
								? [
										{
											icon: faClock,
											label: "Category",
											value: `Category ${exercise.category}`,
										},
								  ]
								: []),
						]}
					/>
				</div>
			)}
		</div>
	);
};

function ExerciseDisplay({
	exercises,
	label,
	isdraggable,
	wrapperclass,
	onAdd,
	showIndex = true,
	showDescription = true,
}) {
	return (
		<div className={`space-y-6 ${wrapperclass}`}>
			{label && (
				<label className="block text-xl font-semibold text-gray-900">
					{label}
				</label>
			)}

			<div className="space-y-4">
				{exercises.map((exercise, index) =>
					isdraggable ? (
						<DraggableItem
							className="transition-transform duration-200 hover:scale-[1.01]"
							key={index}
							id={exercise.id}
							content={exerciseItemJSX(exercise, index)}
							onAdd={() => onAdd(exercise)}
						/>
					) : (
						exerciseItemJSX(exercise, index, showDescription, showIndex)
					)
				)}
			</div>
		</div>
	);
}

export default ExerciseDisplay;

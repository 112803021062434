import {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {useRecoilState} from "recoil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faEye,
	faTable,
	faSave,
	faCancel,
	faArrowUpRightFromSquare,
	faEdit,
	faBook,
	faLayerGroup,
	faPlus,
  faXmark,
  faDownLeftAndUpRightToCenter,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {getWorkoutListApi} from "../../../API/workout";
import {
	deleteTrainingProgAPI,
	getTrainingProgDetailAPI,
	patchTrainingProgAPI,
} from "../../../API/trainingProgram";
import PhasePanel from "./phasepanel";
import WorkoutDisplay from "../../Reusable/WorkoutsDisplay";
import Modal from "../../Reusable/Modal";
import FormInputField from "../../Reusable/FormInputField";
import Accordion from "../../Reusable/accordion";
import Layout from "../../Menu/Layout";

const EmptyState = ({icon, title, description, action}) => (
	<div className="text-center max-w-md mx-auto">
		<div className="inline-flex items-center justify-center w-12 h-12 rounded-xl bg-gray-100 mb-4">
			<FontAwesomeIcon icon={icon} className="w-6 h-6 text-gray-400" />
		</div>
		<h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
		<p className="text-sm text-gray-500 mb-6">{description}</p>
		{action}
	</div>
);

const StatusCard = ({label, value, icon, statusClass}) => (
	<div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-200 shadow-sm">
		<div className="flex items-start justify-between">
			<div className="flex-1 min-w-0">
				<p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
				<h3 className="text-base sm:text-lg font-semibold text-gray-900 truncate">
					{value}
				</h3>
			</div>
			{icon ? (
				<FontAwesomeIcon icon={icon} className="w-5 h-5 text-gray-400 ml-2" />
			) : (
				<span
					className={`ml-2 px-2.5 sm:px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${statusClass}`}>
					{value}
				</span>
			)}
		</div>
	</div>
);

function AdminTPDetailUpdate() {
	// URL Parameters
	const {uid, id} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [listData, setListData] = useState([]);
	const [selectedWorkoutForDeletion, setSelectedWorkoutForDeletion] =
		useState(null);
	const [showAddWorkoutModal, setShowAddWorkoutModal] = useState(false);
	const [selectedPhase, setSelectedPhase] = useState(null);
	const [isModified, setIsModified] = useState(false);

	const [selectedWorkoutForRoutine, setselectedWorkoutForRoutine] = useState(
		{}
	);
	const [getSelectedWorkouts, setgetSelectedWorkouts] = useState({});
	// Event handling
	const handleAddWorkoutClick = (phase) => {
		setSelectedPhase(phase);
		setShowAddWorkoutModal(true);
	};

	const handleAddWorkoutModalClose = () => {
		setShowAddWorkoutModal(false);
	};

	const handleInputChange = (index, field, value) => {
		const selectedPhaseId = selectedPhase.id;

		setselectedWorkoutForRoutine((prevState) => {
			const updatedWorkouts = [...(prevState[selectedPhaseId] || [])];

			// Fix: Ensure proper type conversion and validation
			if (field === "week" || field === "day") {
				const numValue = parseInt(value) || 0;
				if (numValue < 0) return prevState; // Prevent negative values
				updatedWorkouts[index] = {
					...updatedWorkouts[index],
					[field]: numValue,
				};
			} else {
				updatedWorkouts[index] = {
					...updatedWorkouts[index],
					[field]: value,
				};
			}

			return {
				...prevState,
				[selectedPhaseId]: updatedWorkouts,
			};
		});

		setIsModified(true);
	};

	// API

	// Detail
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);

			getTrainingProgDetailAPI(
				id,
				onDetailSuccess,
				onDetailError,
				onDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	// Delete
	function handleDeleteConfirmButtonClick() {
		deleteTrainingProgAPI(id, ondeleteSuccess, ondeleteError, onDeleteDone);
		setSelectedWorkoutForDeletion(null);
	}
	// Misc.
	useEffect(() => {
		if (JSON.stringify(datum) !== JSON.stringify({}) && !listData.length) {
			setFetching(true);
			let query = new Map();
			query.set("sort_field", "created");
			query.set("sort_order", -1);
			if (datum.userId) {
				// query.set("user_id", datum.userId);
				query.set("visibility", 1); //admin+personal
			}

			getWorkoutListApi(query, onListSuccess, onListError, onListDone);
		}
		return () => {};
	}, [datum]);

	// Callbacks
	function onDetailSuccess(response) {
		setDatum(response);
		if (response.trainingPhases) {
			const updatedWorkoutForRoutine = {};
			response.trainingPhases.forEach((tp) => {
				// Fix: Properly map training routines with all necessary data
				if (tp.trainingRoutines && tp.trainingRoutines.length) {
					const phaseWorkout = tp.trainingRoutines.map((routine) => ({
						id: routine.workoutId,
						name: routine.workout.name,
						description: routine.workout.description,
						workoutExercises: routine.workout.workoutExercises,
						day: routine.trainingDays?.[0]?.day || 0,
						week: routine.trainingDays?.[0]?.week || 0,
						routineId: routine.id, // Add routine ID for updates
					}));
					updatedWorkoutForRoutine[tp.id] = phaseWorkout;
				} else {
					updatedWorkoutForRoutine[tp.id] = [];
				}
			});
			setselectedWorkoutForRoutine(updatedWorkoutForRoutine);
		}
	}

	function onDetailError(apiErr) {
		setErrors(apiErr);
		scrollToTop();
	}

	function onDetailDone() {
		setFetching(false);
	}

	function ondeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("training program deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setForceURL("/admin/training-program");
	}

	function ondeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		scrollToTop();
	}

	function onDeleteDone() {
		setFetching(false);
	}

	function onListSuccess(resp) {
		setListData(resp.results);
	}

	function onListError(er) {
		setErrors(er);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	function onListDone() {
		setFetching(false);
	}

	const handleSaveButtonClick = () => {
		const phases = [];

		// Fix: Properly format data for API
		for (const phaseId in selectedWorkoutForRoutine) {
			const routines = selectedWorkoutForRoutine[phaseId];
			const phaseRoutines = routines.map((routine) => ({
				workout_id: routine.id,
				routine_day: parseInt(routine.day) || 0,
				routine_week: parseInt(routine.week) || 0,
				order_number: routine.orderNumber || 0,
			}));

			const phaseData = datum.trainingPhases?.find(
				(phase) => phase.id === phaseId
			);

			phases.push({
				phase_id: phaseId,
				phase: phaseData?.phase || 1,
				routines: phaseRoutines,
			});
		}

		setFetching(true);
		patchTrainingProgAPI(id, {phases}, onPatchOK, onPatchError, onDone);
		setIsModified(false);
	};
	function onPatchOK(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("Program updated");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setForceURL("/admin/training-program");
	}

	function onPatchError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed updating");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		scrollToTop();
	}

	function onDone() {
		setFetching(false);
	}

	const handleSaveSelectedWorkouts = () => {
		const selectedPhaseId = selectedPhase.id;
		const newWorkouts = getSelectedWorkouts[selectedPhaseId] || [];

		// Fix: Transform selected workouts to match the required format
		const transformedWorkouts = newWorkouts.map((workout) => ({
			id: workout.id,
			name: workout.name,
			description: workout.description,
			workoutExercises: workout.workout_exercises,
			day: 0, // Default values for new workouts
			week: 0,
		}));

		setselectedWorkoutForRoutine((prevState) => ({
			...prevState,
			[selectedPhaseId]: transformedWorkouts,
		}));

		setIsModified(true);
		setShowAddWorkoutModal(false);
	};

	// Helper function to scroll to the top of the page
	const scrollToTop = () => {
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Training Programs", link: "/admin/training-program", icon: faBook},
		{label: "Update", icon: faEdit},
	];

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50/50">
				<div className="w-full max-w-7xl mx-auto px-3 sm:px-4 md:px-6 lg:px-8 py-3 sm:py-4 md:py-6">
					{/* Header Section */}
					<div className="mb-4 md:mb-6">
						<div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between mb-4 md:mb-6">
							<div className="flex items-center gap-2 sm:gap-3">
								<Link
									to="/admin/training-program"
									className="p-2 text-gray-500 hover:text-primary transition-colors rounded-lg hover:bg-gray-100">
									<FontAwesomeIcon icon={faBook} className="w-4 h-4" />
								</Link>
								<h1 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 truncate">
									Update Training Program
								</h1>
							</div>
							<div className="flex items-center gap-2 sm:gap-3 mt-2 sm:mt-0">
								<button
									onClick={() => setSelectedWorkoutForDeletion(true)}
									className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4 
                    text-sm font-medium text-red-600 bg-white border border-red-200 
                    rounded-lg hover:bg-red-50 hover:border-red-300 transition-all
                    duration-300 shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
									<FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
									<span className="ml-2 hidden sm:inline">Delete Program</span>
								</button>
								{isModified && (
									<button
										onClick={handleSaveButtonClick}
										className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4
                      text-sm font-medium text-white bg-greenShade1 hover:bg-greenShade2 
                      rounded-lg transition-all duration-300 shadow-sm 
                      focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
										<FontAwesomeIcon icon={faSave} className="w-4 h-4" />
										<span className="ml-2 hidden sm:inline">Save Changes</span>
									</button>
								)}
							</div>
						</div>

						{/* Status Cards */}
						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 md:gap-6">
							<StatusCard
								label="Status"
								value={datum.status === 1 ? "Active" : "Draft"}
								statusClass={
									datum.status === 1
										? "bg-green-100 text-green-700"
										: "bg-yellow-100 text-yellow-700"
								}
							/>
							<StatusCard
								label="Visibility"
								value={datum.visibility === 1 ? "Public" : "Private"}
								icon={faEye}
							/>
							<StatusCard
								label="Total Phases"
								value={`${datum.trainingPhases?.length || 0} Phases`}
								icon={faLayerGroup}
							/>
						</div>
					</div>

					{/* Main Content */}
					<div className="space-y-6">
						<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm overflow-hidden">
							<div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200/80 px-6 py-5">
								<div className="flex flex-wrap items-center justify-between gap-4">
									<div className="flex items-center space-x-3">
										<div className="flex items-center justify-center w-10 h-10 rounded-lg bg-primary/10">
											<FontAwesomeIcon
												icon={faLayerGroup}
												className="w-5 h-5 text-primary"
											/>
										</div>
										<div>
											<h2 className="text-lg font-semibold text-gray-900">
												Training Phases
											</h2>
											<p className="text-sm text-gray-500 mt-0.5">
												Manage workouts for each phase
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="p-6">
								<div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
									{/* Phase Panel */}
									<div className="lg:col-span-1">
										<PhasePanel
											phases={datum.trainingPhases}
											onAddWorkout={handleAddWorkoutClick}
											setSelectedPhase={setSelectedPhase}
										/>
									</div>

									{/* Workouts Display */}
									<div className="lg:col-span-3">
										{selectedPhase ? (
											<div className="space-y-4">
												<div className="flex items-center justify-between">
													<div>
														<h3 className="text-lg font-semibold text-gray-900">
															{selectedPhase.name}
														</h3>
														<p className="text-sm text-gray-500">
															{selectedPhase.description}
														</p>
													</div>
													<button
														onClick={() => setShowAddWorkoutModal(true)}
														className="inline-flex items-center px-4 py-2 rounded-lg bg-primary 
                            hover:bg-primary-dark text-white transition-colors duration-200">
														<FontAwesomeIcon
															icon={faPlus}
															className="w-4 h-4 mr-2"
														/>
														Add Workout
													</button>
												</div>

												{selectedWorkoutForRoutine &&
												selectedWorkoutForRoutine[selectedPhase.id]?.length ? (
													<div className="space-y-4">
														{selectedWorkoutForRoutine[selectedPhase.id].map(
															(routine, index) => (
																<Accordion
    key={`routine-${routine.id}-${index}`}
    head={
      <div className="flex items-center justify-between w-full p-4 bg-white rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200 pr-12"> {/* Added pr-12 for chevron space */}
        <div className="flex items-center gap-3">
          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100">
            <span className="text-sm font-medium text-gray-600">{index + 1}</span>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-900">{routine.name}</h4>
            <p className="text-xs text-gray-500">
              Week {routine.week || 0}, Day {routine.day || 0}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3"> {/* Reduced gap */}
          <div className="flex items-center gap-1.5"> {/* Reduced gap */}
            <FontAwesomeIcon icon={faLayerGroup} className="w-3.5 h-3.5 text-gray-400" />
            <span className="text-xs text-gray-500 whitespace-nowrap">{routine.workoutExercises?.length || 0} exercises</span>
          </div>
          <div className="flex items-center gap-1.5"> {/* Reduced gap */}
            <FontAwesomeIcon icon={faClock} className="w-3.5 h-3.5 text-gray-400" />
            <span className="text-xs text-gray-500 whitespace-nowrap">{routine.workoutExercises?.reduce((acc, ex) => acc + (ex.targetTimeInSecs || 0), 0)}s</span>
          </div>
        </div>
      </div>
    }
    content={
      <div className="p-4 space-y-4 bg-gray-50 rounded-b-lg border-x border-b border-gray-200">
        {/* Schedule Controls */}
        <div className="grid grid-cols-2 gap-4">
          <FormInputField
            type="number"
            label="Week"
            value={routine.week || 0}
            onChange={(e) => handleInputChange(index, "week", e.target.value)}
            className="w-full"
            min={0}
          />
          <FormInputField
            type="number"
            label="Day"
            value={routine.day || 0}
            onChange={(e) => handleInputChange(index, "day", e.target.value)}
            className="w-full"
            min={0}
          />
        </div>

        {/* Exercise List */}
        <div className="space-y-2">
          <div className="flex items-center gap-2 mb-3">
            <FontAwesomeIcon icon={faLayerGroup} className="w-4 h-4 text-gray-400" />
            <h5 className="text-sm font-medium text-gray-700">Exercises</h5>
          </div>
          {routine.workoutExercises?.map((exercise, idx) => (
            <div
              key={`exercise-${exercise.id}-${idx}`}
              className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 hover:border-primary/50 transition-all duration-200"
            >
              <div className="flex items-center gap-3">
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-primary/10">
                  <span className="text-xs font-medium text-primary">
                    {idx + 1}
                  </span>
                </div>
                <span className="text-sm font-medium text-gray-900">
                  {exercise.exerciseName}
                </span>
              </div>
              <div className="flex items-center gap-4">
                {exercise.sets && (
                  <div className="flex items-center gap-1.5">
                    <span className="text-xs font-medium text-gray-900">
                      {exercise.sets}
                    </span>
                    <span className="text-xs text-gray-500">sets</span>
                  </div>
                )}
                {exercise.targetTimeInSecs && (
                  <div className="flex items-center gap-1.5">
                    <FontAwesomeIcon icon={faClock} className="w-3.5 h-3.5 text-gray-400" />
                    <span className="text-xs text-gray-500">
                      {exercise.targetTimeInSecs}s
                    </span>
                  </div>
                )}
                {exercise.targetText && (
                  <div className="flex items-center gap-1.5">
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className="w-3.5 h-3.5 text-gray-400" />
                    <span className="text-xs text-gray-500">
                      {exercise.targetText}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    }
  />
))}
													</div>
												) : (
													<EmptyState
														icon={faTable}
														title="No Workouts Added"
														description="Add workouts to this phase to get started"
														action={
															<button
																onClick={() => setShowAddWorkoutModal(true)}
																className="inline-flex items-center px-4 py-2 text-sm font-medium 
                                text-primary bg-primary/5 rounded-lg hover:bg-primary/10 
                                transition-colors duration-200">
																<FontAwesomeIcon
																	icon={faPlus}
																	className="w-4 h-4 mr-2"
																/>
																Add Workout
															</button>
														}
													/>
												)}
											</div>
										) : (
											<EmptyState
												icon={faLayerGroup}
												title="Select a Phase"
												description="Choose a phase from the sidebar to manage its workouts"
											/>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>

					{/* Modals */}
					<Modal
  isOpen={showAddWorkoutModal}
  onClose={handleAddWorkoutModalClose}
  contentLabel="Add Workout Modal"
  className="w-[95%] max-w-lg mx-auto" // Responsive width with max constraint
>
  <div className="bg-white rounded-lg">
    {/* Header - Made responsive */}
    <div className="p-3 sm:p-4 border-b border-gray-200">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <div className="text-orange-600">
            <FontAwesomeIcon icon={faPlus} className="w-4 h-4" />
          </div>
          <div>
            <h2 className="text-base font-semibold text-gray-900">Add Workouts</h2>
            <p className="text-xs sm:text-sm text-gray-500">
              Adding workouts to phase: Week 1 - 4
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 self-end sm:self-auto">
          <button
            onClick={handleAddWorkoutModalClose}
            className="text-xs sm:text-sm text-gray-700 hover:text-gray-900 px-2 py-1"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveSelectedWorkouts}
            className="text-xs sm:text-sm text-white bg-orange-600 rounded px-3 py-1 hover:bg-orange-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>

    {/* Content - Made responsive */}
    <div className="p-3 sm:p-4">
      <div className="space-y-3">
        {listData.map((workout) => (
          <div 
            key={workout.id}
            className="relative border border-gray-200 rounded-lg p-3"
          >
            {/* Workout Header */}
            <div className="flex items-start justify-between gap-2">
              <div className="flex-1 min-w-0"> {/* min-w-0 prevents text overflow */}
                <div className="flex flex-wrap items-center gap-2 mb-1">
                  <h3 className="text-sm font-medium text-gray-900 break-words">
                    {workout.name}
                  </h3>
                  {workout.status === 1 && (
                    <span className="inline-block px-1.5 py-0.5 text-xs text-green-700 bg-green-50 rounded">
                      Active
                    </span>
                  )}
                </div>
                <p className="text-xs text-gray-500 break-words">
                  {workout.description}
                </p>
              </div>
              <div className="flex-shrink-0">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-orange-600 border-gray-300 rounded focus:ring-orange-500"
                  checked={getSelectedWorkouts[selectedPhase?.id]?.some(w => w.id === workout.id)}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setgetSelectedWorkouts(prev => ({
                      ...prev,
                      [selectedPhase.id]: isChecked
                        ? [...(prev[selectedPhase.id] || []), workout]
                        : (prev[selectedPhase.id] || []).filter(w => w.id !== workout.id)
                    }));
                  }}
                />
              </div>
            </div>

            {/* Exercise List */}
            {workout.workoutExercises?.length > 0 && (
              <div className="mt-2">
                <div className="flex items-center gap-1.5 mb-2">
                  <FontAwesomeIcon icon={faLayerGroup} className="w-3.5 h-3.5 text-gray-400" />
                  <span className="text-xs text-gray-600">
                    Exercises ({workout.workoutExercises.length})
                  </span>
                </div>
                <div className="space-y-2">
                  {workout.workoutExercises.map((exercise, index) => (
                    <div 
                      key={exercise.id}
                      className="flex flex-wrap sm:flex-nowrap items-center justify-between py-1.5 gap-2"
                    >
                      <div className="flex items-center gap-2 min-w-0"> {/* min-w-0 prevents text overflow */}
                        <span className="text-xs text-gray-500 flex-shrink-0">
                          {index + 1}
                        </span>
                        <span className="text-xs text-gray-900 truncate">
                          {exercise.exerciseName}
                        </span>
                      </div>
                      <div className="flex items-center gap-3 ml-6 flex-shrink-0">
                        {exercise.sets && (
                          <span className="text-xs text-gray-500">
                            {exercise.sets} sets
                          </span>
                        )}
                        {exercise.targetTimeInSecs && (
                          <span className="text-xs text-gray-500">
                            {exercise.targetTimeInSecs}s
                          </span>
                        )}
                        {exercise.targetText && (
                          <span className="text-xs text-gray-500">
                            {exercise.targetText}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
</Modal>

					<Modal
						isOpen={selectedWorkoutForDeletion !== null}
						onClose={() => setSelectedWorkoutForDeletion(null)}
						header="Confirm Deletion">
						<div className="space-y-4">
							<p className="text-gray-600">
								Are you sure you want to delete this training program? This
								action cannot be undone.
							</p>
							<div className="flex justify-end space-x-3">
								<button
									onClick={() => setSelectedWorkoutForDeletion(null)}
									className="px-4 py-2 text-sm font-medium text-gray-700 bg-white 
                         border border-gray-300 rounded-lg hover:bg-gray-50 
                         transition-colors duration-300">
									Cancel
								</button>
								<button
									onClick={handleDeleteConfirmButtonClick}
									className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                         rounded-lg hover:bg-red-700 transition-colors duration-300">
									Delete
								</button>
							</div>
						</div>
					</Modal>

					{/* Error Display */}
					<FormErrorBox errors={errors} />
				</div>
			</div>
		</Layout>
	);
}

export default AdminTPDetailUpdate;

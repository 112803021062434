import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faVideo,
	faDumbbell,
	faGauge,
	faArrowRight,
	faUsers,
	faChalkboardTeacher,
	faChartLine,
	faLightbulb,
	faTrophy,
	faDollarSign,
	faPlus,
	faRefresh,
	faFilter,
	faMedal,
	faLayerGroup,
	faListCheck,
	faClock,
	faCalendarAlt,
	faCheckCircle,
	faExclamationCircle,
	faFire,
	faArrowUp,
	faArrowDown,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../AppState";
import Layout from "../Menu/Layout";
import Modal from "../Reusable/Modal";

// Static data
const STATIC_DASHBOARD_DATA = {
	upcomingClasses: [
		{
			title: "MMA Fundamentals",
			time: "10:00 AM",
			trainer: "Mike Thompson",
			attendees: 12,
			type: "MMA",
			status: "In Progress",
		},
		{
			title: "Advanced Boxing",
			time: "2:00 PM",
			trainer: "Sarah Connor",
			attendees: 8,
			type: "Boxing",
			status: "Upcoming",
		},
		{
			title: "Kickboxing Basics",
			time: "4:30 PM",
			trainer: "Alex Rivera",
			attendees: 15,
			type: "Kickboxing",
			status: "Upcoming",
		},
	],
	memberProgress: {
		activeChallenges: 45,
		completedWorkouts: 1234,
		achievementRate: 78,
		pendingRequests: 12,
	},
	performanceMetrics: {
		memberRetention: 94,
		avgSessionRating: 4.8,
		activePlans: 156,
		challengeCompletion: 82,
	},
	topPerformers: [
		{name: "John Doe", points: 2500, rank: 1, progress: 85},
		{name: "Jane Smith", points: 2300, rank: 2, progress: 78},
		{name: "Mike Johnson", points: 2100, rank: 3, progress: 72},
	],
};

function AdminDashboard() {
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const currentUser = useRecoilValue(currentUserState);
	const [showNewMemberModal, setShowNewMemberModal] = useState(false);
	const [stats, setStats] = useState({
		totalMembers: "1,234",
		activePrograms: "45",
		videoCollections: "89",
		revenue: "$12,345",
	});
	const [recentActivities] = useState([
		{
			title: "New Member Joined",
			description: "John Doe has registered as a new member",
			time: "5m ago",
			icon: faUsers,
			bgColor: "bg-blue-50",
		},
		{
			title: "New Challenge Created",
			description: "Summer Fitness Challenge 2024",
			time: "2h ago",
			icon: faTrophy,
			bgColor: "bg-yellow-50",
		},
		{
			title: "Video Added",
			description: "New workout video uploaded",
			time: "3h ago",
			icon: faVideo,
			bgColor: "bg-purple-50",
		},
	]);

	const UpcomingClassesSection = () => (
		<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm">
			<div className="space-y-4 sm:space-y-6">
				{/* Header */}
				<div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-0">
					<h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-900">
						Today's Classes
					</h2>
					<div className="flex items-center space-x-2">
						<span
							className="px-3 sm:px-4 py-1.5 sm:py-2 bg-green-50 text-green-600 
							   rounded-full text-xs sm:text-sm font-medium">
							{STATIC_DASHBOARD_DATA.upcomingClasses.length} Classes Today
						</span>
					</div>
				</div>

				{/* Classes List */}
				<div className="space-y-3 sm:space-y-4">
					{STATIC_DASHBOARD_DATA.upcomingClasses.map((session, index) => (
						<div
							key={index}
							className="flex flex-col sm:flex-row sm:items-center justify-between 
						   p-3 sm:p-5 bg-gray-50 rounded-xl hover:bg-gray-100 
						   transition-all duration-300 gap-3 sm:gap-4">
							{/* Left Section - Class Info */}
							<div className="flex items-center space-x-3 sm:space-x-4">
								<div className="bg-primary p-3 sm:p-4 rounded-full shrink-0">
									<FontAwesomeIcon
										icon={faClock}
										className="text-white text-base sm:text-xl"
									/>
								</div>
								<div className="min-w-0">
									<p className="font-semibold text-gray-900 text-sm sm:text-base truncate">
										{session.title}
									</p>
									<div className="flex flex-wrap items-center gap-x-2 mt-1">
										<span className="text-xs sm:text-sm text-gray-500">
											{session.time}
										</span>
										<span className="text-gray-300 hidden sm:inline">•</span>
										<span className="text-xs sm:text-sm text-gray-500">
											{session.trainer}
										</span>
									</div>
								</div>
							</div>

							{/* Right Section - Status & Attendees */}
							<div
								className="flex items-center justify-between sm:justify-end 
								space-x-3 sm:space-x-4 ml-11 sm:ml-0">
								<span
									className={`px-2.5 sm:px-4 py-1 sm:py-2 rounded-full text-xs sm:text-sm 
								font-medium whitespace-nowrap ${
									session.status === "In Progress"
										? "bg-green-50 text-green-600"
										: "bg-blue-50 text-blue-600"
								}`}>
									{session.status}
								</span>
								<span className="text-xs sm:text-sm text-gray-500 font-medium whitespace-nowrap">
									{session.attendees} attendees
								</span>
							</div>
						</div>
					))}
				</div>

				{/* View All Link */}
				<div className="pt-2 sm:pt-4 border-t border-gray-100">
					<Link
						to="/admin/classes"
						className="inline-flex items-center text-primary hover:text-primary-dark 
						 text-sm sm:text-base font-medium transition-colors duration-300">
						View All Classes
						<FontAwesomeIcon
							icon={faArrowRight}
							className="ml-2 text-xs sm:text-sm"
						/>
					</Link>
				</div>
			</div>
		</div>
	);

	const TopPerformersSection = () => (
		<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm">
			<div className="space-y-4 sm:space-y-6">
				{/* Header */}
				<div className="flex items-center justify-between">
					<h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-900">
						Top Performers
					</h2>
					<Link
						to="/admin/members/leaderboard"
						className="text-primary hover:text-primary-dark text-sm sm:text-base 
						 font-medium transition-colors duration-300 flex items-center gap-2">
						View All
						<FontAwesomeIcon
							icon={faArrowRight}
							className="text-xs sm:text-sm transition-transform group-hover:translate-x-1"
						/>
					</Link>
				</div>

				{/* Performers List */}
				<div className="space-y-3 sm:space-y-4">
					{STATIC_DASHBOARD_DATA.topPerformers.map((performer, index) => (
						<div
							key={index}
							className="group flex items-center justify-between p-3 sm:p-4 
						   bg-gray-50 rounded-xl hover:bg-gray-100 
						   transition-all duration-300 cursor-pointer">
							{/* Left Section - Rank & Info */}
							<div className="flex items-center space-x-3 sm:space-x-4">
								<div
									className={` bg-white hover:bg-primary/10
						w-8 h-8 sm:w-10 sm:h-10 rounded-full 
						flex items-center justify-center 
						text-base sm:text-lg font-bold
						transform transition-transform group-hover:scale-105
						${
							index === 0
								? "bg-yellow-100 text-yellow-600"
								: index === 1
								? "bg-gray-200 text-gray-600"
								: "bg-orange-100 text-orange-600"
						}
					  `}>
									{performer.rank}
								</div>
								<div>
									<p className="font-semibold text-gray-900 text-sm sm:text-base">
										{performer.name}
									</p>
									<div className="flex items-center gap-2 mt-0.5">
										<p className="text-xs sm:text-sm text-gray-500">
											{performer.points.toLocaleString()} points
										</p>
										<span className="hidden sm:inline text-gray-300">•</span>
										<span className="hidden sm:block text-xs text-gray-500">
											Level {Math.floor(performer.points / 500)}
										</span>
									</div>
								</div>
							</div>

							{/* Right Section - Progress Bar */}
							<div className="w-20 sm:w-32">
								<div className="flex items-center gap-2">
									<div className="flex-1 h-1.5 sm:h-2 bg-gray-200 rounded-full overflow-hidden">
										<div
											className="h-full bg-primary rounded-full transition-all duration-300
								   group-hover:bg-primary-dark"
											style={{width: `${performer.progress}%`}}
										/>
									</div>
									<span className="text-xs font-medium text-gray-500 whitespace-nowrap">
										{performer.progress}%
									</span>
								</div>
							</div>
						</div>
					))}
				</div>

				{/* Footer - Weekly Stats */}
				<div className="pt-3 sm:pt-4 mt-3 sm:mt-4 border-t border-gray-100">
					<div className="flex items-center justify-between text-xs sm:text-sm text-gray-500">
						<span>Updated weekly</span>
						<div className="flex items-center gap-2">
							<FontAwesomeIcon icon={faFire} className="text-primary" />
							<span>
								{STATIC_DASHBOARD_DATA.topPerformers.length} active members
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const StatCard = ({title, value, change, icon, bgColor = "bg-blue-50"}) => (
		<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100">
			<div className="flex items-center justify-between">
				<div className="space-y-2 sm:space-y-3">
					<p className="text-sm font-medium text-gray-500">{title}</p>
					<p className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900">
						{value}
					</p>
					<div
						className={`inline-flex items-center px-2.5 py-1 sm:px-3 sm:py-1.5 rounded-full text-xs sm:text-sm font-medium ${
							change >= 0
								? "bg-green-50 text-green-600"
								: "bg-red-50 text-red-600"
						}`}>
						<span>
							{change >= 0 ? "↑" : "↓"} {Math.abs(change)}%
						</span>
					</div>
				</div>
				<div className={`${bgColor} p-3 sm:p-4 rounded-xl`}>
					<FontAwesomeIcon
						icon={icon}
						className="text-primary text-xl sm:text-2xl"
					/>
				</div>
			</div>
		</div>
	);

	// Enhanced QuickActionCard Component
	const QuickActionCard = ({
		title,
		description,
		icon,
		link,
		bgColor = "bg-blue-50",
	}) => (
		<Link to={link} className="block group">
			<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100">
				<div className="flex items-start space-x-3 sm:space-x-4">
					<div
						className={`${bgColor} p-3 sm:p-4 rounded-xl shrink-0 group-hover:bg-primary/10`}>
						<FontAwesomeIcon
							icon={icon}
							className="text-primary text-lg sm:text-xl group-hover:scale-110 transition-all duration-300"
						/>
					</div>
					<div className="min-w-0">
						<h3 className="font-semibold text-gray-900 text-sm sm:text-base group-hover:text-primary transition-colors duration-300">
							{title}
						</h3>
						<p className="text-xs sm:text-sm text-gray-500 mt-1 sm:mt-1.5 line-clamp-2">
							{description}
						</p>
					</div>
				</div>
			</div>
		</Link>
	);

	// Enhanced WelcomeSection Component
	const WelcomeSection = ({currentUser, onNewMember}) => (
		<div className="bg-gradient-to-br from-primary to-primary-dark rounded-xl p-4 sm:p-6 lg:p-8 text-white">
			<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-6">
				<div className="space-y-1 sm:space-y-2">
					<h1 className="text-xl sm:text-2xl lg:text-3xl font-bold">
						Welcome back, {currentUser?.name}
					</h1>
					<p className="text-white/80 text-sm sm:text-base">
						Here's what's happening with your gym today.
					</p>
				</div>
				<button
					onClick={onNewMember}
					className="px-4 sm:px-6 py-2 sm:py-3 bg-white text-primary rounded-xl 
					   hover:bg-gray-50 transition-all duration-300 font-medium 
					   flex items-center justify-center space-x-2 shadow-lg 
					   hover:shadow-xl w-full sm:w-auto text-sm sm:text-base">
					<FontAwesomeIcon icon={faPlus} className="text-sm" />
					<Link className="text-textDark" to="/admin/members/add">
						New Member
					</Link>
				</button>
			</div>
		</div>
	);

	const breadcrumbItems = [{label: "Dashboard", icon: faGauge}];

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="p-4 sm:p-6 lg:p-8 space-y-6 sm:space-y-8 bg-gray-50 min-h-screen">
				{/* Demo Message Banner */}
				<div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded-lg">
					<div className="flex items-center text-blue-700">
						<span className="text-sm">Demo Mode: Currently displaying mock data for demonstration purposes.</span>
					</div>
				</div>

				{/* Welcome Section */}
				<WelcomeSection
					currentUser={currentUser}
					onNewMember={() => setShowNewMemberModal(true)}
				/>

				{/* Stats Grid */}
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
					<StatCard
						title="Total Members"
						value={stats.totalMembers}
						change={12.5}
						icon={faUsers}
					/>
					<StatCard
						title="Active Programs"
						value={stats.activePrograms}
						change={-2.3}
						icon={faDumbbell}
						bgColor="bg-purple-50"
					/>
					<StatCard
						title="Video Collections"
						value={stats.videoCollections}
						change={5.7}
						icon={faVideo}
						bgColor="bg-green-50"
					/>
					<StatCard
						title="Total Revenue"
						value={stats.revenue}
						change={8.9}
						icon={faDollarSign}
						bgColor="bg-yellow-50"
					/>
				</div>

				<div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
					<div className="xl:col-span-2 space-y-8">
						<div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-400">
							<UpcomingClassesSection />
						</div>

						<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm">
							<div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 sm:gap-0 mb-6">
								<h2 className="text-lg sm:text-xl font-bold text-gray-900">
									Quick Actions
								</h2>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
								<QuickActionCard
									title="Member Management"
									description="Add, edit, or review member profiles"
									icon={faUsers}
									link="/admin/members"
									bgColor="bg-blue-50"
								/>
								<QuickActionCard
									title="Exercise Library"
									description="Manage workout exercises and routines"
									icon={faChalkboardTeacher}
									link="/admin/exercises"
									bgColor="bg-green-50"
								/>
								<QuickActionCard
									title="Video Content"
									description="Manage video collections and categories"
									icon={faVideo}
									link="/admin/video-collections"
									bgColor="bg-purple-50"
								/>
								<QuickActionCard
									title="Fitness Challenges"
									description="Create and manage training programs"
									icon={faDumbbell}
									link="/admin/fitness-challenges"
									bgColor="bg-orange-50"
								/>
								<QuickActionCard
									title="Global Workouts"
									description="Manage workouts for all members"
									icon={faTrophy}
									link="/admin/workouts"
									bgColor="bg-yellow-50"
								/>
								<QuickActionCard
									title="Tips & Resources"
									description="Manage fitness tips and resources"
									icon={faLightbulb}
									link="/admin/tips"
									bgColor="bg-red-50"
								/>
							</div>
						</div>
					</div>

					{/* Right Column - Performance and Activity */}
					<div className="space-y-6 sm:space-y-8">
						<TopPerformersSection />
						<div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm">
							<h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4 sm:mb-6">
								Recent Activity
							</h2>
							<div className="space-y-3 sm:space-y-4">
								{recentActivities.map((activity, index) => (
									<div
										key={index}
										className="flex items-start space-x-3 sm:space-x-4 p-3 sm:p-4 
                                hover:bg-gray-50 rounded-lg transition-colors">
										<div
											className={`p-2 sm:p-2.5 rounded-xl ${activity.bgColor} shrink-0`}>
											<FontAwesomeIcon
												icon={activity.icon}
												className="text-primary text-sm sm:text-base"
											/>
										</div>
										<div className="flex-1 min-w-0">
											<p className="text-sm sm:text-base font-medium text-gray-900 truncate">
												{activity.title}
											</p>
											<p className="text-xs sm:text-sm text-gray-500 mt-0.5">
												{activity.description}
											</p>
										</div>
										<span className="text-xs sm:text-sm text-gray-400 whitespace-nowrap">
											{activity.time}
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminDashboard;

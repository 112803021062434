import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faFilter,
  faSearch,
  faSave,
  faFilterCircleXmark,
  faDumbbell,
  faGauge,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropZone from "../../Reusable/dropzone";
import { getExerciseListAPI } from "../../../API/Exercise";
import WorkoutDisplay from "../../Reusable/Workouts/WorkoutDisplay";
import { postWorkoutCreateAPI } from "../../../API/workout";
import DragSortListForSelectedWorkouts from "../../Reusable/draglistforSelectWorkouts";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
  EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import Modal from "../../Reusable/Modal";

function AdminWorkoutAdd() {
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  const [currentUser] = useRecoilState(currentUserState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [listdata, setlistdata] = useState([]);
  const [selectableExcercises, setselectableExcercises] = useState(listdata);
  const [forceURL, setForceURL] = useState("");
  const [exerciseLoading, setExerciseLoading] = useState(true);
  const [showExerciseFilter, setshowExerciseFilter] = useState(false);
  const [temporarySearchText, setTemporarySearchText] = useState("");
  const [actualSearchText, setActualSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [movementType, setMovementType] = useState("");
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState("");
  const [videoType, setVideoType] = useState("");
  const [tags, setTags] = useState([]);
  const onSubmitClick = () => {
    // Logic to submit data
    let payload = {
      name: name,
      description: description,
      visibility: 1, //1. visible to all 2. personal
      user_id: currentUser.id,
      user_name: currentUser.name,
    };
    let workoutExcercises = [];
    selectedWorkouts?.map((w, index) =>
      workoutExcercises.push({
        exercise_id: w.isRest ? null : w.id,
        exercise_name: w.isRest ? "REST" : w.name,
        is_rest: w.isRest === true,
        order_number: index + 1,
        sets: w.reps ? parseInt(w.reps) : 0,
        type: w.type ? parseInt(w.type) : 0,
        rest_period_in_secs: parseInt(w.restPeriod),
        target_time_in_secs: w.targetTime ? parseInt(w.targetTime) : 0,
        target_text: w?.targetText,
      })
    );
    payload.workout_exercises = workoutExcercises;
    postWorkoutCreateAPI(payload, onAddSuccess, onAddError, onAddDone);
  };

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Exercise created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/workouts/" + response.id + "");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  const getAllExcericses = (clear = false, search = "") => {
    setExerciseLoading(true);
    let params = new Map();
    params.set("page_size", 1000000);
    params.set("sort_field", "created");
    params.set("sort_order", "-1");
    if ((!clear && actualSearchText !== "") || search != "") {
      if (search) {
        params.set("search", search);
      } else {
        params.set("search", actualSearchText);
      }
    }
    if (!clear && category !== "") {
      params.set("category", category);
    }
    if (!clear && movementType !== "") {
      params.set("movement_type", movementType);
    }
    if (!clear && status !== "") {
      params.set("status", status);
    }
    if (!clear && gender !== "") {
      params.set("gender", gender);
    }
    if (!clear && videoType !== "") {
      params.set("video_type", videoType);
    }
    if (tags.length > 0) {
      params.set("tags", tags);
    }
    getExerciseListAPI(
      params,
      onExerciseListSuccess,
      onExerciseListError,
      onExerciseListDone
    );
  };

  function onExerciseListSuccess(response) {
    if (response.results !== null) {
      setlistdata(response.results);
      setselectableExcercises(response.results);
      if (response.hasNextPage) {
        // setNextCursor(response.nextCursor);
      }
    } else {
      setlistdata([]);
      // setNextCursor("");
    }
  }

  function onExerciseListError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onExerciseListDone() {
    setFetching(false);
    setExerciseLoading(false);
  }

  const onSearchButtonClick = (e) => {
    setActualSearchText(temporarySearchText);
    getAllExcericses(false, temporarySearchText);
    setshowExerciseFilter(false);
  };

  function ApplyFilter() {
    getAllExcericses();
    setshowExerciseFilter(false);
  }
  const onClearFilterClick = (e) => {
    setshowExerciseFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
    setStatus("");
    setCategory("");
    setMovementType("");
    setVideoType("");
    setGender("");
    getAllExcericses(true);
  };

  useEffect(() => {
    getAllExcericses();
    window.scrollTo(0, 0);
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const onDrop = (item) => {
    const exercise = listdata.find((ex) => ex.id === item.id);
    const newWorkout = {
      ...exercise,
      reps: "",
      restPeriod: "",
      targetText: "",
      targetType: "",
    };

    setselectableExcercises((prevExercises) =>
      prevExercises.filter((e) => e.id !== exercise.id)
    );

    setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, newWorkout]);
  };

  const handleInputChange = (e, exerciseId, field) => {
    const { value } = e.target;
    setSelectedWorkouts((prevWorkouts) =>
      prevWorkouts?.map((workout) => {
        if (workout.id === exerciseId) {
          return { ...workout, [field]: value };
        }
        return workout;
      })
    );
  };

  const onRemove = (cancelledItem) => {
    // Move the cancelled item back to the exercises column
    setSelectedWorkouts((prevWorkouts) =>
      prevWorkouts.filter((workout) => workout.id !== cancelledItem.id)
    );
    if (!cancelledItem.isRest) {
      const exercise = listdata.find((ex) => ex.id === cancelledItem.id);
      setselectableExcercises((e) => [...e, exercise]);
    }
  };

  const handleAddRest = () => {
    const restId = `rest-${Date.now()}`;
    let restWorkout = { id: restId, restPeriod: 60, isRest: true };

    setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, restWorkout]);
  };

  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Workouts", link: "/admin/workouts", icon: faDumbbell },
    { label: "New Workout", icon: faPlus },
  ];

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
          <DndProvider backend={HTML5Backend}>
      <div className="mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          {/* Header */}
          <div className="border-b border-gray-200 px-6 py-4">
            <h1 className="text-xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Workouts
            </h1>
            <p className="mt-2 text-sm text-gray-600">
              Please fill out all the required fields before submitting this form.
            </p>
          </div>

          {/* Content */}
          <div className="p-6">
            <FormErrorBox errors={errors} />

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <div className="space-y-6">
                {/* Basic Info */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormTextareaField
                    rows={1}
                    name="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isRequired={true}
                    className="max-w-[150px]"
                  />
                  <FormTextareaField
                    rows={1}
                    name="Description"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isRequired={true}
                    className="max-w-[380px]"
                  />
                </div>

                {/* Workout Builder Section */}
									<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
										{/* Selected Workouts Column */}
										<div className="bg-white rounded-xl border border-gray-200 shadow-sm">
											<div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
												<div className="flex items-center justify-between">
													<h2 className="text-lg font-semibold text-gray-900">
														Selected Workouts
													</h2>
													<button
														onClick={handleAddRest}
														className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg
                                 hover:bg-primary-dark transition-all duration-200 shadow-sm">
														<FontAwesomeIcon icon={faPlus} className="mr-2" />
														Add Rest Period
													</button>
												</div>
											</div>

											<div className="p-4">
												<DropZone
													className="min-h-[400px] bg-gray-50 border-2 border-dashed border-gray-300 
                               rounded-xl transition-all duration-200 hover:border-primary/50"
													onDrop={onDrop}
													placeholder={!selectedWorkouts.length}>
													<DragSortListForSelectedWorkouts
														onRemove={onRemove}
														onSortChange={setSelectedWorkouts}
														selectedWorkouts={selectedWorkouts}
														handleInputChange={handleInputChange}
													/>
												</DropZone>
											</div>
										</div>

										{/* Available Exercises Column */}
										<div className="bg-white rounded-xl border border-gray-200 shadow-sm">
											<div className="bg-gray-50 border-b border-gray-200">
												{/* Header */}
												<div className="px-6 py-4 border-b border-gray-100 bg-gray-50/50">
													<div className="flex items-center justify-between">
														<div className="flex items-center gap-3">
															<div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center">
																<FontAwesomeIcon
																	icon={faDumbbell}
																	className="text-primary text-lg"
																/>
															</div>
															<div>
																<h2 className="text-lg font-semibold text-gray-900">
																	Available Exercises
																</h2>
																<p className="text-sm text-gray-500">
																	Drag or click to add to your workout
																</p>
															</div>
														</div>
														<span className="px-3 py-1.5 bg-primary/10 text-primary rounded-full text-sm font-medium">
															{selectableExcercises.length} exercises
														</span>
													</div>
												</div>
											</div>

											<div className="p-4">
												{exerciseLoading ? (
													<PageLoadingContent
														displayMessage={"Loading exercises..."}
													/>
												) : (
													<WorkoutDisplay
														className="bg-gray-50 rounded-xl border border-gray-200"
														workouts={selectableExcercises}
														onAdd={onDrop}
														showindex={false}
														showDescription={false}
													/>
												)}
											</div>
										</div>
									</div>

                {/* Footer Actions */}
                <div className="flex flex-col sm:flex-row justify-between items-center pt-6 gap-4 border-t border-gray-200">
                  <Link
                    to="/workouts"
                    className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg 
                             text-gray-700 hover:text-primary hover:border-primary 
                             transition-colors flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Back to Workouts
                  </Link>
                  <button
                    onClick={onSubmitClick}
                    disabled={!(name && description && selectedWorkouts.length)}
                    className="w-full sm:w-auto px-4 py-2 bg-primary text-white rounded-lg 
                             hover:bg-primary-dark transition-colors flex items-center justify-center
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
    </Layout>
  );
}

export default AdminWorkoutAdd;

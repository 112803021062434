import React, {useState, useEffect, useMemo} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faEye,
	faBuilding,
	faEnvelope,
	faSquarePhone,
	faTable,
	faHome,
	faLock,
	faTimesCircle,
	faArrowRight,
	faArrowUpRightFromSquare,
	faImage,
	faEllipsis,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faKey,
	faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import QRCode from "qrcode.react";

import {getAccountDetailAPI} from "../../../API/Account";
import {postVertifyOTP} from "../../../API/gateway";
import {postGenerateOTP} from "../../../API/gateway";
import DateTimeTextFormatter from "../../Reusable/EveryPage/DateTimeTextFormatter";
import CheckboxTextFormatter from "../../Reusable/EveryPage/CheckboxTextFormatter";
import SelectTextFormatter from "../../Reusable/EveryPage/SelectTextFormatter";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	currentOTPResponseState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputField from "../../Reusable/FormInputField";
import DataDisplayRowImage from "../../Reusable/DataDisplayRowImage";
import TagsTextFormatter from "../../Reusable/EveryPage/TagsTextFormatter";
import URLTextFormatter from "../../Reusable/EveryPage/URLTextFormatter";
import EmailTextFormatter from "../../Reusable/EveryPage/EmailTextFormatter";
import PhoneTextFormatter from "../../Reusable/EveryPage/PhoneTextFormatter";
import DateTextFormatter from "../../Reusable/EveryPage/DateTextFormatter";
import {COMMERCIAL_CUSTOMER_TYPE_OF_ID} from "../../../Constants/App";
import Layout from "../../Menu/Layout";
import {
	ROOT_ROLE_ID,
	ADMIN_ROLE_ID,
	TRAINER_ROLE_ID,
	MEMBER_ROLE_ID,
} from "../../../Constants/App";
import {
	USER_PHONE_TYPE_OF_OPTIONS_WITH_EMPTY_OPTIONS,
	USER_TYPE_OF_FILTER_OPTIONS,
	USER_ORGANIZATION_TYPE_OPTIONS,
	GENDER_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";

// QR Code Error Boundary Component
class QRCodeErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("QR Code Error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="text-center p-4">
                    <p className="text-red-600">Failed to display QR code</p>
                    {this.props.fallback}
                </div>
            );
        }
        return this.props.children;
    }
}

function AccountEnableTwoFactorAuthentication() {
	////
	////
	////

	////
	//// Global state.
	////

	// Global state
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [otpResponse, setOtpResponse] = useRecoilState(currentOTPResponseState);

    // Local state
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [verificationToken, setVerificationToken] = useState("");

    // QR Code size based on screen width
    const qrSize = useMemo(() => {
        return window.innerWidth < 768 ? 200 : 250;
    }, []);

    useEffect(() => {
        if (otpResponse) {
            console.log("OTP Response:", otpResponse);
            console.log("OTP Auth URL:", otpResponse.optAuthURL);
        }
    }, [otpResponse]);

	////
	//// Event handling.
	////

// Event handlers
const onVerificationTokenSubmitButtonClick = () => {
    const cleanedVerificationToken = verificationToken.replace(/\s/g, "");
    const payload = { verification_token: cleanedVerificationToken };
    
    setFetching(true);
    postVertifyOTP(
        payload,
        onVerifyOPTSuccess,
        onVerifyOPTError,
        onVerifyOPTDone
    );
};

// API Success handlers
function onGenerateOPTSuccess(response) {
    if (!response || !response.optAuthURL) {
        console.error("Invalid OTP response format");
        setErrors({ api: "Invalid response from server" });
        return;
    }
    setOtpResponse(response);
}

	// API Error handlers
    function onGenerateOPTError(apiErr) {
        setErrors(apiErr);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

	function onGenerateOPTDone() {
		console.log("onGenerateOPTDone: Starting...");
        
		setFetching(false);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();


	}

	// --- Enable 2FA --- //

	function onVerifyOPTSuccess(response) {
        if (response?.user) {
            setErrors({});
            setCurrentUser(response.user);
            setTopAlertMessage("2FA Enabled");
            setTopAlertStatus("success");
            setTimeout(() => setTopAlertMessage(""), 2000);
            setForceURL("/account?tab=twoFactor");
        }
    }


	function onVerifyOPTError(apiErr) {
        setErrors(apiErr);
        setTopAlertMessage("Failed Enabling 2FA");
        setTopAlertStatus("danger");
        setTimeout(() => setTopAlertMessage(""), 2000);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

	function onVerifyOPTDone() {
		console.log("onVerifyOPTDone: Starting...");
		setFetching(false);
	}

	////
	//// BREADCRUMB
	////

	const generateBreadcrumbItemLink = (currentUser) => {
		let dashboardLink;
		switch (currentUser.role) {
			case ROOT_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case ADMIN_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case TRAINER_ROLE_ID:
				dashboardLink = "/admin/dashboard";
				break;
			case MEMBER_ROLE_ID:
				dashboardLink = "/dashboard";
				break;
			default:
				dashboardLink = "/501"; // Default or error handling
				break;
		}
		return dashboardLink;
	};

	////
	//// Misc.
	////

	// Initial data fetch
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);
            setFetching(true);
            setErrors({});

            postGenerateOTP(
                onGenerateOPTSuccess,
                onGenerateOPTError,
                onGenerateOPTDone
            );
        }


        return () => {
            mounted = false;
        };
    }, []);

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: generateBreadcrumbItemLink(currentUser),
		},
		{label: "Enable 2FA", icon: faEye},
	];


	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div>
				<section className="p-4">
					<h3 className="text-sm font-medium uppercase">Enable 2FA</h3>
					<hr className="border-gray-200 mb-6" />

					{/* Page Content */}
					<div className="bg-white rounded-lg shadow-md p-6">
						{isFetching ? (
							<PageLoadingContent displayMessage={"Loading..."} />
						) : (
							<>
								<FormErrorBox errors={errors} />
								{currentUser && (
									<div className="space-y-8">
										<div className="grid md:grid-cols-3 gap-8">
											{/* Step 1 */}
											<div className="space-y-6">
												<h2 className="text-2xl font-semibold">Step 1</h2>
												<hr className="border-gray-200" />
												<p className="text-gray-600">
													To begin, please download any of the following
													applications for your mobile device.
												</p>

												{/* Auth App Cards */}
												<div className="space-y-4">
													{/* Google Authenticator Card */}
													<div className="border rounded-lg shadow-sm">
														<div className="p-4">
															<h3 className="text-lg font-semibold underline mb-3">
																Google Authenticator
															</h3>
															<p className="mb-4">
																This 2FA app is created by <b>Google, Inc.</b>
															</p>
															<div className="space-y-2">
																<div>
																	<span className="font-bold">
																		Download for iOS:
																	</span>
																	<Link
																		className="text-primary hover:text-primary-hover ml-2 inline-flex items-center"
																		to="https://apps.apple.com/ca/app/google-authenticator/id388497605"
																		target="_blank"
																		rel="noreferrer">
																		Visit App Store
																		<FontAwesomeIcon
																			className="ml-1"
																			icon={faArrowUpRightFromSquare}
																		/>
																	</Link>
																</div>
																<div>
																	<span className="font-bold">
																		Download for Android:
																	</span>
																	<Link
																		className="text-primary hover:text-primary-hover ml-2 inline-flex items-center"
																		to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1"
																		target="_blank"
																		rel="noreferrer">
																		Visit Google Play
																		<FontAwesomeIcon
																			className="ml-1"
																			icon={faArrowUpRightFromSquare}
																		/>
																	</Link>
																</div>
															</div>
														</div>
													</div>

													{/* Authenticator Chrome Extension */}
													<div className="border rounded-lg shadow-sm">
														<div className="p-4">
															<h3 className="text-lg font-semibold underline mb-3">
																Authenticator
															</h3>
															<p className="mb-4">
																This 2FA app is created by{" "}
																<b>authenticator.cc</b>
															</p>
															<div>
																<span className="font-bold">
																	Download for Chrome:
																</span>
																<Link
																	className="text-primary hover:text-primary-hover ml-2 inline-flex items-center"
																	to="https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?pli=1"
																	target="_blank"
																	rel="noreferrer">
																	Visit Chrome web store
																	<FontAwesomeIcon
																		className="ml-1"
																		icon={faArrowUpRightFromSquare}
																	/>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* Step 2 */}
											<div className="space-y-4">
                                <h2 className="text-lg font-semibold text-gray-900">
                                    Step 2: Scan QR Code
                                </h2>
                                <p className="text-gray-600">
                                    Open your authenticator app and scan the following QR code:
                                </p>

                                <QRCodeErrorBoundary
                                    fallback={
                                        otpResponse?.manualEntryCode && (
                                            <div className="mt-4">
                                                <p className="text-sm font-medium">Manual Entry Code:</p>
                                                <code className="block mt-2 p-2 bg-gray-100 rounded">
                                                    {otpResponse.manualEntryCode}
                                                </code>
                                            </div>
                                        )
                                    }
                                >
                                    <div className="flex justify-center">
                                        <div className="text-center">
                                            {isFetching ? (
                                                <div className="p-4">
                                                    <span className="text-gray-600">Loading QR code...</span>
                                                </div>
                                            ) : otpResponse?.optAuthURL ? (
                                                <div className="inline-block p-4 bg-white rounded-lg shadow-sm">
                                                    <QRCode
                                                        value={otpResponse.optAuthURL}
                                                        size={qrSize}
                                                        level="H"
                                                        includeMargin={true}
                                                        className="mx-auto"
                                                        onError={(error) => {
                                                            console.error("QR Code Error:", error);
                                                            setErrors({ qr: "Failed to generate QR code" });
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="p-4">
                                                    <span className="text-red-600">Failed to load QR code</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </QRCodeErrorBoundary>
                            </div>


											{/* Step 3 */}
											<div className="space-y-6">
												<h2 className="text-2xl font-semibold">Step 3</h2>
												<hr className="border-gray-200" />
												<p className="text-gray-600">
													Open the two-step verification app on your mobile
													device to get your verification code.
												</p>

												<div className="space-y-4">
													<FormInputField
														label="Enter your Verification Token"
														name="verificationToken"
														placeholder="See your authenticator app"
														value={verificationToken}
														errorText={errors && errors.verificationToken}
														helpText=""
														onChange={(e) =>
															setVerificationToken(e.target.value)
														}
														isRequired={true}
														maxWidth="380px"
													/>
													<button
														className="w-full md:w-auto px-6 py-2 bg-primary hover:bg-primary-hover text-white rounded-lg transition-colors duration-200 flex items-center justify-center"
														onClick={onVerificationTokenSubmitButtonClick}>
														<FontAwesomeIcon className="mr-2" icon={faLock} />
														Submit Verification Token
													</button>
												</div>
											</div>
										</div>

										{/* Bottom Navigation */}
										<div className="flex flex-col md:flex-row justify-between pt-8">
											<Link
												to="/account/2fa"
												className="mb-4 md:mb-0 px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center justify-center md:justify-start">
												<FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
												Back to Detail (2FA)
											</Link>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default AccountEnableTwoFactorAuthentication;

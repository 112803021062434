import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,  
  faGauge,
  faPencil,
  faUsers,
  faEye,
  faIdCard,
  faTags,
  faArrowLeft,
  faDumbbell,
  faAppleAlt,
  faRunning,
  faArrowUp,
  faTrash,
  faCogs,
  faChartPie,
  faHistory,
  faAddressCard,
  faMapMarker,
  faChartBar,
  faCrown,
  faCheckCircle,
  faBuilding,
  faGlobe,
  faCity,
  faMapPin,
  faCalendar,
  faEllipsisV,
  faChevronDown,
  faFileInvoice,
  faCalendarCheck,
  faUserClock,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";

import useLocalStorage from "../../../Hooks/useLocalStorage";
import { getMemberDetailAPI, deleteMemberAPI } from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import { ProfileSidebar } from "./DetailComponents";
import AdminMemberDetailForTags from "./DetailForTags";
import AdminMemberDetailForWaitlisterList from "./DetailForWaitlisterList";
import AdminMemberDetailForInvoiceList from "./DetailForInvoiceList";
import AdminMemberDetailForBookingList from "./DetailForBookingList";
import AdminMemberProfileDetail from "./DetailForProfile";
import AdminMemberDetailForFitnessPlans from "./DetailForFitnessPlan";
import AdminMemberDetailForNutritionPlans from "./DetailForNutritionPlan";

// Define main tabs that are always visible
const mainTabs = [
  {
    id: 'overview',
    label: 'Overview',
    icon: faUser,
  },
  {
    id: 'profile',
    label: 'Profile',
    icon: faIdCard,
  },
  {
    id: 'bookings',
    label: 'Bookings',
    icon: faCalendarCheck,
  },
  {
    id: 'invoices',
    label: 'Invoices',
    icon: faFileInvoice,
  }
];

// Define overflow tabs that appear in the dropdown
const overflowTabs = [
  {
    id: 'tags',
    label: 'Tags',
    icon: faTags,
  },
  {
    id: 'waitlist',
    label: 'Waitlist',
    icon: faUserClock,
  },
  {
    id: 'fitness-plans',  // New tab
    label: 'Fitness Plans',
    icon: faDumbbell,
  },
  {
    id: 'nutrition-plans',  // New tab
    label: 'Nutrition Plans',
    icon: faAppleAlt,
  }
];

// Activity Card Component
export const ActivityCard = ({ title, icon, count, trend, color }) => (
  <div className="relative group">
    <div className={`absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100
                    transition-opacity duration-200 blur-xl bg-gradient-to-r ${color}`} />
    <div className="relative bg-white rounded-xl p-4 border border-primaryColor/5
                    hover:border-transparent transition-colors duration-200">
      <div className="flex justify-between items-start">
        <h4 className="text-sm font-medium text-gray-600">{title}</h4>
        <FontAwesomeIcon icon={icon} className={`text-primaryColor`} />
      </div>
      <p className="mt-2 text-2xl font-bold text-textDark">{count}</p>
      <p className="mt-1 text-xs text-gray-500">{trend}</p>
    </div>
  </div>
);

function TabNavigation({ activeTab, setActiveTab }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="border-b border-gray-200">
      <div className="flex items-center space-x-8">
        {/* Main Tabs */}
        {mainTabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`
              flex items-center py-4 px-3 border-b-2 font-medium text-sm
              ${activeTab === tab.id
                ? 'border-primaryColor text-primaryColor'
                : 'border-transparent text-gray-500 hover:text-gray-700'}
            `}
          >
            <FontAwesomeIcon icon={tab.icon} className="mr-2" />
            {tab.label}
          </button>
        ))}

        {/* More Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className={`
              flex items-center py-4 px-3 border-b-2 font-medium text-sm
              ${overflowTabs.some(tab => tab.id === activeTab)
                ? 'border-primaryColor text-primaryColor'
                : 'border-transparent text-gray-500 hover:text-gray-700'}
            `}
          >
            More
            <FontAwesomeIcon 
              icon={faChevronDown} 
              className={`ml-2 ${showDropdown ? 'rotate-180' : ''}`}
            />
          </button>

          {showDropdown && (
            <ul className="absolute left-0 w-48 py-2 mt-2 bg-white rounded-md shadow-lg border border-gray-200">
              {overflowTabs.map((tab) => (
                <li key={tab.id}>
                  <button
                    onClick={() => {
                      setActiveTab(tab.id);
                      setShowDropdown(false);
                    }}
                    className={`
                      flex items-center w-full px-4 py-2 text-sm
                      ${activeTab === tab.id
                        ? 'bg-gray-100 text-primaryColor'
                        : 'text-gray-700 hover:bg-gray-50'}
                    `}
                  >
                    <FontAwesomeIcon icon={tab.icon} className="mr-2" />
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

function AdminMemberDetail() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('overview');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [datum, setDatum] = useState({});
  const [forceURL, setForceURL] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  const breadcrumbItems = [
    { label: 'Dashboard', link: '/admin/dashboard', icon: faGauge },
    { label: 'Members', link: '/admin/members', icon: faUsers },
    { label: 'Member Details', icon: faUser },
  ];

  ////
  //// API Callbacks
  ////

  function onMemberDetailSuccess(response) {
    console.log("onMemberDetailSuccess: Starting...");
    setDatum(response);
  }

  function onMemberDetailError(apiErr) {
    console.log("onMemberDetailError: Starting...");
    setError(apiErr);
  }

  function onMemberDetailDone() {
    console.log("onMemberDetailDone: Starting...");
    setIsLoading(false);
  }

  function onMemberDeleteSuccess(response) {
    console.log("onMemberDeleteSuccess: Starting...");

    // Update notification.
    setTopAlertStatus("success");
    setTopAlertMessage("Member deleted");
    setTimeout(() => {
      console.log("onDeleteConfirmButtonClick: Going to redirect to list page...");
      setForceURL("/admin/members");
    }, 750);
  }

  function onMemberDeleteError(apiErr) {
    console.log("onMemberDeleteError: Starting...");
    setError(apiErr);

    // Update notification.
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");

    // Scroll to top.
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onMemberDeleteDone() {
    console.log("onMemberDeleteDone: Starting...");
    setFetching(false);
  }

  ////
  //// Event Handlers
  ////

  const onDeleteConfirmButtonClick = () => {
    console.log("onDeleteConfirmButtonClick: Starting...");
    setFetching(true);
    deleteMemberAPI(
      id,
      onMemberDeleteSuccess,
      onMemberDeleteError,
      onMemberDeleteDone
    );
  };

  ////
  //// Lifecycle
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
      setFetching(true);
      getMemberDetailAPI(
        id,
        onMemberDetailSuccess,
        onMemberDetailError,
        onMemberDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, []);

  ////
  //// Component Rendering
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  if (isLoading) {
    return <PageLoadingContent displayMessage={"Loading..."} />;
  }

  if (error) {
    return (
      <div className="p-4 text-red-600 bg-red-50 rounded-lg">
        Error: {error.message}
      </div>
    );
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen">
        {/* Floating Action Header */}
        <div className="bg-lightShade/95 backdrop-blur-lg">
          <div className="mx-auto px-4 py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <Link to="/admin/members" 
                      className="p-2 rounded-full hover:bg-lightGrayShade transition-all duration-200">
                  <FontAwesomeIcon icon={faArrowLeft} className="text-textDark" />
                </Link>
                <h2 className="text-lg font-semibold text-textDark">Member Details</h2>
              </div>
              <div className="flex items-center space-x-3">
                <Link to={`/admin/member/${id}/update`} 
                      className="btn-primary">
                  <FontAwesomeIcon icon={faPencil} className="mr-2" />
                  Edit
                </Link>
                <button onClick={onDeleteConfirmButtonClick}
                        className="btn-danger">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>

            {/* Tab Navigation */}
            <div className="mt-4">
              <TabNavigation 
                activeTab={activeTab} 
                setActiveTab={setActiveTab} 
              />
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="py-8">
          <div className="mx-auto px-4">
            <div className="flex flex-col lg:flex-row gap-8">
               {/* Sidebar */}
            <ProfileSidebar datum={datum} />

              {/* Main Content Area */}
              <div className="flex-1">
                {/* Render content based on active tab */}
                {activeTab === 'overview' && (
                  <div className="space-y-6">

                
        
                  {/* Demo Message Banner */}
                  <div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded-lg">
                    <div className="flex items-center text-blue-700">
                      <span className="text-sm">Demo Mode: Currently displaying mock data for demonstration purposes.</span>
                    </div>
                  </div>
                    {/* Activity Overview Section */}
                    <section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
                      <h3 className="text-lg font-bold text-textDark flex items-center">
                        <FontAwesomeIcon icon={faChartPie} className="mr-3 text-primaryColor" />
                        Activity Overview
                      </h3>
                      
                      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
                        <ActivityCard 
                          title="Fitness Plans"
                          icon={faDumbbell}
                          count="5"
                          trend="+2 this month"
                          color="from-greenShade1 to-greenShade2"
                        />
                        <ActivityCard 
                          title="Training Sessions"
                          icon={faRunning}
                          count="12"
                          trend="Next: Tomorrow"
                          color="from-blueShade1 to-blueShade2"
                        />
                        <ActivityCard 
                          title="Nutrition Plans"
                          icon={faAppleAlt}
                          count="3"
                          trend="Active"
                          color="from-main to-burntOrange"
                        />
                      </div>
                    </section>
                  </div>
                )}
                {activeTab === 'profile' && (
                  <div className="space-y-6">
                    <AdminMemberProfileDetail bid={id} />
                  </div>
                )}
                {activeTab === 'bookings' && (
                  <div className="space-y-6">
                    <AdminMemberDetailForBookingList bid={id} />
                  </div>
                )}
                {activeTab === 'invoices' && (
                  <div className="space-y-6">
                    <AdminMemberDetailForInvoiceList bid={id} />
                  </div>
                )}
                {activeTab === 'tags' && (
                  <div className="space-y-6">
                    <AdminMemberDetailForTags bid={id} />
                  </div>
                )}
                {activeTab === 'waitlist' && (
                  <div className="space-y-6">
                    <AdminMemberDetailForWaitlisterList bid={id} />
                  </div>
                )}
                {activeTab === 'fitness-plans' && (
        <div className="space-y-6">
          <AdminMemberDetailForFitnessPlans bid={id} />
        </div>
      )}
      {activeTab === 'nutrition-plans' && (
        <div className="space-y-6">
          <AdminMemberDetailForNutritionPlans bid={id} />
        </div>
      )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AdminMemberDetail;
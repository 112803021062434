import {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faPencil,
	faVideo,
	faEye,
	faDumbbell,
	faClock,
	faRepeat,
	faHourglass,
	faPlay,
	faVenusMars,
	faTag,
	faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Vimeo from "@u-wave/react-vimeo";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
} from "../../../Constants/App";
import {deleteWorkoutAPI, getWorkoutDetailAPI} from "../../../API/workout";
import YouTubeVideo from "../../Reusable/YoutubePlayer";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminWorkoutDetail() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [activeVideo, setActiveVideo] = useState(null);

	// Event handlers
	const onDeleteConfirmButtonClick = () => {
		deleteWorkoutAPI(id, onDeleteSuccess, onDeleteError, onDeleteDone);
		setShowDeleteModal(false);
	};

	// API Success/Error handlers
	function onVideoCollectionDetailSuccess(response) {
		setDatum(response);
	}

	function onVideoCollectionDetailError(apiErr) {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		setFetching(false);
	}

	function onDeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("Workout deleted successfully");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/admin/workouts");
	}

	function onDeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed to delete workout");
		setTimeout(() => setTopAlertMessage(""), 2000);
		Scroll.animateScroll.scrollToTop();
	}

	function onDeleteDone() {
		setFetching(false);
	}

	// Breadcrumb configuration
	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/admin/dashboard"},
		{label: "Workouts", icon: faDumbbell, link: "/admin/workouts"},
		{label: "Detail", icon: faEye},
	];

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			setFetching(true);
			getWorkoutDetailAPI(
				id,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
				{isFetching ? (
					<PageLoadingContent displayMessage="Loading workout details..." />
				) : (
					<>
						{/* Workout Header */}
						<div className="bg-white rounded-2xl shadow-md overflow-hidden mb-6">
							<div className="p-4 border-b border-gray-100">
								<div className="flex items-center justify-between">
									<div className="flex items-center space-x-4">
										<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center">
											<FontAwesomeIcon
												icon={faDumbbell}
												className="text-primary text-xl"
											/>
										</div>
										<div>
											<h1 className="text-2xl font-bold text-gray-900">
												{datum.name}
											</h1>
											<p className="text-gray-500 mt-1">
												Created by {datum.createdByUserName}
											</p>
										</div>
									</div>

									<div className="flex space-x-3">
										<Link
											to={`/admin/workouts/${id}/update`}
											className="inline-flex items-center px-4 py-2 bg-primary text-white 
                               rounded-lg hover:bg-primary-dark transition-colors duration-300">
											<FontAwesomeIcon icon={faPencil} className="mr-2" />
											Edit
										</Link>
										<button
											onClick={() => setShowDeleteModal(true)}
											className="inline-flex items-center px-4 py-2 bg-red-500 text-white 
                               rounded-lg hover:bg-red-600 transition-colors duration-300">
											<FontAwesomeIcon icon={faTrash} className="mr-2" />
											Delete
										</button>
									</div>
								</div>
							</div>
						</div>

						{/* Exercise List */}
						<div className="space-y-6">
							{datum.workoutExercises?.map((exercise, index) => (
								<ExerciseCard
									key={exercise.id}
									exercise={exercise}
									index={index}
									onVideoClick={() => setActiveVideo(exercise)}
								/>
							))}
						</div>

						<FormErrorBox errors={errors} />
					</>
				)}
			</div>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				header="Confirm Deletion">
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to delete this workout? This action cannot be
						undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowDeleteModal(false)}
							className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                       transition-colors duration-300">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 
                       transition-colors duration-300">
							Delete
						</button>
					</div>
				</div>
			</Modal>

			{/* Video Modal */}
			<Modal
				isOpen={activeVideo !== null}
				onClose={() => setActiveVideo(null)}
				header={activeVideo?.exerciseName}>
				<div className="aspect-video">
					{activeVideo && renderExerciseVideo(activeVideo.excercise)}
				</div>
			</Modal>
		</Layout>
	);
}

// Exercise Card Component
function ExerciseCard({exercise, index, onVideoClick}) {
	if (exercise.isRest) {
		return (
			<div className="bg-gray-50 rounded-2xl shadow-sm overflow-hidden border border-gray-100">
				<div className="p-4">
					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-4">
							<div className="w-12 h-12 bg-gray-200 rounded-xl flex items-center justify-center">
								<span className="text-gray-600 font-semibold">{index + 1}</span>
							</div>
							<div>
								<h2 className="text-xl font-semibold text-gray-700">
									Rest Period
								</h2>
								<div className="flex items-center space-x-4 mt-1">
									<span className="flex items-center text-sm text-gray-500">
										<FontAwesomeIcon
											icon={faHourglass}
											className="w-4 h-4 mr-1"
										/>
										{exercise.restPeriodInSecs || 0} seconds
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className="bg-white rounded-2xl shadow-md overflow-hidden border border-gray-100
                    hover:shadow-lg transition-all duration-300">
			{/* Exercise Header */}
			<div className="border-b border-gray-100">
				<div className="p-4">
					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-4">
							<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center">
								<span className="text-primary font-semibold">{index + 1}</span>
							</div>
							<div>
								<h2 className="text-xl font-semibold text-gray-900">
									{exercise.exerciseName}
								</h2>
								<div className="flex items-center space-x-4 mt-1">
									<span className="flex items-center text-sm text-gray-500">
										<FontAwesomeIcon icon={faRepeat} className="w-4 h-4 mr-1" />
										{exercise.sets || 0} sets
									</span>
									<span className="flex items-center text-sm text-gray-500">
										<FontAwesomeIcon
											icon={faVenusMars}
											className="w-4 h-4 mr-1"
										/>
										{exercise.excercise.gender}
									</span>
								</div>
							</div>
						</div>

						<button
							onClick={onVideoClick}
							className="flex items-center space-x-2 px-4 py-2 bg-primary/10 text-primary
                       rounded-lg hover:bg-primary/20 transition-colors duration-300">
							<FontAwesomeIcon icon={faCirclePlay} />
							<span>Watch Video</span>
						</button>
					</div>
				</div>
			</div>

			{/* Exercise Content */}
			<div className="p-4">
				<div className="grid md:grid-cols-2 gap-8">
					{/* Left Column - Details */}
					<div className="space-y-6">
						{/* Description */}
						<div>
							<h3 className="text-sm font-medium text-gray-500 mb-2">
								Instructions
							</h3>
							<div className="prose prose-sm max-w-none">
								{exercise.excercise.description.split("\n").map(
									(step, idx) =>
										step.trim() && (
											<p key={idx} className="text-gray-600 leading-relaxed">
												{step.trim()}
											</p>
										)
								)}
							</div>
						</div>

						{/* Tags */}
						{exercise.excercise.tags && exercise.excercise.tags.length > 0 && (
							<div>
								<h3 className="text-sm font-medium text-gray-500 mb-2">Tags</h3>
								<div className="flex flex-wrap gap-2">
									{exercise.excercise.tags.map((tag) => (
										<span
											key={tag.id}
											className="inline-flex items-center px-3 py-1 rounded-full
                               text-xs font-medium bg-primary/10 text-primary">
											<FontAwesomeIcon icon={faTag} className="mr-1 w-3 h-3" />
											{tag.text}
										</span>
									))}
								</div>
							</div>
						)}
					</div>

					{/* Right Column - Thumbnail */}
					<div className="relative aspect-video rounded-xl overflow-hidden bg-gray-100">
						<img
							src={exercise.excercise.thumbnailObjectUrl}
							alt={exercise.exerciseName}
							className="w-full h-full object-cover"
						/>
						<div
							className="absolute inset-0 bg-black bg-opacity-30 
                        flex items-center justify-center
                        opacity-0 hover:opacity-100 transition-opacity
                        cursor-pointer"
							onClick={onVideoClick}>
							<FontAwesomeIcon
								icon={faPlay}
								className="text-white text-4xl transform hover:scale-110 transition-transform"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

// Video rendering helper
function renderExerciseVideo(exercise) {
	switch (exercise.videoType) {
		case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
			return (
				<video
					className="w-full h-full object-cover"
					controls
					poster={exercise.thumbnailObjectUrl || exercise.thumbnailUrl}>
					<source src={exercise.videoObjectUrl} type="video/mp4" />
				</video>
			);
		case EXERCISE_VIDEO_TYPE_YOUTUBE:
			return <YouTubeVideo videoId={exercise.videoUrl} />;
		case EXERCISE_VIDEO_TYPE_VIMEO:
			return (
				<div className="relative h-full">
					<Vimeo
						video={exercise.videoUrl}
						responsive
						className="absolute inset-0"
					/>
				</div>
			);
		default:
			return (
				<div className="flex items-center justify-center h-full">
					<p className="text-gray-500">No video available</p>
				</div>
			);
	}
}

export default AdminWorkoutDetail;

import React, {useState} from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Top";
import Footer from "./Footer";
import {Navigate} from "react-router-dom";
import Breadcrumb from "../Reusable/Breadcrumb";

const Layout = ({children, currentUser, breadcrumbItems}) => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const handleLogout = async () => {
		try {
			// Redirect to login page
			Navigate("/login");
		} catch (error) {
			console.error("Logout failed:", error);
		}
	};

	return (
		<div className="flex min-h-screen min-w-screen bg-lightGrayShade">
			<Sidebar
				isCollapsed={isCollapsed}
				setIsCollapsed={setIsCollapsed}
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
				currentUser={currentUser}
				onLogout={handleLogout}
			/>
			<div
				className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${
					isCollapsed ? "lg:ml-16 md:ml-16" : "lg:ml-64 md:ml-64"
				}`}>
				<Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} currentUser={currentUser} />
				<main className="flex-1">
					{breadcrumbItems && (
						<div className="my-3">
							<Breadcrumb items={breadcrumbItems} />
						</div>
					)}
					<div className="bg-white rounded-lg shadow-sm">{children}</div>
				</main>
				<Footer />
			</div>
		</div>
	);
};

export default Layout;

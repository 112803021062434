import React, {useState, useEffect} from "react";
import {Link, Navigate, useSearchParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faArrowLeft,
	faEnvelope,
	faKey,
	faTriangleExclamation,
	faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../Reusable/FormErrorBox";
import useLocalStorage from "../../Hooks/useLocalStorage";
import {postForgotPasswordAPI} from "../../API/gateway";
import {topAlertMessageState, topAlertStatusState} from "../../AppState";
import FormInputField from "../Reusable/FormInputField";

function ForgotPassword() {
	////
	//// URL Parameters.
	////

	const [searchParams] = useSearchParams(); // Special thanks via https://stackoverflow.com/a/65451140
	const isUnauthorized = searchParams.get("unauthorized");

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [validation, setValidation] = useState({
		email: false,
	});
	const [email, setEmail] = useState("");
	const [forceURL, setForceURL] = useState("");
	const [wasEmailSent, setWasEmailSent] = useState(false);

	////
	//// API.
	////

	function onForgotPasswordSuccess() {
		console.log("onForgotPasswordSuccess: Starting...");

		setTopAlertMessage("Email sent");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onOrganizationUpdateSuccess: Delayed for 2 seconds.");
			console.log(
				"onOrganizationUpdateSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		setWasEmailSent(true);
	}

	function onForgotPasswordError(apiErr) {
		console.log("onForgotPasswordError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onForgotPasswordDone() {
		console.log("onForgotPasswordDone: Starting...");
	}

	////
	//// Event handling.
	////

	function onEmailChange(e) {
		setEmail(e.target.value);
		validation["email"] = false;
		setValidation(validation);
		// setErrors(errors["email"]="");
	}

	function onButtonClick(e) {
		var newErrors = {};
		var newValidation = {};
		if (email === undefined || email === null || email === "") {
			newErrors["email"] = "value is missing";
		} else {
			newValidation["email"] = true;
		}

		/// Save to state.
		setErrors(newErrors);
		setValidation(newValidation);

		if (Object.keys(newErrors).length > 0) {
			//
			// Handle errors.
			//

			console.log("failed validation");

			// window.scrollTo(0, 0);  // Start the page at the top of the page.

			// The following code will cause the screen to scroll to the top of
			// the page. Please see ``react-scroll`` for more information:
			// https://github.com/fisshy/react-scroll
			var scroll = Scroll.animateScroll;
			scroll.scrollToTop();
		} else {
			//
			// Submit to server.
			//

			console.log("successful validation, submitting to API server.");
			postForgotPasswordAPI(
				email,
				onForgotPasswordSuccess,
				onForgotPasswordError,
				onForgotPasswordDone
			);
		}
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
		}

		return () => (mounted = false);
	}, []);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	////
	//// Component rendering.
	////

	return (
		<div
			className="min-h-screen bg-cover bg-center bg-no-repeat"
			style={{
				backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`,
			}}>
			<div className="max-w-md mx-auto px-4 py-12 sm:px-6 lg:px-8">
				<div className="bg-darkShade/90 backdrop-blur-sm rounded-2xl shadow-2xl p-8">
					{/* Logo */}
					<div className="flex justify-center mb-8">
						<img
							src="/static/logo.png"
							alt="Logo"
							className="w-32 h-32 rounded-full border-4 border-main/20 hover:border-main/40 transition-colors duration-300"
						/>
					</div>

					{!wasEmailSent ? (
						<div className="space-y-6">
							<div className="text-center">
								<h1 className="text-2xl font-bold text-lightShade mb-2">
									Forgot Password
								</h1>
								<p className="text-lightShade/60">
									Please enter your email and we will send you a password reset
									email.
								</p>
							</div>

							{isUnauthorized === "true" && (
								<div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 text-red-500 flex items-start">
									<FontAwesomeIcon
										icon={faTriangleExclamation}
										className="mt-1 mr-3"
									/>
									<div>
										Your session has ended.
										<br />
										Please login again
									</div>
								</div>
							)}

							<FormErrorBox errors={errors} />

							<div className="space-y-2">
								<div className="relative">
									<label className="text-sm font-medium text-lightShade">
										Email
									</label>
									<FormInputField
										// label="Email"
										name="email"
										type="email"
										placeholder="Enter your email"
										value={email}
										onChange={onEmailChange}
										error={errors?.email}
										validation={validation?.email}
										icon={faEnvelope}
									/>
								</div>
								{errors.email && (
									<p className="text-sm text-red-500">{errors.email}</p>
								)}
							</div>

							<button
								onClick={onButtonClick}
								className="w-full py-3 px-4 bg-main text-darkShade rounded-lg
                  hover:bg-main/90 transition-colors duration-200 flex items-center 
                  justify-center font-medium">
								Send Reset Email
								<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
							</button>
						</div>
					) : (
						<div className="bg-green-500/10 border border-green-500/20 rounded-lg p-6 text-center">
							<FontAwesomeIcon
								icon={faCheckCircle}
								className="text-green-500 text-3xl mb-4"
							/>
							<h2 className="text-xl font-semibold text-lightShade mb-2">
								Email Sent
							</h2>
							<p className="text-lightShade/60">
								Please check your email for password reset instructions.
							</p>
						</div>
					)}

					<div className="mt-6 text-center">
						<Link
							to="/login"
							className="text-main hover:text-main/80 transition-colors duration-200 
                inline-flex items-center text-sm">
							<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
							Back to Login
						</Link>
					</div>
				</div>

				{/* Help Section */}
				<div className="mt-8 text-center">
					<h3 className="text-lightShade font-medium mb-4">Need help?</h3>
					<div className="space-y-2">
						<div>
							<span className="text-lightShade">London: </span>
							<a
								href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
								className="text-main hover:text-main/50">
								{process.env.REACT_APP_ORGANIZATION_EMAIL}
							</a>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;

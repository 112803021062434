import React, {useState, useEffect} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
	faGauge,
	faArrowRight,
	faClock,
	faRefresh,
	faRunning,
	faBolt,
	faHeart,
	faAppleAlt,
	faMapMarkedAlt,
	faHistory,
	faUser,
	faDumbbell,
	faChalkboardTeacher,
	faGear,
	faVideo,
	faTrophy,
	faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../Reusable/FormErrorBox";
import {getMySummaryAPI} from "../../API/Biometric";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../AppState";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import Layout from "../Menu/Layout";
import {getTipsListAPI} from "../../API/tips";
import ProgressBar from "../Reusable/ProgressBar";
import {VideoPlayer, VIDEO_LAYOUTS} from "../Reusable/VideoPlayer";

// Enhanced Section Header Component
const SectionHeader = ({icon, title, action}) => (
	<div className="flex items-center justify-between p-6 border-b border-lightGrayShade">
		<div className="flex items-center space-x-3">
			<div className="p-3 rounded-lg bg-main shadow-sm">
				<FontAwesomeIcon icon={icon} className="text-xl text-lightShade" />
			</div>
			<h2 className="text-xl font-bold text-textDark font-primary">{title}</h2>
		</div>
		{action}
	</div>
);

// Enhanced MetricCard Component
const MetricCard = ({icon, value, unit, label, trend, isPositive, bgColor}) => (
	<div className="bg-lightShade rounded-lg shadow-sm border border-lightGrayShade p-6 hover:shadow-md transition-all duration-DEFAULT">
		<div className="flex justify-between items-start">
			<div className={`p-3 rounded-lg ${bgColor} shadow-sm`}>
				<FontAwesomeIcon icon={icon} className="text-xl text-lightShade" />
			</div>
			<div
				className={`px-3 py-1 rounded-full text-xs font-medium ${
					isPositive
						? "bg-greenShade1/10 text-greenShade1"
						: "bg-red-500/10 text-red-500"
				}`}>
				{trend}
			</div>
		</div>
		<div className="mt-4">
			<div className="flex items-baseline">
				<span className="text-3xl font-bold text-textDark font-primary">
					{value}
				</span>
				<span className="ml-1 text-sm text-gray-600">{unit}</span>
			</div>
			<div className="text-sm text-gray-600 mt-1 font-secondary">{label}</div>
		</div>
	</div>
);

// Enhanced QuickActionCard Component
const QuickActionCard = ({icon, title, subtitle, links, bgColor}) => (
	<div className="group bg-lightShade rounded-lg shadow-sm border border-lightGrayShade overflow-hidden">
		<div className={`${bgColor} p-4`}>
			<div className="flex flex-col space-y-1">
				<div className="w-8 h-8 flex items-center justify-center mb-2">
					<FontAwesomeIcon icon={icon} className="text-xl text-lightShade" />
				</div>
				<h3 className="text-lightShade text-sm font-medium font-primary">
					{title}
				</h3>
				<p className="text-lightShade/80 text-xs font-secondary">{subtitle}</p>
			</div>
		</div>
		<div className="p-4">
			<div className="space-y-2">
				{links.map((link, index) => (
					<Link
						key={index}
						to={link.to}
						className="flex items-center justify-between p-2 rounded-lg text-darkShade hover:text-main hover:bg-lightGrayShade transition-all duration-DEFAULT">
						<span className="font-medium text-sm">{link.label}</span>
						<FontAwesomeIcon
							icon={faArrowRight}
							className="text-sm opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all duration-DEFAULT"
						/>
					</Link>
				))}
			</div>
		</div>
	</div>
);

const TipOfTheWeek = ({videoType = "lesson", className = ""}) => {
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize] = useState(1);
	const [currentCursor, setCurrentCursor] = useState("");
	const [nextCursor, setNextCursor] = useState("");
	const [status, setStatus] = useState("");

	const fetchList = (cur, limit) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		params.set("sort_field", "created_at");
		params.set("sort_order", -1);
		params.set("video_type", videoType);

		if (cur !== "") {
			params.set("cursor", cur);
		}

		getTipsListAPI(params, onTipsListSuccess, onTipsListError, onTipsListDone);
	};

	const onTipsListSuccess = (response) => {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onTipsListError = (apiErr) => {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onTipsListDone = () => {
		setFetching(false);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(currentCursor, pageSize, "", status, videoType);
		}
		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, status, videoType]);

	return (
		<div className={className}>
		  {isFetching ? (
			<div className="animate-pulse space-y-4">
			  <div className="bg-gray-200 aspect-video rounded-lg" />
			  <div className="h-6 bg-gray-200 rounded w-2/3" />
			  <div className="h-4 bg-gray-200 rounded w-1/2" />
			  <div className="space-y-2">
				<div className="h-4 bg-gray-200 rounded" />
				<div className="h-4 bg-gray-200 rounded" />
				<div className="h-4 bg-gray-200 rounded w-3/4" />
			  </div>
			</div>
		  ) : listData?.results?.[0] ? (
			<div className="space-y-6">
			  {/* Video Section */}
			  <VideoPlayer
				videoType={listData.results[0].videoType}
				videoUrl={listData.results[0].videoUrl}
				videoObjectUrl={listData.results[0].videoObjectUrl || listData.results[0].videoUrl}
				thumbnailUrl={listData.results[0].thumbnailUrl || listData.results[0].thumbnailObjectUrl}
				description={listData.results[0].description}
				layout={VIDEO_LAYOUTS.MINIMAL}
				showActions={false}
				showDescription={false} // We'll handle description separately
			  />
	  
			  {/* Content Section */}
			  <div className="space-y-4 px-2">
				{/* Title and Date */}
				<div className="space-y-2">
				  <h3 className="text-xl font-semibold text-white">
					{listData.results[0].name}
				  </h3>
				  <p className="text-sm text-gray-400">
					Published {new Date(listData.results[0].publishedAt).toLocaleDateString('en-US', { 
					  year: 'numeric', 
					  month: 'long', 
					  day: 'numeric' 
					})}
				  </p>
				</div>
				
				{/* Description */}
				{listData.results[0].description && (
				  <div className="mt-4">
					<p className="text-gray-300 text-sm leading-relaxed">
					  {listData.results[0].description}
					</p>
				  </div>
				)}
	  
				{/* Organization Attribution */}
				<div className="py-8 text-gray-400 text-sm">
				  Brought to you by {listData.results[0].organizationName}
				</div>
			  </div>
			</div>
		  ) : (
			<div className="text-center py-8">
			  <p className="text-gray-400">
				No {videoType === "technique" ? "techniques" : "lessons"} available
				this week.
			  </p>
			</div>
		  )}
		</div>
	  );
};

const TechniqueTuesday = ({videoType = "technique", className = ""}) => {
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize] = useState(1);
	const [currentCursor, setCurrentCursor] = useState("");
	const [nextCursor, setNextCursor] = useState("");
	const [status, setStatus] = useState("");

	const fetchList = (cur, limit) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		params.set("sort_field", "created_at");
		params.set("sort_order", -1);
		params.set("video_type", videoType);

		if (cur !== "") {
			params.set("cursor", cur);
		}

		// getTechniqueListAPI(params, onTechniqueListSuccess, onTechniqueListError, onTechniqueListDone);

		setFetching(false);
	};

	const onTechniqueListSuccess = (response) => {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onTechniqueListError = (apiErr) => {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onTechniqueListDone = () => {
		setFetching(false);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(currentCursor, pageSize, "", status, videoType);
		}
		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, status, videoType]);

	return (
		<div className={className}>
		  {isFetching ? (
			<div className="animate-pulse space-y-4">
			  <div className="bg-gray-200 aspect-video rounded-lg" />
			  <div className="h-6 bg-gray-200 rounded w-2/3" />
			  <div className="h-4 bg-gray-200 rounded w-1/2" />
			  <div className="space-y-2">
				<div className="h-4 bg-gray-200 rounded" />
				<div className="h-4 bg-gray-200 rounded" />
				<div className="h-4 bg-gray-200 rounded w-3/4" />
			  </div>
			</div>
		  ) : listData?.results?.[0] ? (
			<div className="space-y-6">
			  {/* Video Section */}
			  <VideoPlayer
				videoType={listData.results[0].videoType}
				videoUrl={listData.results[0].videoUrl}
				videoObjectUrl={listData.results[0].videoObjectUrl || listData.results[0].videoUrl}
				thumbnailUrl={listData.results[0].thumbnailUrl || listData.results[0].thumbnailObjectUrl}
				description={listData.results[0].description}
				layout={VIDEO_LAYOUTS.MINIMAL}
				showActions={false}
				showDescription={false} // We'll handle description separately
			  />
	  
			  {/* Content Section */}
			  <div className="space-y-4 px-2">
				{/* Title and Date */}
				<div className="space-y-2">
				  <h3 className="text-xl font-semibold text-white">
					{listData.results[0].name}
				  </h3>
				  <p className="text-sm text-gray-400">
					Published {new Date(listData.results[0].publishedAt).toLocaleDateString('en-US', { 
					  year: 'numeric', 
					  month: 'long', 
					  day: 'numeric' 
					})}
				  </p>
				</div>
				
				{/* Description */}
				{listData.results[0].description && (
				  <div className="mt-4">
					<p className="text-gray-300 text-sm leading-relaxed">
					  {listData.results[0].description}
					</p>
				  </div>
				)}
	  
				{/* Organization Attribution */}
				<div className="pt-4 text-sm text-gray-400">
				  Brought to you by {listData.results[0].organizationName}
				</div>
			  </div>
			</div>
		  ) : (
			<div className="text-center py-8">
			  <p className="text-gray-400">
				No {videoType === "technique" ? "techniques" : "lessons"} available
				this week.
			  </p>
			</div>
		  )}
		</div>
	  );
};

function MemberDashboard() {
	const navigate = useNavigate();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [datum, setDatum] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");

	const handleNavigateToAccount = (e) => {
		e.preventDefault();
		navigate("/account", {state: {activeTabProp: "wearableTech"}});
	};

	const getDatum = (user) => {
		if (user !== undefined && user !== null && user !== "") {
			setFetching(true);
			setErrors({});
			getMySummaryAPI(user.id, onSummarySuccess, onSummaryError, onSummaryDone);
		}
	};

	function onSummarySuccess(response) {
		setDatum(response);
	}

	function onSummaryError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onSummaryDone() {
		setFetching(false);
	}

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
		},
	];

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			getDatum(currentUser);
		}

		return () => {
			mounted = false;
		};
	}, [currentUser, forceURL]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout currentUser={currentUser} breadcrumbItems={breadcrumbItems}>
			<div className="min-h-screen bg-backgroundLight">
				<div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
					{/* Welcome Section */}
					<div className="mb-8 bg-gray-900 rounded-xl shadow-xl border border-gray-800">
						<div className="flex flex-col md:flex-row justify-between items-start md:items-center p-8 space-y-4 md:space-y-0">
							<div className="flex items-center space-x-6">
								<div
									className="p-4 rounded-xl bg-gradient-to-br from-primary to-primary-dark text-lightShade shadow-lg 
                    ring-2 ring-primary/20 backdrop-blur-sm">
									<FontAwesomeIcon icon={faGauge} className="text-2xl" />
								</div>
								<div>
									<h1 className="text-3xl font-bold text-lightShade font-primary tracking-tight">
										Welcome back, {currentUser.firstName}! 👋
									</h1>
									<p className="text-gray-400 mt-1 font-secondary">
										Track your progress and manage your fitness journey
									</p>
								</div>
							</div>
							<div className="flex space-x-4">
								<button
									onClick={() => getDatum(currentUser)}
									className="inline-flex items-center px-4 py-2 rounded-lg bg-mutedOrange/30 text-lightShade 
                  border border-gray-700 hover:bg-gray-800 transition-all duration-300 
                  shadow-lg hover:shadow-xl">
									<FontAwesomeIcon icon={faRefresh} className="mr-2" />
									Refresh Stats
								</button>
								<button
									onClick={handleNavigateToAccount}
									className="inline-flex items-center px-4 py-2 rounded-lg bg-primary text-lightShade 
                  border border-gray-700 hover:bg-gray-800 transition-all duration-300 
                  shadow-lg hover:shadow-xl">
									<FontAwesomeIcon icon={faUser} className="mr-2" />
									My Account
								</button>
							</div>
						</div>
					</div>

					{isFetching ? (
						<PageLoadingContent displayMessage={"Loading your dashboard..."} />
					) : (
						<div className="space-y-8">
							{/* Stats Overview */}
							{/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
								<MetricCard
									icon={faHeart}
									value={datum?.dailySummary?.data?.avgHeartRate || 0}
									unit="bpm"
									label="Heart Rate"
									trend="+5%"
									isPositive={true}
									bgColor="bg-red-500"
								/>
								<MetricCard
									icon={faMapMarkedAlt}
									value={Math.round(datum?.dailySummary?.data?.distance || 0)}
									unit="m"
									label="Distance"
									trend="+12%"
									isPositive={true}
									bgColor="bg-blueShade1"
								/>
								<MetricCard
									icon={faBolt}
									value={datum?.dailySummary?.data?.calories || 0}
									unit="cal"
									label="Calories"
									trend="+8%"
									isPositive={true}
									bgColor="bg-main"
								/>
								<MetricCard
									icon={faRunning}
									value={datum?.dailySummary?.data?.steps || 0}
									unit="steps"
									label="Daily Steps"
									trend="+15%"
									isPositive={true}
									bgColor="bg-greenShade1"
								/>
							</div> */}

							{/* Video Content Sections with Enhanced Design */}
							<div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-8">
								{/* Technique Tuesday Panel */}
								<div
									className="bg-darkShade rounded-xl shadow-xl border border-gray-800 overflow-hidden 
                  transform transition-all duration-300 hover:shadow-2xl">
									<div className="p-6 border-b border-gray-800">
										<div className="flex items-center justify-between">
											<div className="flex items-center space-x-4">
												<div className="p-3 rounded-xl bg-primary/10 backdrop-blur-sm">
													<FontAwesomeIcon
														icon={faChalkboardTeacher}
														className="text-xl text-primary"
													/>
												</div>
												<h2 className="text-xl font-bold text-white">
													Technique Tuesday
												</h2>
											</div>
											<Link
												to="/previous-week-techniques"
												className="flex items-center space-x-2 text-primary hover:text-primary-light 
                   transition-colors duration-300">
												<span>View All</span>
												<FontAwesomeIcon icon={faArrowRight} />
											</Link>
										</div>
									</div>
									<div className="p-4">
										<TechniqueTuesday
											videoType="technique"
											className="w-full rounded-xl overflow-hidden shadow-lg"
										/>
									</div>
									<div className="px-6 pb-6">
										<p className="text-gray-400 text-sm">
											Weekly technique breakdowns by our expert MMA trainers
										</p>
									</div>
								</div>

								{/* Lesson of the Week Panel */}
								<div
									className="bg-darkShade rounded-xl shadow-xl border border-gray-800 overflow-hidden 
                  transform transition-all duration-300 hover:shadow-2xl">
									<div className="p-6 border-b border-gray-800">
										<div className="flex items-center justify-between">
											<div className="flex items-center space-x-4">
												<div className="p-3 rounded-xl bg-primary/10 backdrop-blur-sm">
													<FontAwesomeIcon
														icon={faVideo}
														className="text-xl text-primary"
													/>
												</div>
												<h2 className="text-xl font-bold text-white">
													Lesson of the Week
												</h2>
											</div>
											<Link
												to="/previous-week-lessons"
												className="flex items-center space-x-2 text-primary hover:text-primary-light 
                   transition-colors duration-300">
												<span>View All</span>
												<FontAwesomeIcon icon={faArrowRight} />
											</Link>
										</div>
									</div>
									<div className="py-4 px-5">
										<TipOfTheWeek
											videoType="lesson"
											className="w-full rounded-xl overflow-hidden shadow-lg"
										/>
									</div>
								</div>
							</div>

							{/* Quick Actions with Enhanced Design */}
							<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
								{[
									{
										icon: faAppleAlt,
										title: "Nutrition Plans",
										subtitle:
											"Transform your body with expert-crafted meal plans",
										link: "/nutrition-plans",
										bgColor: "from-blue-600 to-blue-800",
										hoverEffect: "hover:scale-[1.02]",
									},
									{
										icon: faDumbbell,
										title: "Fitness Plans",
										subtitle:
											"Level up your training with personalized workouts",
										link: "/fitness-plans",
										bgColor: "from-primary to-primary-dark",
										hoverEffect: "hover:scale-[1.02]",
									},
									{
										icon: faChalkboardTeacher,
										title: "MMA Lessons",
										subtitle: "Master essential techniques with video lessons",
										link: "/videos",
										bgColor: "from-green-600 to-green-800",
										hoverEffect: "hover:scale-[1.02]",
									},
								].map((card, index) => (
									<div
										key={index}
										className={`group relative overflow-hidden rounded-xl shadow-xl 
                    transition-all duration-300 ${card.hoverEffect}`}>
										<div
											className={`absolute inset-0 bg-gradient-to-br ${card.bgColor} opacity-90`}
										/>
										<div className="relative p-6">
											<div className="flex flex-col h-full space-y-4">
												<div className="p-4 rounded-xl bg-white/10 w-fit backdrop-blur-sm">
													<FontAwesomeIcon
														icon={card.icon}
														className="text-2xl text-white"
													/>
												</div>
												<div>
													<h3 className="text-xl font-bold text-white mb-2">
														{card.title}
													</h3>
													<p className="text-gray-200 text-sm mb-4">
														{card.subtitle}
													</p>
												</div>
												<Link
													to={card.link}
													className="inline-flex items-center space-x-2 text-white 
                     hover:text-gray-200 transition-colors duration-300 mt-auto">
													<span className="font-medium">Get Started</span>
													<FontAwesomeIcon
														icon={faArrowRight}
														className="transform group-hover:translate-x-1 transition-transform duration-300"
													/>
												</Link>
											</div>
										</div>
									</div>
								))}
							</div>

							{/* Progress Section */}
							{/* {currentUser?.role === 4 && (
								<div className="bg-lightShade rounded-lg shadow-sm border border-lightGrayShade overflow-hidden">
									<SectionHeader
										icon={faTrophy}
										title="Your Progress"
										action={
											<div className="hidden sm:flex items-center space-x-2 text-gray-500 text-sm">
												<span className="font-medium">
													{currentUser?.currentLevelDetails?.name}
												</span>
											</div>
										}
									/>
									<div className="p-4 sm:p-4">
										<ProgressBar
											currentLevel={currentUser?.currentLevel}
											currentLevelDetails={currentUser?.currentLevelDetails}
										/>
									</div>
								</div>
							)} */}
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
}

export default MemberDashboard;

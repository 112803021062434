import {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faArrowLeft,
	faEdit,
	faFilterCircleXmark,
	faClose,
	faSave,
	faSearch,
	faFilter,
	faGauge,
	faDumbbell,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	currentUserState,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import DropZone from "../../Reusable/dropzone";
import {getExerciseListAPI} from "../../../API/Exercise";
import WorkoutDisplay from "../../Reusable/Workouts/WorkoutDisplay";
import {getWorkoutDetailAPI, putWorkoutUpdateAPI} from "../../../API/workout";
import DragSortListForSelectedWorkouts from "../../Reusable/draglistforSelectWorkouts";
import {
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import Modal from "../../Reusable/Modal";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import Layout from "../../Menu/Layout";

function MemberWorkoutEdit() {
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [selectedWorkouts, setSelectedWorkouts] = useState([]);
	const [listdata, setlistdata] = useState([]);
	const [selectableExcercises, setselectableExcercises] = useState(listdata);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [currentUser] = useRecoilState(currentUserState);
	const [exerciseLoading, setExerciseLoading] = useState(true);
	const [showExerciseFilter, setshowExerciseFilter] = useState(false);
	const [temporarySearchText, setTemporarySearchText] = useState("");
	const [actualSearchText, setActualSearchText] = useState("");
	const [category, setCategory] = useState("");
	const [movementType, setMovementType] = useState("");
	const [status, setStatus] = useState("");
	const [gender, setGender] = useState("");
	const [videoType, setVideoType] = useState("");
	const [tags, setTags] = useState([]);
	const {id} = useParams();

	const onSubmitClick = () => {
		// Logic to submit data
		let payload = {
			id: datum.id,
			name: name,
			description: description,
			visibility: datum.visibility, //1. visible to all 2. personal
			user_id: datum.user_id || currentUser.id,
			user_name: datum.userName || currentUser.name,
		};
		let workoutExcercises = new Array();
		selectedWorkouts.map((w, index) =>
			workoutExcercises.push({
				id: w.selfId ? w.selfId : null,
				exercise_id: w.isRest ? null : w.id,
				exercise_name: w.isRest ? "REST" : w.name,
				is_rest: w.isRest === true,
				order_number: index + 1,
				sets: w.reps ? parseInt(w.reps) : 0,
				type: w.type ? parseInt(w.type) : 0,
				rest_period_in_secs: parseInt(w.restPeriod),
				target_time_in_secs: w.targetTime ? parseInt(w.targetTime) : 0,
				target_text: w?.targetText,
				created_at: w.selfId ? w.createdAt : null,
			})
		);
		payload.workout_exercises = workoutExcercises;
		putWorkoutUpdateAPI(id, payload, onAddSuccess, onAddError, onAddDone);
	};

	function onAddSuccess(response) {
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Workout updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL("/workouts/" + response.id + "");
	}

	function onAddError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		setFetching(false);
	}

	const getAllExcericses = (clear = false, search = "") => {
		setExerciseLoading(true);
		let params = new Map();
		params.set("page_size", 1000000);
		params.set("sort_field", "created");
		params.set("sort_order", "-1");
		if ((!clear && actualSearchText !== "") || search != "") {
			if (search) {
				params.set("search", search);
			} else {
				params.set("search", actualSearchText);
			}
		}
		if (!clear && category !== "") {
			params.set("category", category);
		}
		if (!clear && movementType !== "") {
			params.set("movement_type", movementType);
		}
		if (!clear && status !== "") {
			params.set("status", status);
		}
		if (!clear && gender !== "") {
			params.set("gender", gender);
		}
		if (!clear && videoType !== "") {
			params.set("video_type", videoType);
		}
		getExerciseListAPI(
			params,
			onExerciseListSuccess,
			onExerciseListError,
			onExerciseListDone
		);
	};

	function onExerciseListSuccess(response) {
		if (response.results !== null) {
			setlistdata(response.results);
		} else {
			setlistdata([]);
		}
	}

	function onExerciseListError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onExerciseListDone() {
		setFetching(false);
		setExerciseLoading(false);
	}

	function workoutdetailsuccess(response) {
		setDatum(response);
		if (response.workoutExercises) {
			setSelectedWorkouts(
				response.workoutExercises.map((w) => ({
					...w,
					selfId: w.id,
					id: w.exerciseId,
					name: w.exerciseName,
					reps: w.sets,
					restPeriod: w.restPeriodInSecs,
					targetTime: w.targetTimeInsecs,
				}))
			);
		}
		setName(response.name);
		setDescription(response.description);
	}
	function workoutdetailerrror(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function workoutdetaildone() {
		setFetching(false);
	}

	const onSearchButtonClick = (e) => {
		setActualSearchText(temporarySearchText);
		getAllExcericses(false, temporarySearchText);
		setshowExerciseFilter(false);
	};

	function ApplyFilter() {
		getAllExcericses();
		setshowExerciseFilter(false);
	}

	const onClearFilterClick = (e) => {
		setshowExerciseFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setStatus("");
		setCategory("");
		setMovementType("");
		setVideoType("");
		setGender("");
		getAllExcericses(true);
	};

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Workouts",
			icon: faDumbbell,
			link: "/workouts",
		},
		{
			label: "Detail",
			icon: faEye,
			link: `/workouts/${id}`,
		},
		{
			label: "Edit",
			icon: faEdit,
		},
	];

	useEffect(() => {
		getAllExcericses();
		getWorkoutDetailAPI(
			id,
			workoutdetailsuccess,
			workoutdetailerrror,
			workoutdetaildone
		);

		Scroll.animateScroll.scrollToTop();
	}, []);

	useEffect(() => {
		if (listdata.length && selectedWorkouts.length) {
			const filtered = listdata.filter((l) => {
				return !selectedWorkouts.some((obj) => obj.id === l.id);
			});
			setselectableExcercises(filtered);
		}
	}, [listdata, selectedWorkouts]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const onDrop = (item) => {
		const exercise = listdata.find((ex) => ex.id === item.id);
		const newWorkout = {
			...exercise,
			reps: "",
			restPeriod: "",
			targetText: "",
			targetType: "",
		};

		setselectableExcercises((prevExercises) =>
			prevExercises.filter((e) => e.id !== exercise.id)
		);

		setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, newWorkout]);
	};

	const handleInputChange = (e, exerciseId, field) => {
		const {value} = e.target;
		setSelectedWorkouts((prevWorkouts) =>
			prevWorkouts.map((workout) => {
				if (workout.id === exerciseId) {
					return {...workout, [field]: value};
				}
				return workout;
			})
		);
	};

	const onRemove = (cancelledItem) => {
		// Move the cancelled item back to the exercises column
		setSelectedWorkouts((prevWorkouts) =>
			prevWorkouts.filter((workout) => workout.id !== cancelledItem.id)
		);
		if (!cancelledItem.isRest) {
			const exercise = listdata.find((ex) => ex.id === cancelledItem.id);
			setselectableExcercises((e) => [...e, exercise]);
		}
	};

	const handleAddRest = () => {
		const restId = `rest-${Date.now()}`;
		let restWorkout = {id: restId, restPeriod: 60, isRest: true};

		setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, restWorkout]);
	};

	return (
		<DndProvider backend={HTML5Backend}>
            <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
                <div className="bg-white rounded-lg shadow-md p-6">
                    {/* Header Section */}
                    <div className="flex items-center space-x-2 mb-6">
                        <FontAwesomeIcon icon={faEdit} className="text-primary text-xl" />
                        <h1 className="text-xl font-semibold text-gray-900">Edit Workout</h1>
                    </div>

                    <FormErrorBox errors={errors} />
                    
                    <p className="text-gray-500 mb-8">
                        Please fill out all the required fields before submitting this form.
                    </p>

                    {isFetching ? (
                        <PageLoadingContent displayMessage={"Please wait..."} />
                    ) : (
                        <div className="space-y-6">
                            {/* Form Fields */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <FormTextareaField
                                    rows={1}
                                    name="Name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    isRequired={true}
                                    maxWidth="150px"
                                />
                                <FormTextareaField
                                    rows={1}
                                    name="Description"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    isRequired={true}
                                    maxWidth="380px"
                                />
                            </div>

                            {/* Workout Builder Section */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Selected Workouts Column */}
                                <div className="space-y-4">
                                    <div className="flex items-center justify-between">
                                        <h2 className="text-lg font-semibold text-gray-900">Selected Workouts</h2>
                                        <button
                                            onClick={handleAddRest}
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-greenShade4 bg-light/10 rounded-lg hover:bg-light/25 transition-colors duration-300"
                                        >
                                            Add Rest
                                        </button>
                                    </div>

                                    <DropZone
                                        className="min-h-[400px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200 p-4"
                                        onDrop={onDrop}
                                        placeholder={!selectedWorkouts.length}
                                    >
                                        <DragSortListForSelectedWorkouts
                                            onRemove={onRemove}
                                            onSortChange={setSelectedWorkouts}
                                            selectedWorkouts={selectedWorkouts}
                                            handleInputChange={handleInputChange}
                                        />
                                    </DropZone>
                                </div>

                                {/* Exercises Column */}
                                <div className="space-y-4">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h2 className="text-lg font-semibold text-gray-900">Exercises</h2>
                                            <p className="text-sm text-gray-500">Click or drag item to add</p>
                                        </div>
                                        <button
                                            onClick={() => setshowExerciseFilter(true)}
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-primary bg-light/10 rounded-lg hover:bg-light/25 transition-colors duration-300"
                                        >
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                            Filter
                                        </button>
                                    </div>

                                    {exerciseLoading ? (
                                        <PageLoadingContent displayMessage={"Please wait..."} />
                                    ) : (
                                        <div className="bg-gray-50 rounded-lg p-4">
                                            <WorkoutDisplay
                                                wrapperclass="space-y-2"
                                                workouts={selectableExcercises}
                                                onAdd={onDrop}
                                                showindex={false}
                                                showDescription={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 pt-6 border-t border-gray-200">
                                <Link
                                    to="/workouts"
                                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-300"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Back to workouts
                                </Link>
                                <button
                                    onClick={onSubmitClick}
                                    disabled={!(name && description && selectedWorkouts.length)}
                                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-hover rounded-lg transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Filter Modal */}
					<Modal isOpen={showExerciseFilter} onClose={() => setshowExerciseFilter(false)}>
                        <div className="bg-white rounded-lg shadow-xl max-w-4xl mx-auto">
                            <div className="p-6">
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">Filter</h3>
                                
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
																<FormInputFieldWithButton
																	label={"Search"}
																	name="temporarySearchText"
																	type="text"
																	placeholder="Search by name"
																	value={temporarySearchText}
																	helpText=""
																	onChange={(e) =>
																		setTemporarySearchText(e.target.value)
																	}
																	isRequired={true}
																	maxWidth="100%"
																	buttonLabel={
																		<>
																			<FontAwesomeIcon
																				className="fas"
																				icon={faSearch}
																			/>
																		</>
																	}
																	onButtonClick={onSearchButtonClick}
																/>
																<FormSelectField
																	label="Category"
																	name="category"
																	placeholder="Pick"
																	selectedValue={category}
																	errorText={errors && errors.category}
																	helpText=""
																	onChange={(e) =>
																		setCategory(parseInt(e.target.value))
																	}
																	options={
																		EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION
																	}
																/>
																<FormSelectField
																	label="Movement Type"
																	name="movementType"
																	placeholder="Pick"
																	selectedValue={movementType}
																	errorText={errors && errors.movementType}
																	helpText=""
																	onChange={(e) =>
																		setMovementType(parseInt(e.target.value))
																	}
																	options={
																		EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION
																	}
																/>
																<FormSelectField
																	label="Gender"
																	name="gender"
																	placeholder="Pick"
																	selectedValue={gender}
																	errorText={errors && errors.gender}
																	helpText=""
																	onChange={(e) => setGender(e.target.value)}
																	options={
																		EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION
																	}
																/>
																<FormSelectField
																	label="Video Type"
																	name="videoType"
																	placeholder="Pick"
																	selectedValue={videoType}
																	errorText={errors && errors.videoType}
																	helpText=""
																	onChange={(e) => setVideoType(e.target.value)}
																	options={
																		EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION
																	}
																/>
																<FormMultiSelectFieldForTags
																	label="Tags"
																	name="tags"
																	placeholder="Pick tags"
																	tags={tags}
																	setTags={setTags}
																	errorText={errors && errors.tags}
																	helpText=""
																	isRequired={true}
																	maxWidth="320px"
																/>
															</div>

<div className="flex justify-end space-x-3 mt-6">
	<button
		onClick={ApplyFilter}
		className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-hover rounded-lg transition-colors duration-300"
	>
		<FontAwesomeIcon icon={faSave} className="mr-2" />
		Apply
	</button>
															<button
																className="button is-small is-primary ml-2"
																onClick={() => setshowExerciseFilter(false)}>
																<FontAwesomeIcon
																	icon={faClose}
																	className="mr-1"
																/>
																Close
															</button>
															<button
																className="button is-small is-secondary ml-2 is-light"
																onClick={onClearFilterClick}>
																<FontAwesomeIcon
																	icon={faFilterCircleXmark}
																	className="mr-1"
																/>
																Clear filter
																</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Layout>
        </DndProvider>
	);
}

export default MemberWorkoutEdit;

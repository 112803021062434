import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faPencil,
	faArrowLeft,
	faGauge,
	faEye,
	faVideoCamera,
	faTrash,
	faSpinner,
	faCheck,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../../../Menu/Layout";
import {ListHeader} from "../../../Reusable/AdminList";
import Modal from "../../../Reusable/Modal";
import DataDisplayRowText from "../../../Reusable/DataDisplayRowText";
import DataDisplayRowURL from "../../../Reusable/DataDisplayRowURL";
import DataDisplayRowCheckbox from "../../../Reusable/DataDisplayRowCheckbox";
import DataDisplayRowSelect from "../../../Reusable/DataDisplayRowSelect";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";
import QuillRenderer from "../../../Reusable/QuillRenderer";

import {
	getVideoContentDetailAPI,
	deleteVideoContentAPI,
} from "../../../../API/VideoContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../../AppState";
import {
	VIDEO_CONTENT_TYPE_WITH_EMPTY_OPTIONS,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../../Constants/FieldOptions";
import {
	VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE,
	VIDEO_CONTENT_VIDEO_TYPE_VIMEO,
	VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL,
} from "../../../../Constants/App";
import VideoPlayer, { VIDEO_LAYOUTS } from "../../../Reusable/VideoPlayer";

function AdminVideoContentDetail() {
	const {vcid, vconid} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [forceURL, setForceURL] = useState("");
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [data, setData] = useState(null);

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/admin/dashboard"},
		{
			label: "MMA Lessons",
			icon: faVideo,
			link: "/admin/video-collections",
		},
		{
			label: "Detail(Overview)",
			icon: faEye,
			link: `/admin/video-collection/${vcid}`,
		},
		{label: data?.name || "Loading...", icon: faEye},
	];

	useEffect(() => {
		fetchVideoContent();
	}, [vconid]);

	const fetchVideoContent = () => {
		setIsLoading(true);
		getVideoContentDetailAPI(vconid, onFetchSuccess, onFetchError, onFetchDone);
	};

	const onFetchSuccess = (response) => {
		console.log("onFetchSuccess: Starting...");
		console.log(response);
		setData(response);
	};

	const onFetchError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to load video content");
		setTopAlertStatus("error");
	};

	const onFetchDone = () => {
		setIsLoading(false);
	};

	const onDeleteClick = () => {
		setShowDeleteModal(true);
	};

	const onDeleteConfirm = () => {
		setIsDeleting(true);
		deleteVideoContentAPI(vconid, onDeleteSuccess, onDeleteError, onDeleteDone);
	};

	const onDeleteSuccess = () => {
		setTopAlertMessage("Video content deleted successfully");
		setTopAlertStatus("success");
		setForceURL(`/admin/video-collection/${vcid}`);
	};

	const onDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to delete video content");
		setTopAlertStatus("error");
	};

	const onDeleteDone = () => {
		setIsDeleting(false);
		setShowDeleteModal(false);
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}


	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="p-6">
				<ListHeader
					title={data?.name || "Video Content Details"}
					icon={faVideo}
				/>

				{isLoading ? (
					<div className="flex items-center justify-center p-12">
						<FontAwesomeIcon
							icon={faSpinner}
							spin
							className="text-primary text-3xl"
						/>
						<span className="ml-3 text-gray-600">Loading video content...</span>
					</div>
				) : (
					<div className="space-y-6">
						{/* Preview Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faEye} className="mr-2" />
								Preview
							</h2>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
								{/* Thumbnail Preview */}
								<div>
									<h3 className="text-sm font-medium text-gray-700 mb-2">
										Thumbnail
									</h3>
									<div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
										<img
											src={data.thumbnailObjectUrl}
											alt={data.name}
											className="object-cover w-full h-full"
										/>
									</div>
								</div>

								{/* Video Preview */}
								<div>
									<h3 className="text-sm font-medium text-gray-700 mb-2">
										Video
									</h3>
									<VideoPlayer
										videoType={data.videoType}
										videoObjectUrl={data.videoType === VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? data.videoObjectUrl : null}
										videoUrl={data.videoType !== VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? data.videoUrl : null}
										thumbnailUrl={data.thumbnailObjectUrl || data.thumbnailUrl}
										name={data.name}
										layout={VIDEO_LAYOUTS.MINIMAL}
										showActions={false}
										showDescription={false}
									/>
								</div>
							</div>
						</div>

						{/* Details Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faVideoCamera} className="mr-2" />
								Video Details
							</h2>
							<div className="space-y-4">
								<DataDisplayRowText label="Name" value={data.name} />
								<DataDisplayRowText label="Number" value={data.no} />
								<QuillRenderer
									label="Description"
									content={data.description}
								/>
								<DataDisplayRowText
									label="Author Name"
									value={data.authorName}
								/>
								<DataDisplayRowURL label="Author URL" value={data.authorUrl} />
								<DataDisplayRowText label="Duration" value={data.duration} />
								<DataDisplayRowText
									label="Category"
									value={data.categoryName}
								/>
							</div>
						</div>

						{/* Monetization Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
								Monetization
							</h2>
							<div className="space-y-4">
								<DataDisplayRowCheckbox
									label="Monetization Enabled"
									checked={data.hasMonetization}
								/>

								{data.hasMonetization && (
									<>
										<DataDisplayRowURL
											label="Offer"
											value={data.offerName}
											url={`/admin/offer/${data.offerId}`}
										/>

										<DataDisplayRowCheckbox
											label="Timed Lock Enabled"
											checked={data.hasTimedLock}
										/>

										{data.hasTimedLock && (
											<DataDisplayRowSelect
												label="Timed Lock Duration"
												value={data.timedLock}
												options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
											/>
										)}
									</>
								)}
							</div>
						</div>

						{/* Action Buttons */}
						<div className="flex flex-col sm:flex-row justify-between gap-4">
							<Link
								to={`/admin/video-collection/${vcid}`}
								className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to Detail(Overview)
							</Link>
							<div className="flex flex-col sm:flex-row gap-4">
								<Link
									to={`/admin/video-collection/${vcid}/video-content/${vconid}/update`}
									className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
									<FontAwesomeIcon icon={faPencil} className="mr-2" />
									Edit
								</Link>
								<button
									onClick={onDeleteClick}
									className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
									disabled={isDeleting}>
									<FontAwesomeIcon icon={faTrash} className="mr-2" />
									{isDeleting ? "Deleting..." : "Delete"}
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Delete Confirmation Modal */}
				<Modal
					isOpen={showDeleteModal}
					onClose={() => setShowDeleteModal(false)}
					header="Confirm Deletion">
					<div className="space-y-4">
						<p className="text-gray-700">
							Are you sure you want to delete this video content? This action
							cannot be undone.
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={() => setShowDeleteModal(false)}
								className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
								disabled={isDeleting}>
								Cancel
							</button>
							<button
								onClick={onDeleteConfirm}
								className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
								disabled={isDeleting}>
								{isDeleting ? (
									<span className="flex items-center">
										<FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
										Deleting...
									</span>
								) : (
									"Delete"
								)}
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminVideoContentDetail;

import {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faArrowLeft,
	faCalendar,
	faTimes,
	faSave,
	faArrowRight,
	faPencil,
	faEye,
	faLevelUp,
	faGauge,
	faBolt,
	faPenRuler,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	currentUserState,
	cursorStateForBadges,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import FormSelectField from "../../Reusable/FormSelectField";
import {
	BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
	FITNESS_CHALLENGE,
	MAX_WEEK_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import FormInputField from "../../Reusable/FormInputField";
import FormDateTimeField from "../../Reusable/FormDateTimeField";
import Modal from "../../Reusable/Modal";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import {getMemberListOptionsAPI} from "../../../API/member";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import {
	getfitnessChallengeDetailAPI,
	putfitnessChallengeUpdateAPI,
} from "../../../API/FitnessChallenge";
import {AsyncPaginate} from "react-select-async-paginate";
import {getBadgesListAPI} from "../../../API/badge";
import Layout from "../../Menu/Layout";
import FormQuillField from "../../Reusable/FormQuillField";

function AdminFitnessChallengeUpdate() {
	const {id} = useParams();

	const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
	const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [forceURL, setForceURL] = useState("");
	const [startOn, setStartOn] = useState("");
	const [duration, setDuration] = useState("");
	const [showRuleModal, setShowRuleModal] = useState(false);
	const [selectedRules, setSelectedRules] = useState([]);
	const [memOptions, setMemOptions] = useState([]);
	const [users, setUsers] = useState([]);

	const [maxPoints, setMaxPoints] = useState(0);
	const [criteria, setCriteria] = useState("");
	const [cursor, setCursor] = useRecoilState(cursorStateForBadges); // Recoil state for cursor

	// for badges
	const [selectedBadge, setSelectedBadge] = useState(null);
	const [earnBadge, setEarnBadge] = useState(false);
	const [, setBadges] = useState([]);
	const IsRuleSelected = (ruleId) => {
		const index = selectedRules.indexOf(ruleId);
		if (index !== -1) {
			return true;
		}
		return false;
	};

	// Breadcrumb items
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Challenges", link: "/admin/fitness-challenges", icon: faBolt},
		{label: "Detail", link: `/admin/fitness-challenge/${id}`, icon: faEye},
		{label: "Edit", icon: faPencil},
	];

	const ToggleRuleCheckBox = (ruleId) => {
		console.log("ruleid", ruleId);
		if (IsRuleSelected(ruleId)) {
			// If ruleId is present, remove it from the selectedRules array
			setSelectedRules((rules) => rules.filter((id) => id !== ruleId));
		} else {
			// If ruleId is not present, add it to the selectedRules array
			setSelectedRules((rules) => [...rules, ruleId]);
		}
	};

	const onSubmitClick = () => {
		// Logic to submit data
		setFetching(true);
		let payload = {
			id: id,
			name: name,
			description: description,
			duration: parseInt(duration),
			organization_id: currentUser.organizationId,
			rules: selectedRules,
			users: users,
			start_on: startOn,
			max_points: parseInt(maxPoints),
			earns_badge: earnBadge,
			criteria: parseInt(criteria),
			badge_id: selectedBadge?.value,
			badge_name: selectedBadge?.label,
		};

		putfitnessChallengeUpdateAPI(
			id,
			payload,
			onAddSuccess,
			onAddError,
			onAddDone
		);
	};

	function onAddSuccess(response) {
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("challenge updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL("/admin/fitness-challenge/" + response.id + "");
	}

	function onAddError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		setFetching(false);
	}

	function onListOK(resp) {
		setFetching(false);
		if (resp?.length) {
			setMemOptions(resp);
		}
	}

	function onListNotOK(resp) {
		setErrors(resp);
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed gettiing list");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}
	function onDone() {
		setFetching(false);
	}

	function onDetailSuccess(response) {
		if (response) {
			setName(response.name);
			setDescription(response.description);
			setStartOn(response.startTime);
			setDuration(response.durationInWeeks);
			setEarnBadge(response.earnsBadge);
			setCriteria(response.criteria);
			setMaxPoints(response.maxPoints);
			setUsers(response.users?.map((user) => user.id));

			if (response.badgeId && response.badgeName) {
				setSelectedBadge({
					value: response.badgeId,
					label: response.badgeName,
					thumbnailUrl: response.badgeDetails?.thumbnailUrl,
				});
			}

			if (response.rules) {
				setSelectedRules(response.rules.map((rule) => rule.type));
			}
		}
	}

	function onDetailError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		setFetching(false);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		getMemberListOptionsAPI(
			currentUser.organizationId,
			onListOK,
			onListNotOK,
			onDone
		);
	}, []);

	useEffect(() => {
		if (memOptions.length > 0) {
			getfitnessChallengeDetailAPI(
				id,
				onDetailSuccess,
				onDetailError,
				onDetailDone
			);
		}

		return () => {};
	}, [memOptions]);

	const loadBadges = async (search, loadedOptions, {cursor}) => {
		return new Promise((resolve, reject) => {
			let params = new Map();

			params.set("page_size", 10); // Set page size

			if (search) {
				params.set("search", search);
			}

			if (cursor && cursor !== "" && cursor !== "000000000000000000000000") {
				params.set("cursor", cursor); // Use cursor for pagination
			}

			getBadgesListAPI(
				params,
				(response) => {
					const newBadges = response?.results?.map((badge) => ({
						value: badge.id,
						label: badge.name,
					}));

					setBadges(newBadges);
					setCursor(response.nextCursor);

					// Continue loading if there are more results
					const hasMore =
						response.nextCursor !== "" &&
						response.nextCursor !== "000000000000000000000000";

					if (!response?.results || response.results.length === 0) {
						resolve({
							options: [],
							hasMore: false,
							additional: {cursor: ""},
						});
						return;
					}

					resolve({
						options: [...newBadges],
						hasMore: hasMore, // Continue loading if more results are available
						additional: {
							cursor: response.nextCursor, // Store the new cursor for the next request
						},
					});
				},
				(error) => {
					setErrors(error);
					reject(error);
				}
			);
		});
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 py-8 max-w-container">
				{/* Header Section */}
				<div className="mb-6 animate-fade-in">
					<h1 className="text-2xl font-bold text-textDark flex items-center">
						<FontAwesomeIcon icon={faBolt} className="mr-3" />
						Update Challenge
					</h1>
				</div>

				{isFetching ? (
					<PageLoadingContent displayMessage="Loading challenge..." />
				) : (
					<div className="space-y-6 animate-slide-up">
						<FormErrorBox errors={errors} />

						{/* Main Form */}
						<div className="bg-white rounded-xl shadow-md border border-gray-200 overflow-hidden">
							<div className="p-6 space-y-6">
								{/* Basic Information */}
								<div className="grid grid-cols-2 md:grid-cols-2 gap-3">
									<FormInputField
										label="Name"
										name="name"
										placeholder="Enter challenge name"
										value={name}
										onChange={(e) => setName(e.target.value)}
										errorText={errors?.name}
									/>
									<FormInputField
										name="maxPoints"
										label="Max Points Required for Challenge Completion:"
										errorText={errors && errors.maxPoints}
										value={maxPoints} // This should be `value`
										maxWidth="200px"
										onChange={(e) => setMaxPoints(e.target.value)}
										type="number"
									/>

									<FormMultiSelectField
										label="Add users to the challenge"
										name="users"
										placeholder="Add users"
										options={memOptions}
										selectedValues={users}
										onChange={(e) => {
											let values = [];
											for (let option of e) {
												values.push(option.value);
											}
											setUsers(values);
										}}
										errorText={errors && errors.users}
										helpText=""
										isRequired={false}
										// maxWidth="640px"
									/>
								</div>

								<FormQuillField
									label="Description"
									name="description"
									placeholder="Describe the challenge objectives and rules"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									isRequired={true}
									errorText={errors?.description}
									className="w-full"
								/>

								{/* Challenge Settings */}
								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<FormDateTimeField
										label={"Start on :"}
										onChange={(date) => setStartOn(date)}
										value={startOn}
										placeholder={"start on"}
										maxWidth={"240px"}
										name={"startOn"}
										errorText={errors && errors.startOn}
									/>

									<FormSelectField
										options={MAX_WEEK_WITH_EMPTY_OPTIONS}
										label={"Duration: "}
										placeholder={"duration"}
										value={duration}
										onChange={(e) => setDuration(e.target.value)}
										errorText={errors && errors.duration}
									/>
								</div>

								{/* Badge Settings */}
								<div className="space-y-4">
									<FormCheckboxField
										label="Will this challenge earn the user a badge?"
										checked={earnBadge}
										onChange={() => setEarnBadge(!earnBadge)}
									/>

									{earnBadge && (
										<div className="pl-6 space-y-4 animate-fade-in">
											{earnBadge && (
												<FormSelectField
													name="criteria"
													label="Criteria"
													helpText={
														"Choose the criteria for earning this badge"
													}
													errorText={errors && errors.criteria}
													options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
													maxWidth="380px"
													value={criteria}
													onChange={(e) => setCriteria(e.target.value)}
												/>
											)}

											<div className="space-y-2">
												<label className="block text-sm font-medium text-gray-700">
													Select Badge
												</label>
												<AsyncPaginate
													value={selectedBadge}
													loadOptions={loadBadges}
													onChange={setSelectedBadge}
													isClearable
													placeholder="Search for badges..."
													additional={{
														cursor: cursor,
													}}
													isDisabled={!earnBadge}
													noOptionsMessage={() => "No badges found"}
													errorText={errors?.badge_id}
												/>
											</div>
										</div>
									)}

									<button
										className="bg-primary text-white rounded-lg px-4 py-2"
										onClick={() => setShowRuleModal(true)}>
										<FontAwesomeIcon icon={faPenRuler} />
										&nbsp;Set Rules
									</button>
									<div>
										<p class="subtitle is-6 mt-6">
											<FontAwesomeIcon className="fas" icon={faPenRuler} />
											&nbsp;Rules Added
										</p>
										<hr />
										{selectedRules.map((r) => (
											<p>
												<FontAwesomeIcon icon={faArrowRight} />
												&nbsp;
												{FITNESS_CHALLENGE.filter((o) => o.value === r).map(
													(f) => f.label
												)}
											</p>
										))}
									</div>
								</div>
							</div>

							{/* Form Actions */}
							<div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3">
								<Link
									to={`/admin/fitness-challenge/${id}`}
									className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-300">
									Cancel
								</Link>
								<button
									onClick={onSubmitClick}
									disabled={isFetching}
									className="px-4 py-2 bg-primary hover:bg-primary-dark text-white rounded-lg transition-colors duration-300 disabled:opacity-50">
									<FontAwesomeIcon icon={faSave} className="mr-2" />
									Save Changes
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Rules Modal */}
				<Modal
					isOpen={showRuleModal}
					onClose={() => setShowRuleModal(false)}
					header="Configure Challenge Rules">
					<div className="p-6 space-y-4">
						{FITNESS_CHALLENGE.map((rule) => (
							<div
								key={rule.value}
								className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
								<FormCheckboxField
									label={rule.label}
									checked={IsRuleSelected(rule.value)}
									onChange={() => ToggleRuleCheckBox(rule.value)}
								/>
							</div>
						))}

						{selectedRules.length > 0 && (
							<div className="mt-4 pt-4 border-t border-gray-100">
								<h4 className="text-sm font-medium text-gray-700 mb-3">
									Selected Rules:
								</h4>
								<div className="space-y-2">
									{selectedRules.map((ruleId) => (
										<div
											key={ruleId}
											className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-100">
											<span className="text-sm text-gray-600">
												{
													FITNESS_CHALLENGE.find(
														(rule) => rule.value === ruleId
													)?.label
												}
											</span>
											<button
												onClick={() => ToggleRuleCheckBox(ruleId)}
												className="text-red-500 hover:text-red-600 transition-colors">
												<FontAwesomeIcon icon={faTimes} />
											</button>
										</div>
									))}
								</div>
							</div>
						)}

						<div className="flex justify-end pt-4 border-t border-gray-100">
							<button
								className="px-4 py-2 bg-primary hover:bg-primary-dark text-white rounded-lg transition-colors duration-300"
								onClick={() => setShowRuleModal(false)}>
								Apply Rules
							</button>
						</div>
					</div>
				</Modal>

				{forceURL && <Navigate to={forceURL} />}
			</div>
		</Layout>
	);
}

export default AdminFitnessChallengeUpdate;

import { Link } from "react-router-dom";

const CardView = ({
  items,
  renderItem,
  linkPrefix = '',
  emptyState,
}) => {
  if (!items?.length) {
    return emptyState;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {items.map((item) => (
        <div
          key={item.id}
          className="rounded-xl shadow-sm mx-4 sm:mx-0 hover:shadow-md overflow-hidden"
        >
          {renderItem(item)}
        </div>
      ))}
    </div>
  );
};

export default CardView;
import {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faList} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import FormErrorBox from "../Reusable/FormErrorBox";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	quizAnswersState,
} from "../../AppState";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import {getQuestionnaireListApi} from "../../API/questionnaire";
import {SelectableOption} from "../Reusable/Wizard/Questions";
import {putMemberUpdateAPI} from "../../API/member";

function FitnessProfile() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");

	const [isFetching, setFetching] = useState(false);
	const [answers, setAnswers] = useRecoilState(quizAnswersState);
	// const [forceURL, setForceURL] = useState("");

	////
	//// API.
	////

	//   if (currentUser && currentUser.on)

	function OnListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
		} else {
			setListData([]);
		}
	}

	function OnListErr(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onListDone() {
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = () => {
		setFetching(true);
		setErrors({});

		let params = new Map();

		params.set("status", true);
		getQuestionnaireListApi(params, OnListSuccess, OnListErr, onListDone);
	};

	const handleSubmit = () => {
		setFetching(true);
		setErrors({});

		const onboardingAnswers = listData.results.map((question) => ({
			question_id: question.id,
			answers: answers[question.id] || [],
		}));
		const decamelizedData = {
			id: currentUser.id,
			organization_id: currentUser.organizationId,
			first_name: currentUser.firstName,
			last_name: currentUser.lastName,
			email: currentUser.email,
			phone: currentUser.phone,
			postal_code: currentUser.postalCode,
			address_line_1: currentUser.addressLine1,
			address_line_2: currentUser.addressLine2,
			city: currentUser.city,
			region: currentUser.region,
			country: currentUser.country,
			status: currentUser.status,
			password: currentUser.password,
			password_repeated: currentUser.passwordRepeated,
			how_did_you_hear_about_us: currentUser.howDidYouHearAboutUs,
			how_did_you_hear_about_us_other: currentUser.howDidYouHearAboutUsOther,
			agree_promotions_email: currentUser.agreePromotionsEmail,
			onboarding_answers: onboardingAnswers,
			onboarding_completed: true,
		};
		putMemberUpdateAPI(
			decamelizedData,
			onAdminMemberUpdateSuccess,
			onAdminMemberUpdateError,
			onAdminMemberUpdateDone
		);
	};

	function onAdminMemberUpdateSuccess(response) {
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Member updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect the user to a new page.
		// setForceURL("/admin/member/" + response.id);
	}

	function onAdminMemberUpdateError(apiErr) {
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAdminMemberUpdateDone() {
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		if (currentUser?.onboardingAnswers?.length > 0) {
			const userAnswers = {};
			currentUser.onboardingAnswers.forEach((answer) => {
				userAnswers[answer.questionId] = answer.answers || [];
			});
			setAnswers(userAnswers);
		}
	}, []);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList();
		}

		return () => {
			mounted = false;
		};
	}, []);

	////
	//// Component rendering.
	////
	const handleSelect = (questionId, selectedId, isMultiSelect) => {
		if (isMultiSelect) {
			const updatedSelections = answers[questionId]?.includes(selectedId)
				? answers[questionId].filter((id) => id !== selectedId)
				: [...(answers[questionId] || []), selectedId];
			setAnswers({...answers, [questionId]: updatedSelections});
		} else {
			setAnswers({...answers, [questionId]: [selectedId]}); // Wrap selectedId in an array
		}
	};
	return (
		<div className="min-h-screen bg-gradient-to-b from-lightShade to-lightGrayShade p-4 md:p-6 lg:p-8">
			<nav className="mx-auto">
				<div className="flex items-center justify-between mb-6">
					<h1 className="text-xl md:text-2xl font-primary font-bold text-darkShade flex items-center">
						<FontAwesomeIcon className="mr-2 text-burntOrange" icon={faList} />
						<span className="animate-fade-in">Questions</span>
					</h1>
					{/* Progress indicator */}
					{listData?.results?.length > 0 && (
						<div className="text-xs md:text-sm text-gray-600">
							Questions: {Object.keys(answers).length} / {listData.results.length}
						</div>
					)}
				</div>

				{/* Submit button moved to top */}
				{listData?.results?.length > 0 && (
                <div className="mb-4">
                    <button
                        onClick={handleSubmit}
                        disabled={isFetching}
                        className="w-full bg-greenShade1 hover:bg-greenShade2 text-white text-sm font-semibold
                            py-2.5 px-6 rounded-full transform hover:scale-105 transition-all duration-300 
                            disabled:opacity-50 disabled:cursor-not-allowed shadow-md hover:shadow-lg"
                    >
                        Submit Answers
                    </button>
                </div>
            )}
	
				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						<FormErrorBox errors={errors} />
						{listData && listData.results && listData.results.length > 0 ? (
                        <div className="space-y-4 max-h-[calc(100vh-10rem)] overflow-y-auto flex-1 pr-2"> {/* Added scrolling here */}
                            {listData.results.map((datum, i) => (
                                <div 
                                    key={datum.id}
                                    className="bg-white rounded-lg shadow-lg p-4 md:p-6 transform transition-all duration-300"
                                >
										<div className="mb-4">
											<div className="flex items-center mb-2">
											<span className="w-6 h-6 aspect-square rounded-full bg-burntOrange text-white flex items-center justify-center text-sm font-bold mr-3">
													{i + 1}
												</span>
												<h2 className="text-base md:text-lg font-primary font-semibold text-darkShade">
													{datum.title}
												</h2>
											</div>
											{datum.subtitle && (
												<p className="text-sm text-gray-600 ml-9">
													{datum.subtitle}
												</p>
											)}
										</div>
										<div className="ml-9">
											<div className="grid gap-2">
												{datum.options.map((option) => (
													<SelectableOption
														isFullScreen={false}
														key={option}
														option={option}
														isSelected={
															Array.isArray(answers[datum.id])
																? answers[datum.id].includes(option)
																: answers[datum.id] === option
														}
														onSelect={() =>
															handleSelect(
																datum.id,
																option,
																datum.isMultiselect
															)
														}
													/>
												))}
											</div>
										</div>
									</div>
								))}
								
								{/* Submit button */}
								{/* <div className="mt-6 flex justify-center">
									<button
										onClick={handleSubmit}
										disabled={isFetching}
										className="bg-burntOrange hover:bg-darkOrangeShade text-white text-sm font-semibold
											py-2.5 px-6 rounded-full transform hover:scale-105 transition-all duration-300 
											disabled:opacity-50 disabled:cursor-not-allowed shadow-md hover:shadow-lg"
									>
										Submit Answers
									</button>
								</div> */}
							</div>
						) : (
							<div className="bg-white rounded-lg shadow-lg p-6 text-center animate-fade-in">
								<div className="flex flex-col items-center">
									<FontAwesomeIcon 
										icon={faQuestionCircle} 
										className="text-4xl text-gray-400 mb-3"
									/>
									<h2 className="text-lg font-primary font-bold text-darkShade mb-1">
										No Questions Available
									</h2>
									<p className="text-sm text-gray-600 max-w-md mx-auto">
										There are currently no questions available. Please check back later.
									</p>
								</div>
							</div>
						)}
					</>
				)}
			</nav>
		</div>
	);
}

export default FitnessProfile;

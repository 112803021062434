import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../AppState';
import { ADMIN_ROLE_ID } from '../Constants/App';

export const ProtectedRoute = ({ children }) => {
  const user = useRecoilValue(currentUserState);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const user = useRecoilValue(currentUserState);
  
  if (!user || user.role !== ADMIN_ROLE_ID) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      {children}
    </div>
  );
};

export const PublicOnlyRoute = ({ children }) => {
  const user = useRecoilValue(currentUserState);
  
  if (user) {
    // Redirect admins to /admin/dashboard, others to /dashboard
    const redirectPath = user.role === ADMIN_ROLE_ID ? '/admin/dashboard' : '/dashboard';
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
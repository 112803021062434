import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import FormErrorBox from "../Reusable/FormErrorBox";

function RegisterSuccessful() {
  const [errors, setErrors] = useState({});
  const [forceURL, setForceURL] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />
  }

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat" 
      style={{
        backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`
      }}>
      <div className="max-w-2xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="bg-darkShade/90 backdrop-blur-sm rounded-2xl shadow-2xl p-8">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <img
              src="/static/logo.png"
              alt="Logo"
              className="w-32 h-32 rounded-full border-4 border-main/20 hover:border-main/40 transition-colors duration-300"
            />
          </div>

          <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-6 text-center">
            <FontAwesomeIcon 
              icon={faCheckCircle} 
              className="text-green-500 text-3xl mb-4" 
            />
            <h1 className="text-2xl font-bold text-lightShade mb-4">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              Email Sent
            </h1>
            
            <FormErrorBox errors={errors} />
            
            <p className="text-lightShade/80 mb-6">
              Thank you for registering - an <span className="font-semibold">activation email</span> has been sent to you. 
              Please be sure to check your social, promotions and spam folders if it does not arrive within 5 minutes.
            </p>

            <Link 
              to="/"
              className="inline-flex items-center text-main hover:text-main/80 
                transition-colors duration-200 font-medium"
            >
              Back to index
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
          </div>
        </div>

        {/* Help Section */}
        <div className="mt-8 text-center">
											<h3 className="text-lightShade font-medium mb-4">
												Need help?
											</h3>
											<div className="space-y-2">
												<div>
													<span className="text-lightShade">London: </span>
													<a
														href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
														className="text-main hover:text-main/50">
														{process.env.REACT_APP_ORGANIZATION_EMAIL}
													</a>
												</div>
											</div>
										</div>
      </div>
    </div>
  );
}

export default RegisterSuccessful;
import React from "react";

function FormTextareaField({
  label,
  name,
  placeholder,
  value,
  errorText,
  helpText,
  onChange,
  isRequired,
  maxWidth,
  rows = 2,
  disabled = false,
}) {
  return (
    <div className="mb-4">
      <label 
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
        {isRequired && <span className="text-red-500 ml-1">*</span>}
      </label>
      
      <div className="relative">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className={`
            w-full
            px-3 
            py-2 
            transition-colors
            duration-200
            rounded-md
            shadow-sm
            border
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            disabled:bg-gray-50
            disabled:text-gray-500
            disabled:cursor-not-allowed
            ${errorText 
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500' 
              : 'border-gray-300 focus:border-primary focus:ring-primary'
            }
            ${maxWidth ? `max-w-[${maxWidth}]` : 'max-w-full'}
          `}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoComplete="off"
        />
        
        {errorText && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg 
              className="h-5 w-5 text-red-500" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" 
                clipRule="evenodd" 
              />
            </svg>
          </div>
        )}
      </div>

      {/* Help and Error Text */}
      {errorText && (
        <p className="mt-1 text-sm text-red-600">
          {errorText}
        </p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">
          {helpText}
        </p>
      )}
    </div>
  );
}

export default FormTextareaField;
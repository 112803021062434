import { Route } from 'react-router-dom';
import { ProtectedRoute, AdminRoute, PublicOnlyRoute } from './RouteGuards';

export const generateRoutes = (routes, type) => {
  return routes.map(({ path, component: Component, redirectIfAuth, ...rest }) => {
    let element = <Component {...rest} />;

    if (type === 'admin') {
      element = (
        <AdminRoute>
          {element}
        </AdminRoute>
      );
    } else if (type === 'member') {
      element = (
        <ProtectedRoute>
          {element}
        </ProtectedRoute>
      );
    } else if (redirectIfAuth) {
      element = (
        <PublicOnlyRoute>
          {element}
        </PublicOnlyRoute>
      );
    }

    return (
      <Route
        key={path}
        path={path}
        element={element}
        {...rest}
      />
    );
  });
};
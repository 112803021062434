import {useEffect} from "react";
import useTimer from "../../Hooks/useTimer";

const Timer = ({
	initialTime = 120,
	onComplete,
	autoStart = false,
	showControls = true,
	className = "",
	onTick,
	progressBar = false,
}) => {
	const timer = useTimer({
		initialTime,
		onComplete,
		autoStart,
		onTick,
	});

	const progressStyle = {
		width: `${timer.getProgress()}%`,
	};

	useEffect(() => {
		console.log("Timer render - States:", {
			isActive: timer.isActive,
			isPaused: timer.isPaused,
			time: timer.time,
		});
	}, [timer.isActive, timer.isPaused]);

	return (
		<div className={`timer-container ${className}`}>
			{/* Timer Display */}
			<div className="text-4xl font-bold text-center mb-4">
				{timer.formatTime()}
			</div>

			{/* Progress Bar */}
			{progressBar && (
				<div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
					<div
						className="bg-primary h-2.5 rounded-full transition-all duration-1000"
						style={progressStyle}
					/>
				</div>
			)}

			{/* Controls */}
			{showControls && (
    <div className="flex justify-center space-x-4">
        {/* Start Button */}
        {!timer.isActive && !timer.isPaused && (
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    timer.start();
                }}
                className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark cursor-pointer"
                style={{
                    position: 'relative',
                    zIndex: 10,
                    pointerEvents: 'auto'
                }}
            >
                Start
            </button>
        )}

        {/* Pause Button */}
        {timer.isActive && !timer.isPaused && (
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    timer.pause();
                }}
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 cursor-pointer"
                style={{
                    position: 'relative',
                    zIndex: 10,
                    pointerEvents: 'auto'
                }}
            >
                Pause
            </button>
        )}

        {/* Resume Button */}
        {timer.isPaused && (
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    timer.resume();
                }}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 cursor-pointer"
                style={{
                    position: 'relative',
                    zIndex: 10,
                    pointerEvents: 'auto'
                }}
            >
                Resume
            </button>
        )}

        {/* Reset Button - Always visible */}
        <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                timer.reset();
            }}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 cursor-pointer"
            style={{
                position: 'relative',
                zIndex: 10,
                pointerEvents: 'auto'
            }}
        >
            Reset
        </button>
				</div>
			)}
		</div>
	);
};

export default Timer;

import {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faEye,
	faTable,
	faPlus,
	faDumbbell,
	faCalendar,
	faLayerGroup,
	faEdit,
	faChartLine,
	faArrowRight,
	faUsers,
	faBook,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {ListHeader} from "../../Reusable/AdminList";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {getWorkoutListApi} from "../../../API/workout";
import {
	deleteTrainingProgAPI,
	getTrainingProgDetailAPI,
	patchTrainingProgAPI,
} from "../../../API/trainingProgram";
import Layout from "../../Menu/Layout";
import PhasePanel from "./phasepanel";
import FitnessPlanDisplay from "../../Reusable/FitnessPlanDisplay";
import {
	TRAINING_PROGRAM_STATUS_OPTIONS,
	TRAINING_PROGRAM_VISBILITY_OPTIONS,
	EXERCISE_CATEGORY_OPTIONS,
	EXERCISE_MOMENT_TYPE_OPTIONS,
} from "../../../Constants/FieldOptions";

const EmptyState = ({icon, title, description, action}) => (
	<div className="text-center max-w-md mx-auto">
		<div className="inline-flex items-center justify-center w-12 h-12 rounded-xl bg-gray-100 mb-4">
			<FontAwesomeIcon icon={icon} className="w-6 h-6 text-gray-400" />
		</div>
		<h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
		<p className="text-sm text-gray-500 mb-6">{description}</p>
		{action}
	</div>
);

const StatusCard = ({label, value, icon, statusClass}) => (
	<div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-200 shadow-sm">
		<div className="flex items-start justify-between">
			<div className="flex-1 min-w-0">
				<p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
				<h3 className="text-base sm:text-lg font-semibold text-gray-900 truncate">
					{value}
				</h3>
			</div>
			{icon ? (
				<FontAwesomeIcon icon={icon} className="w-5 h-5 text-gray-400 ml-2" />
			) : (
				<span
					className={`ml-2 px-2.5 sm:px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${statusClass}`}>
					{value}
				</span>
			)}
		</div>
	</div>
);

function AdminTPDetail() {
	const {uid, id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedWorkoutForDeletion, setSelectedWorkoutForDeletion] =
		useState(null);
	const [selectedPhase, setSelectedPhase] = useState(null);
	const [isPhaseLoading, setPhaseLoading] = useState(false);

	const onAddWorkout = (phase) => {
		window.location.href = `/admin/training-program/${id}/edit`;
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			setFetching(true);
			getTrainingProgDetailAPI(
				id,
				onDetailSuccess,
				onDetailError,
				onDetailDone
			);
		}
		return () => {
			mounted = false;
		};
	}, [id]);

	function handleDeleteConfirmButtonClick() {
		deleteTrainingProgAPI(id, ondeleteSuccess, ondeleteError, onDeleteDone);
		setSelectedWorkoutForDeletion(null);
	}

	const handleSelectPhase = (phase) => {
		setPhaseLoading(true);
		setSelectedPhase(phase);
		setTimeout(() => setPhaseLoading(false), 500);
	};

	const transformResponseForFitnessPlan = (phase) => {
		return [
			{
				title: phase.name,
				dailyPlans: phase.trainingRoutines.map((tr) => ({
					title: tr.trainingDays?.length
						? `Week - ${tr.trainingDays[0].week}: Day - ${tr.trainingDays[0].day}`
						: "",
					instructions: tr.workout.description,
					planDetails:
						tr.workout.workoutExercises?.map((exc) => ({
							id: exc.id,
							name: exc.exerciseName,
							videoUrl: exc.excercise?.videoUrl,
							thumbnailUrl:
								exc.excercise?.thumbnailObjectUrl ||
								exc.excercise?.thumbnailUrl,
							description: setInstruction(exc),
							videoType: exc.excercise?.videoType,
							videoObjectUrl: exc.excercise?.videoObjectUrl,
							// Additional metadata from API
							sets: exc.sets,
							targetText: exc.targetText,
							targetTimeInSecs: exc.targetTimeInSecs,
							movementType: exc.movementType,
							category: exc.category,
						})) || [],
				})),
			},
		];
	};

	const parseInstructions = (text) => {
		if (!text) return [];

		// If text doesn't start with a number, treat it as a single instruction
		if (!/^\d+\./.test(text)) {
			return [
				{
					number: 1,
					text: text.trim(),
				},
			];
		}

		// Regex pattern: matches a number followed by period, space, and text up to the next number or end
		const pattern = /(\d+)\.\s([^1-9]+)/g;
		const instructions = [];
		let match;

		while ((match = pattern.exec(text)) !== null) {
			instructions.push({
				number: parseInt(match[1]),
				text: match[2].trim(),
			});
		}

		return instructions;
	};

	// Update the setInstruction helper function to use the correct fields
	const setInstruction = (exc) => {
		const instructions = [];

		// Parse and add exercise description if available
		if (exc.excercise?.description) {
			const parsedInstructions = parseInstructions(exc.excercise.description);
			instructions.push(...parsedInstructions.map((inst) => inst.text));
		}

		// Add exercise metadata
		// const metadata = [];
		// if (exc.sets) {
		//   metadata.push(`Sets: ${exc.sets}`);
		// }
		// if (exc.targetText) {
		//   metadata.push(`Target: ${exc.targetText}`);
		// }
		// if (exc.targetTimeInSecs) {
		//   const minutes = Math.floor(exc.targetTimeInSecs / 60);
		//   const seconds = exc.targetTimeInSecs % 60;
		//   metadata.push(`Duration: ${minutes}:${seconds.toString().padStart(2, '0')}`);
		// }
		// if (exc.category) {
		//   const categoryLabel = EXERCISE_CATEGORY_OPTIONS.find(
		// 	(item) => item.value === exc.category
		//   )?.label || exc.category;
		//   metadata.push(`Category: ${categoryLabel}`);
		// }
		// if (exc.movementType) {
		//   const movementLabel = EXERCISE_MOMENT_TYPE_OPTIONS.find(
		// 	(item) => item.value === exc.movementType
		//   )?.label || exc.movementType;
		//   metadata.push(`Movement Type: ${movementLabel}`);
		// }

		// Combine instructions and metadata
		return [...instructions, ""].join("\n");
	};

	// API callbacks
	const onDetailSuccess = (response) => {
		setDatum(response);
		if (response.trainingPhases) {
			const updatedWorkoutForRoutine = {};
			response.trainingPhases.forEach((tp) => {
				updatedWorkoutForRoutine[tp.id] =
					tp.trainingRoutines?.map((routine) => ({
						...routine.workout,
						day: routine.trainingDays?.[0]?.day || 0,
						week: routine.trainingDays?.[0]?.week || 0,
					})) || [];
			});
		}
	};

	const onDetailError = (apiErr) => {
		setErrors(apiErr);
		scrollToTop();
	};

	const onDetailDone = () => setFetching(false);
	const ondeleteSuccess = (response) => {
		setTopAlertStatus("success");
		setTopAlertMessage("Training program deleted");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/admin/training-program");
	};

	const ondeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed to delete program");
		setTimeout(() => setTopAlertMessage(""), 2000);
		scrollToTop();
	};

	const onDeleteDone = () => setFetching(false);
	const scrollToTop = () => Scroll.animateScroll.scrollToTop();

	if (forceURL !== "") return <Navigate to={forceURL} />;

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Training Programs", link: "/admin/training-program", icon: faBook},
		{label: "Details", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50/50">
				<div className="w-full max-w-7xl mx-auto px-3 sm:px-4 md:px-6 lg:px-8 py-3 sm:py-4 md:py-6">
					{/* Header Section */}
					<div className="mb-4 md:mb-6">
						<div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between mb-4 md:mb-6">
							<div className="flex items-center gap-2 sm:gap-3">
								<Link
									to="/admin/training-program"
									className="p-2 text-gray-500 hover:text-primary transition-colors rounded-lg hover:bg-gray-100">
									<FontAwesomeIcon icon={faBook} className="w-4 h-4" />
								</Link>
								<h1 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 truncate">
									Training Program Details
								</h1>
							</div>
							<div className="flex items-center gap-2 sm:gap-3 mt-2 sm:mt-0">
								<button
									onClick={() => setSelectedWorkoutForDeletion(datum)}
									className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4 
                    text-sm font-medium text-red-600 bg-white border border-red-200 
                    rounded-lg hover:bg-red-50 hover:border-red-300 transition-all
                    duration-300 shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
									<FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
									<span className="ml-2 hidden sm:inline">Delete Program</span>
								</button>
								<Link
									to={
										uid
											? `/admin/member/${uid}/training-program/${id}/edit`
											: `/admin/training-program/${id}/edit`
									}
									className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4
    text-sm font-medium text-primary bg-white border border-primary/20 
    rounded-lg hover:bg-primary/5 hover:border-primary transition-all
    duration-300 shadow-sm focus:ring-2 focus:ring-primary focus:ring-offset-2">
									<FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
									<span className="ml-2 hidden sm:inline">Edit Program</span>
								</Link>
							</div>
						</div>

						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 md:gap-6">
							<StatusCard
								label="Status"
								value={
									TRAINING_PROGRAM_STATUS_OPTIONS.find(
										(option) => option.value === datum.status
									)?.label || "N/A"
								}
								statusClass={
									datum.status === 1
										? "bg-green-100 text-green-700"
										: "bg-yellow-100 text-yellow-700"
								}
							/>
							<StatusCard
								label="Visibility"
								value={
									TRAINING_PROGRAM_VISBILITY_OPTIONS.find(
										(option) => option.value === datum.visibility
									)?.label || "N/A"
								}
								icon={faEye}
							/>
							<StatusCard
								label="Total Phases"
								value={`${datum.trainingPhases?.length || 0} Phases`}
								icon={faLayerGroup}
							/>
						</div>
						<FormErrorBox errors={errors} />
					</div>

					{/* Main Content */}
					<div className="space-y-6">
						<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm overflow-hidden">
							<div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200/80 px-6 py-5">
								<div className="flex flex-wrap items-center justify-between gap-4">
									<div className="flex items-center space-x-3">
										<div className="flex items-center justify-center w-10 h-10 rounded-lg bg-primary/10">
											<FontAwesomeIcon
												icon={faLayerGroup}
												className="w-5 h-5 text-primary"
											/>
										</div>
										<div>
											<h2 className="text-lg font-semibold text-gray-900">
												Training Phases
											</h2>
											<p className="text-sm text-gray-500 mt-0.5">
												Manage your program's training phases
											</p>
										</div>
									</div>
									<span className="inline-flex items-center px-3 py-1 rounded-full bg-primary/5 text-primary text-sm font-medium">
										{datum.trainingPhases?.length || 0} Phases
									</span>
								</div>
							</div>

							<div className="p-6">
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
									{(datum.trainingPhases || []).map((phase, index) => (
										<div
											key={phase.id}
											className={`group relative p-5 rounded-xl border border-gray-200/80 bg-white
                        hover:border-primary/20 hover:shadow-lg transition-all duration-300
                        ${
													selectedPhase?.id === phase.id
														? "ring-2 ring-primary ring-offset-2"
														: ""
												}`}>
											<div className="flex flex-col h-full">
												<div className="flex items-start justify-between mb-3">
													<div className="flex-1">
														<h3 className="text-base font-medium text-gray-900 group-hover:text-primary transition-colors">
															{phase.name}
														</h3>
														<p className="mt-1 text-sm text-gray-500 line-clamp-2">
															{phase.description}
														</p>
													</div>
													<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
														Phase {index + 1}
													</span>
												</div>

												<div className="flex items-center gap-4 py-3 border-t border-gray-100">
													<div className="flex items-center text-sm text-gray-500">
														<FontAwesomeIcon
															icon={faDumbbell}
															className="w-4 h-4 mr-1.5"
														/>
														{phase.trainingRoutines?.reduce(
															(acc, routine) =>
																acc + routine.trainingDays.length,
															0
														) || 0}{" "}
														Sessions
													</div>
													{/* <div className="flex items-center text-sm text-gray-500">
                            <FontAwesomeIcon icon={faCalendar} className="w-4 h-4 mr-1.5" />
                            {phase.endWeek - phase.startWeek + 1} Weeks
                          </div> */}
												</div>

												<div className="flex items-center gap-2 mt-auto pt-4 border-t border-gray-100">
													<button
														onClick={() => handleSelectPhase(phase)}
														className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium
                              text-primary bg-primary/5 rounded-lg hover:bg-primary/10 transition-colors duration-200">
														<FontAwesomeIcon
															icon={faEye}
															className="w-4 h-4 mr-2"
														/>
														Select Phase
													</button>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</section>

						{selectedPhase ? (
							<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm overflow-hidden">
								<div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200/80 px-6 py-5">
									<div className="flex flex-wrap items-center justify-between gap-4">
										<div className="flex items-center space-x-3">
											<div className="flex items-center justify-center w-10 h-10 rounded-lg bg-primary/10">
												<FontAwesomeIcon
													icon={faChartLine}
													className="w-5 h-5 text-primary"
												/>
											</div>
											<div>
												<h2 className="text-lg font-semibold text-gray-900 flex items-center">
													{selectedPhase.name}
												</h2>
												<p className="text-sm text-gray-500 mt-0.5 line-clamp-1">
													{selectedPhase.description}
												</p>
											</div>
										</div>
										<div className="flex flex-wrap items-center gap-2">
											<span className="inline-flex items-center px-3 py-1 rounded-full bg-primary/5 text-primary text-sm font-medium">
												Weeks {selectedPhase.startWeek}-{selectedPhase.endWeek}
											</span>
											<span className="inline-flex items-center px-3 py-1 rounded-full bg-gray-100 text-gray-700 text-sm font-medium">
												{selectedPhase.status === 1 ? "Active" : "Draft"}
											</span>
										</div>
									</div>
								</div>

								{isPhaseLoading ? (
									<div className="p-12">
										<PageLoadingContent display="spinner" />
									</div>
								) : (
									<div className="relative">
										{selectedPhase.trainingRoutines?.length > 0 ? (
											<div className="overflow-x-auto p-4">
												<FitnessPlanDisplay
													weeklyFitnessPlans={transformResponseForFitnessPlan(
														selectedPhase
													)}
												/>
											</div>
										) : (
											<div className="px-6 py-12">
												<EmptyState
													icon={faTable}
													title="No Workouts Scheduled"
													description={`Add workouts to ${selectedPhase.name} phase by clicking the button below.`}
													action={
														<button
															onClick={() => onAddWorkout(selectedPhase)}
															className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium
                                text-white bg-primary rounded-lg hover:bg-primary-dark
                                transition-colors duration-200 shadow-sm">
															<FontAwesomeIcon
																icon={faPlus}
																className="w-4 h-4 mr-2"
															/>
															Schedule Workout
														</button>
													}
												/>
											</div>
										)}
									</div>
								)}
							</section>
						) : (
							<div className="bg-white rounded-2xl border border-gray-200/80 shadow-sm p-12">
								<EmptyState
									icon={faLayerGroup}
									title="Select a Phase"
									description="Choose a phase from above to view its workout details."
								/>
							</div>
						)}
					</div>
				</div>

				<Modal
					isOpen={selectedWorkoutForDeletion !== null}
					onClose={() => setSelectedWorkoutForDeletion(null)}
					header="Confirm Deletion">
					<div className="p-4">
						<p className="text-sm text-gray-600 mb-4">
							Are you sure you want to delete this training program? This action
							cannot be undone.
						</p>
						<div className="flex flex-col sm:flex-row justify-end gap-2">
							<button
								onClick={() => setSelectedWorkoutForDeletion(null)}
								className="w-full sm:w-auto h-10 px-4 text-sm font-medium text-gray-700 
                    bg-white border border-gray-300 rounded-lg hover:bg-gray-50 
                    transition-colors duration-300 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
								Cancel
							</button>
							<button
								onClick={handleDeleteConfirmButtonClick}
								className="w-full sm:w-auto h-10 px-4 text-sm font-medium text-white 
                    bg-red-600 rounded-lg hover:bg-red-700 transition-colors 
                    duration-300 focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
								Delete
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminTPDetail;
